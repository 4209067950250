// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var Storage$Util = require("util/src/Storage.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var SalesHeading$Thin = require("../shared/SalesHeading.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var MakeAPaymentModal$Thin = require("../../common/molecules/payment-modules/MakeAPaymentModal.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var PaymentAmountSelection$Thin = require("./PaymentAmountSelection.bs.js");
var PaymentMethodSelection$Thin = require("./PaymentMethodSelection.bs.js");
var SelectedCabinSummaryCard$Thin = require("../shared/SelectedCabinSummaryCard.bs.js");
var CurrentUserBookingSessions$Thin = require("../queries/CurrentUserBookingSessions.bs.js");
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;

function Checkout(props) {
  var useSessionSaveHandler = props.useSessionSaveHandler;
  var voyageSlug = props.voyageSlug;
  var urlState = props.urlState;
  var sessionData = props.sessionData;
  var url = props.url;
  var paymentSuggestions = HopperState$Util.Observable.useComputed(sessionData, (function (m) {
          return m.paymentSuggestions;
        }), undefined, undefined);
  var match = React.useState(function () {
        
      });
  var setPaymentSuggestionError = match[1];
  var paymentSuggestionError = match[0];
  var paymentOption = HopperState$Util.Observable.useComputed(sessionData, (function (m) {
          return m.paymentOption;
        }), undefined, undefined);
  var match$1 = React.useState(function () {
        
      });
  var paymentAction = match$1[0];
  var paymentReturn = React.useState(function () {
        return "NotAsked";
      });
  var match$2 = React.useState(function () {
        
      });
  var calculatedFee = match$2[0];
  var sessionSaveHandler = useSessionSaveHandler(sessionData, undefined, undefined, undefined);
  var selectPaymentOption = function (paymentOption) {
    HopperState$Util.Observable.notify(sessionData, (function (model) {
            return {
                    paxes: model.paxes,
                    bc: model.bc,
                    pax1: model.pax1,
                    paymentOption: paymentOption,
                    sessionSaveIsMutating: model.sessionSaveIsMutating,
                    signup: model.signup,
                    url: model.url,
                    paymentSuggestions: model.paymentSuggestions,
                    quote: model.quote,
                    referral: model.referral,
                    referralSuccess: model.referralSuccess,
                    referralError: model.referralError
                  };
          }));
  };
  var clearPaymentOption = function () {
    HopperState$Util.Observable.notify(sessionData, (function (model) {
            return {
                    paxes: model.paxes,
                    bc: model.bc,
                    pax1: model.pax1,
                    paymentOption: undefined,
                    sessionSaveIsMutating: model.sessionSaveIsMutating,
                    signup: model.signup,
                    url: model.url,
                    paymentSuggestions: model.paymentSuggestions,
                    quote: model.quote,
                    referral: model.referral,
                    referralSuccess: model.referralSuccess,
                    referralError: model.referralError
                  };
          }));
  };
  React.useEffect((function () {
          if (sessionSaveHandler.TAG === "Ok") {
            sessionSaveHandler._0();
          }
          
        }), [paymentOption]);
  var canAdvance = props.canCheckout && Belt_Option.isSome(paymentOption) && Belt_Option.isSome(paymentAction);
  var match$3 = Belt_Option.map(paymentOption, (function (p) {
          return SFState$Thin.paymentOptionToInt(p);
        }));
  var btnAdvanceCmp = match$3 !== undefined ? (
      calculatedFee !== undefined ? JsxRuntime.jsxs(Typography, {
              color: "inherit",
              level: "body-sm",
              children: [
                "Finish and Pay " + Common$Thin.formatCurrency((match$3 + calculatedFee | 0) / 100.0),
                JsxRuntime.jsx(Typography, {
                      color: "inherit",
                      level: "body-xs",
                      component: "span",
                      children: Caml_option.some(" (includes " + Common$Thin.formatCurrency(calculatedFee / 100.0) + " fee)")
                    })
              ]
            }) : JsxRuntime.jsx(Typography, {
              color: "inherit",
              level: "body-sm",
              children: Caml_option.some("Finish and Pay " + Common$Thin.formatCurrency(match$3 / 100.0))
            })
    ) : JsxRuntime.jsx(Typography, {
          level: "body-sm",
          children: "Finish and Pay"
        });
  var match$4 = React.useState(function () {
        return false;
      });
  var setPaymentModalOpen = match$4[1];
  var onAdvance = function (param) {
    if (paymentAction !== undefined) {
      paymentAction();
      return setPaymentModalOpen(function (param) {
                  return true;
                });
    }
    
  };
  React.useEffect((function () {
          if (paymentSuggestions !== undefined) {
            
          } else {
            CurrentUserBookingSessions$Thin.$$fetch(sessionData, setPaymentSuggestionError, voyageSlug);
          }
        }), []);
  var match$5 = React.useState(function () {
        
      });
  var setRedirectUrl = match$5[1];
  var redirectUrl = match$5[0];
  var onFinish = function (b) {
    setPaymentModalOpen(function (param) {
          return false;
        });
    HopperState$Util.Observable.notify(sessionData, (function (param) {
            return SFState$Thin.blankModel();
          }));
  };
  if (redirectUrl !== undefined) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        spacing: 1,
                        sx: {
                          p: {
                            xs: 1,
                            md: 4
                          }
                        },
                        children: [
                          JsxRuntime.jsx(Stack, {
                                sx: {
                                  width: "62px",
                                  height: "62px"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(CheckCircleOutline, {
                                          color: "success",
                                          sx: {
                                            width: "100%",
                                            height: "100%"
                                          }
                                        }))
                              }),
                          JsxRuntime.jsx(Typography, {
                                level: "h3",
                                component: "h2",
                                sx: {
                                  textAlign: "center"
                                },
                                children: "Anchors aweigh!"
                              }),
                          JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                component: "p",
                                sx: {
                                  textAlign: "center"
                                },
                                children: "Now we're cruisin'! Your payment and reservation are confirmed and ready to view!"
                              })
                        ]
                      }),
                  JsxRuntime.jsx(Stack, {
                        direction: "row",
                        justifyContent: "center",
                        sx: {
                          pb: 1,
                          width: "100%"
                        },
                        children: Caml_option.some(JsxRuntime.jsx(Button, {
                                  onClick: (function () {
                                      RescriptReactRouter.push(redirectUrl);
                                    }),
                                  size: "lg",
                                  disabled: false,
                                  sx: {
                                    borderRadius: "6px"
                                  },
                                  children: "Take me to my booking"
                                }))
                      })
                ]
              });
  } else {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs(Stack, {
                        spacing: 1,
                        sx: {
                          px: {
                            xs: 2,
                            md: 4
                          },
                          py: 2,
                          flex: 1,
                          height: "100%"
                        },
                        children: [
                          JsxRuntime.jsx(SalesHeading$Thin.make, {
                                title: "Payment",
                                description: "Choose your amount and preferred payment method.",
                                voyage: props.voyage
                              }),
                          JsxRuntime.jsx(Stack, {
                                direction: "column",
                                sx: {
                                  pt: 1,
                                  pb: 2
                                },
                                children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                          children: [
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                            direction: "column",
                                                            spacing: 2,
                                                            children: Caml_option.some(paymentSuggestions !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                                        children: [
                                                                          JsxRuntime.jsx(PaymentAmountSelection$Thin.make, {
                                                                                currentPaymentOption: paymentOption,
                                                                                selectPaymentOption: selectPaymentOption,
                                                                                clearPaymentOption: clearPaymentOption,
                                                                                paymentSuggestions: paymentSuggestions
                                                                              }),
                                                                          JsxRuntime.jsx(PaymentMethodSelection$Thin.make, {
                                                                                currentPaymentOption: paymentOption,
                                                                                voyageSlug: voyageSlug,
                                                                                urlState: urlState,
                                                                                setPaymentAction: match$1[1],
                                                                                paymentReturn: paymentReturn,
                                                                                calculatedFee: calculatedFee,
                                                                                setCalculatedFee: match$2[1]
                                                                              })
                                                                        ]
                                                                      }) : (
                                                                    paymentSuggestionError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                                                            message: paymentSuggestionError,
                                                                            type_: "error"
                                                                          }) : JsxRuntime.jsx(Stack, {
                                                                            direction: "row",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                                                                                      size: "md",
                                                                                      variant: "solid"
                                                                                    }))
                                                                          })
                                                                  ))
                                                          })),
                                                  xs: 12,
                                                  md: 7
                                                }),
                                            JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(SelectedCabinSummaryCard$Thin.make, {
                                                            category: props.category,
                                                            cabinHoldExpiry: urlState.cabinHold,
                                                            canAdvance: canAdvance,
                                                            onAdvance: onAdvance,
                                                            advanceText: "Make payment",
                                                            pageLocked: false,
                                                            quote: props.quote,
                                                            mustAcceptTermsLink: "https://jococruise.com/" + CS_Slugs$Util.VoyageSlug.toString(voyageSlug) + "-terms-conditions/",
                                                            btnAdvanceCmp: Caml_option.some(btnAdvanceCmp),
                                                            fareClass: props.fareClass,
                                                            occupancy: props.occupancy,
                                                            sessionData: sessionData,
                                                            useSessionSaveHandler: useSessionSaveHandler
                                                          })),
                                                  xs: 0,
                                                  md: 5
                                                })
                                          ],
                                          container: true,
                                          columnSpacing: 2
                                        }))
                              })
                        ]
                      }),
                  JsxRuntime.jsx(Stack, {
                        sx: {
                          height: canAdvance ? "10rem" : "5rem",
                          display: {
                            xs: "flex",
                            md: "none"
                          }
                        }
                      }),
                  JsxRuntime.jsx(MakeAPaymentModal$Thin.make, {
                        open_: match$4[0],
                        onClose: (function () {
                            setPaymentModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        paymentReturn: paymentReturn,
                        onFinish: onFinish,
                        onPaymentSuccess: (function (b) {
                            var bookingUrl = SysCtx$Thin.vars.bookingsLink + "/" + CS_NonemptyStrings$Util.BookingId.toString(b);
                            setRedirectUrl(function (param) {
                                  return bookingUrl;
                                });
                            Storage$Util.Session.set(SysCtx$Thin.getBookedSessionKey(b), "true");
                            SFState$Thin.navigate(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setBookingSuccess(urlState, true)), url);
                          }),
                        text: {
                          loadingTitle: "Aye, aye, Captain!",
                          loadingDesc: "We've got your cruise details secured and are casting off to finalize your booking. Just a splash longer!",
                          successTitle: "Anchors aweigh!",
                          successDesc: "Now we're cruisin'! Your payment and reservation are confirmed and ready to view!",
                          successBtnText: "Take me to my booking",
                          failureTitle: "Oops!"
                        }
                      })
                ]
              });
  }
}

var make = Checkout;

exports.make = make;
/* react Not a pure module */
