// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var AppConfig_frag_graphql$Thin = require("../__generated__/AppConfig_frag_graphql.bs.js");
var AppConfigFragment_graphql$Thin = require("../__generated__/AppConfigFragment_graphql.bs.js");
var AppConfigVoyages_frag_graphql$Thin = require("../__generated__/AppConfigVoyages_frag_graphql.bs.js");
var AppConfigCountries_frag_graphql$Thin = require("../__generated__/AppConfigCountries_frag_graphql.bs.js");
var AppConfigBrandFamily_frag_graphql$Thin = require("../__generated__/AppConfigBrandFamily_frag_graphql.bs.js");
var AppConfigCountriesAcquisitionSlugs_frag_graphql$Thin = require("../__generated__/AppConfigCountriesAcquisitionSlugs_frag_graphql.bs.js");

var convertFragment = AppConfigCountries_frag_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AppConfigCountries_frag_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppConfigCountries_frag_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(AppConfigCountries_frag_graphql$Thin.node, convertFragment, fRef);
}

var Fragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function countryComparator(param, param$1) {
  var topOfList = Belt_MapString.fromArray(Belt_Array.mapWithIndex([
            "USA",
            "CAN",
            "AUS",
            "NZL",
            "GBR",
            "DEU"
          ], (function (idx, code) {
              return [
                      code,
                      idx + 1 | 0
                    ];
            })));
  var match = Belt_MapString.getWithDefault(topOfList, param.alpha3, 0);
  var match$1 = Belt_MapString.getWithDefault(topOfList, param$1.alpha3, 0);
  if (match === 0 && match$1 === 0) {
    return 0;
  }
  if (match$1 !== 0 && !(match !== 0 && match < match$1)) {
    return 1;
  } else {
    return -1;
  }
}

function use$1(frag) {
  return Belt_SortArray.stableSortBy(Belt_Array.map(Belt_Option.mapWithDefault(use(frag).cntry, [], (function (param) {
                        return param.nodes;
                      })), (function (param) {
                    return CS_ISO3166_1$Util.Alpha3.meta(param.alpha3);
                  })), countryComparator);
}

var Countries = {
  Fragment: Fragment,
  countryComparator: countryComparator,
  use: use$1
};

var convertFragment$1 = AppConfigCountriesAcquisitionSlugs_frag_graphql$Thin.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(AppConfigCountriesAcquisitionSlugs_frag_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppConfigCountriesAcquisitionSlugs_frag_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(AppConfigCountriesAcquisitionSlugs_frag_graphql$Thin.node, convertFragment$1, fRef);
}

var Fragment$1 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$2,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function use$3(fragment) {
  return Belt_Array.map(Belt_Option.mapWithDefault(use$2(fragment).brandFamilyAcquisitionChannels, [], (function (param) {
                    return param.nodes;
                  })), (function (param) {
                return {
                        slug: param.custAcquisitionSlug,
                        desc: CS_NonemptyStrings$Util.NonEmptyString.toString(param.descriptiveName)
                      };
              }));
}

var AcquisitionSlugs = {
  Fragment: Fragment$1,
  use: use$3
};

var convertFragment$2 = AppConfigBrandFamily_frag_graphql$Thin.Internal.convertFragment;

function use$4(fRef) {
  return RescriptRelay_Fragment.useFragment(AppConfigBrandFamily_frag_graphql$Thin.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppConfigBrandFamily_frag_graphql$Thin.node, convertFragment$2);
}

function readResolverFragment$2(fRef) {
  return RescriptRelay_Fragment.read(AppConfigBrandFamily_frag_graphql$Thin.node, convertFragment$2, fRef);
}

var Fragment$2 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  use: use$4,
  useOpt: useOpt$2,
  readResolverFragment: readResolverFragment$2
};

function use$5(fragment) {
  return Belt_Option.getExn(Belt_Option.map(use$4(fragment).brandFamily, (function (param) {
                    return {
                            brandFamilySlug: param.brandFamilySlug,
                            brandFamilyName: CS_NonemptyStrings$Util.NonEmptyString.toString(param.brandFamilyName)
                          };
                  })));
}

var BrandFamily = {
  Fragment: Fragment$2,
  use: use$5
};

var convertFragment$3 = AppConfigVoyages_frag_graphql$Thin.Internal.convertFragment;

function use$6(fRef) {
  return RescriptRelay_Fragment.useFragment(AppConfigVoyages_frag_graphql$Thin.node, convertFragment$3, fRef);
}

function useOpt$3(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppConfigVoyages_frag_graphql$Thin.node, convertFragment$3);
}

function readResolverFragment$3(fRef) {
  return RescriptRelay_Fragment.read(AppConfigVoyages_frag_graphql$Thin.node, convertFragment$3, fRef);
}

var Fragment$3 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$3,
  use: use$6,
  useOpt: useOpt$3,
  readResolverFragment: readResolverFragment$3
};

function use$7(fragment) {
  var allVoyages = Belt_Array.map(Belt_Option.mapWithDefault(use$6(fragment).brandFamilyVoyages, [], (function (param) {
              return param.nodes;
            })), (function (param) {
          return {
                  voyageSlug: param.voyageSlug,
                  voyageName: CS_NonemptyStrings$Util.NonEmptyString.toString(param.voyageName),
                  bookableStatuses: [
                    "SIGNED_ON",
                    "VERIFY_EMAIL",
                    "PENDING_2FA"
                  ]
                };
        }));
  var vset = Belt_Set.fromArray(Belt_Array.map(allVoyages, (function (param) {
              return param.voyageSlug;
            })), CS_Slugs$Util.VoyageSlug.Comparable);
  var checkVoyageSlug = function (str) {
    return Belt_Result.flatMap(CS_Slugs$Util.VoyageSlug.fromPrimitive(str), (function (slug) {
                  if (Belt_Set.has(vset, slug)) {
                    return {
                            TAG: "Ok",
                            _0: slug
                          };
                  } else {
                    return {
                            TAG: "Error",
                            _0: "This voyage does not exist"
                          };
                  }
                }));
  };
  var getVoyageName = function (slug) {
    return Core__Array.findMap(allVoyages, (function (v) {
                  if (Caml_obj.equal(v.voyageSlug, slug)) {
                    return v.voyageName;
                  }
                  
                }));
  };
  var getBookableStatuses = function (slug) {
    var match = Belt_Array.get(Belt_Array.keep(allVoyages, (function (s) {
                return Caml_obj.equal(s.voyageSlug, slug);
              })), 0);
    if (match !== undefined) {
      return match.bookableStatuses;
    } else {
      return ["SIGNED_ON"];
    }
  };
  return {
          allVoyages: allVoyages,
          checkVoyageSlug: checkVoyageSlug,
          getVoyageName: getVoyageName,
          getBookableStatuses: getBookableStatuses
        };
}

var Voyages = {
  Fragment: Fragment$3,
  use: use$7
};

var convertFragment$4 = AppConfig_frag_graphql$Thin.Internal.convertFragment;

function use$8(fRef) {
  return RescriptRelay_Fragment.useFragment(AppConfig_frag_graphql$Thin.node, convertFragment$4, fRef);
}

function useOpt$4(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AppConfig_frag_graphql$Thin.node, convertFragment$4);
}

function readResolverFragment$4(fRef) {
  return RescriptRelay_Fragment.read(AppConfig_frag_graphql$Thin.node, convertFragment$4, fRef);
}

var makeRefetchVariables = AppConfigFragment_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = AppConfigFragment_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(AppConfig_frag_graphql$Thin.node, convertFragment$4, convertRefetchVariables, fRef);
}

var Fragment$4 = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$4,
  use: use$8,
  useOpt: useOpt$4,
  readResolverFragment: readResolverFragment$4,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function useQuery(fragmentRefs) {
  var match = useRefetchable(fragmentRefs);
  var query = match[0];
  return {
          countries: use$1(query.fragmentRefs),
          acquisitionOptions: use$3(query.fragmentRefs),
          brandFamily: use$5(query.fragmentRefs),
          voyages: use$7(query.fragmentRefs)
        };
}

var defaultContextWhichIsBroken = React.createContext("ERROR");

function use$9() {
  return React.useContext(defaultContextWhichIsBroken);
}

var make = defaultContextWhichIsBroken.Provider;

var Provider = {
  make: make
};

var Context = {
  defaultContextWhichIsBroken: defaultContextWhichIsBroken,
  use: use$9,
  Provider: Provider
};

exports.Countries = Countries;
exports.AcquisitionSlugs = AcquisitionSlugs;
exports.BrandFamily = BrandFamily;
exports.Voyages = Voyages;
exports.Fragment = Fragment$4;
exports.useQuery = useQuery;
exports.Context = Context;
/* defaultContextWhichIsBroken Not a pure module */
