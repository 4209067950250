// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"currentUserProfiles_nodes_pendingVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"currentUserProfiles_nodes_passenger_record_nodes_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"currentUserProfiles_nodes_passenger_record_nodes_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"currentUserProfiles_nodes_legalDocumentBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"currentUserProfiles_nodes_lastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserProfiles_nodes_firstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"currentUserProfiles_nodes_accountId":{"c":"Util.CustomScalars.AccountId.Exn"},"currentUserConnectedAccounts_nodes_pendingVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"currentUserConnectedAccounts_nodes_passenger_record_nodes_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"currentUserConnectedAccounts_nodes_legalDocumentBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"currentUserConnectedAccounts_nodes_accountId":{"c":"Util.CustomScalars.AccountId.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pendingVoyageSlugs",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legalDocumentBirthdate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "filter",
      "value": {
        "bookings": {
          "lifecycleStatus": {
            "equalTo": "ACTIVE"
          }
        }
      }
    }
  ],
  "concreteType": "BookingPassengersConnection",
  "kind": "LinkedField",
  "name": "passenger_record",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingPassenger",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bookingId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voyageSlug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "passenger_record(filter:{\"bookings\":{\"lifecycleStatus\":{\"equalTo\":\"ACTIVE\"}}})"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddPassDrawer_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUserConnectedAccountsConnection",
      "kind": "LinkedField",
      "name": "currentUserConnectedAccounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrentUserConnectedAccount",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullDisplayName",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CurrentUserProfilesConnection",
      "kind": "LinkedField",
      "name": "currentUserProfiles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CurrentUserProfile",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
