// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var ReactState$Util = require("./ReactState.bs.js");
var QueryString$Util = require("./QueryString.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function renderUrl(param) {
  var search = param.search;
  var hash = param.hash;
  var renderPath = function (path) {
    if (!path) {
      return "";
    }
    var reste = path.tl;
    var un = path.hd;
    if (reste) {
      return un + "/" + renderPath(reste);
    } else {
      return un;
    }
  };
  var search$1 = search === "" ? "" : "?" + search;
  var hash$1 = hash === "" ? "" : "#" + hash;
  return "/" + renderPath(param.path) + search$1 + hash$1;
}

function merge(l, r) {
  return {
          read: (function (x) {
              return [
                      l.read(x),
                      r.read(x)
                    ];
            }),
          write: (function (x, param) {
              return r.write(l.write(x, param[0]), param[1]);
            })
        };
}

function map(lens, aller, retour) {
  return {
          read: (function (v) {
              return aller(lens.read(v));
            }),
          write: (function (x, z) {
              return lens.write(x, retour(z));
            })
        };
}

function chain(lens, then) {
  return {
          read: (function (v) {
              return then.read(lens.read(v));
            }),
          write: (function (x, z) {
              return lens.write(x, then.write(lens.read(x), z));
            })
        };
}

var Lens = {
  merge: merge,
  map: map,
  chain: chain
};

var WriteOnReadOnlyDerivedQuantity = /* @__PURE__ */Caml_exceptions.create("HopperState-Util.Observable.WriteOnReadOnlyDerivedQuantity");

function dangerouslyRead(param) {
  var match = param.ref.current;
  return param.read(match.ctx);
}

function noopEffect(param, param$1) {
  
}

function compileEffects(subs) {
  return Belt_Array.reduce(subs, noopEffect, (function (prev, param) {
                var r = param[1];
                return function (before, after) {
                  var match = prev(before, after);
                  var match$1 = r(before, after);
                  if (match !== undefined) {
                    if (match$1 !== undefined) {
                      return (function (p) {
                                match(p);
                                match$1(p);
                              });
                    } else {
                      return match;
                    }
                  } else {
                    return match$1;
                  }
                };
              }));
}

function subscribe(t, param, param$1, param$2) {
  var current = t.ref.current;
  var read = t.read;
  var skipUpdate = t.skipUpdate;
  var subs = current.subs;
  var newSubs = Belt_Array.concat(subs, [[
          param,
          (function (before, after) {
              if (skipUpdate(after)) {
                return ;
              } else if (param$2 === "PassThru") {
                return (function (parent) {
                          param$1(read(parent));
                        });
              } else if (skipUpdate(before) || Caml_obj.notequal(read(before), read(after))) {
                return (function (parent) {
                          param$1(read(parent));
                        });
              } else {
                return ;
              }
            })
        ]]);
  t.ref.current = {
    ctx: current.ctx,
    subs: newSubs,
    compiled: compileEffects(newSubs),
    isNotifying: current.isNotifying,
    nextNotification: current.nextNotification
  };
}

function unsubscribe(t, param) {
  var current = t.ref.current;
  var subs = current.subs;
  var newSubs = Belt_Array.keep(subs, (function (param$1) {
          return param$1[0] !== param;
        }));
  t.ref.current = {
    ctx: current.ctx,
    subs: newSubs,
    compiled: compileEffects(newSubs),
    isNotifying: current.isNotifying,
    nextNotification: current.nextNotification
  };
}

function doNotification(t, notification, $staropt$star) {
  var enqueued = $staropt$star !== undefined ? $staropt$star : false;
  var init = t.ref.current;
  t.ref.current = {
    ctx: init.ctx,
    subs: init.subs,
    compiled: init.compiled,
    isNotifying: true,
    nextNotification: init.nextNotification
  };
  var before = t.ref.current.ctx;
  var after = notification(t.ref.current.ctx);
  var liveFx = t.ref.current.compiled(before, after);
  if (liveFx !== undefined) {
    liveFx(after);
    if (t.debug) {
      console.log("fire", Date.now(), enqueued, before, after);
    }
    
  }
  var init$1 = t.ref.current;
  t.ref.current = {
    ctx: after,
    subs: init$1.subs,
    compiled: init$1.compiled,
    isNotifying: false,
    nextNotification: init$1.nextNotification
  };
  var next = t.ref.current.nextNotification;
  var doNext = next !== undefined ? (function () {
        doNotification(t, next, true);
      }) : (function () {
        
      });
  var init$2 = t.ref.current;
  t.ref.current = {
    ctx: init$2.ctx,
    subs: init$2.subs,
    compiled: init$2.compiled,
    isNotifying: init$2.isNotifying,
    nextNotification: undefined
  };
  doNext();
}

function notify(t, notification) {
  var write = t.write;
  var read = t.read;
  var asRootNotification = function (root) {
    return write(root, notification(read(root)));
  };
  if (!t.ref.current.isNotifying) {
    return doNotification(t, asRootNotification, undefined);
  }
  var init = t.ref.current;
  t.ref.current = {
    ctx: init.ctx,
    subs: init.subs,
    compiled: init.compiled,
    isNotifying: init.isNotifying,
    nextNotification: Belt_Option.mapWithDefault(t.ref.current.nextNotification, asRootNotification, (function (prevFn) {
            return function (root) {
              return asRootNotification(prevFn(root));
            };
          }))
  };
}

function useStore(initFn, debugOpt) {
  var debug = debugOpt !== undefined ? debugOpt : false;
  return {
          ref: React.useRef({
                ctx: initFn(),
                subs: [],
                compiled: noopEffect,
                isNotifying: false,
                nextNotification: undefined
              }),
          debug: debug,
          skipUpdate: (function (param) {
              return false;
            }),
          read: (function (a) {
              return a;
            }),
          write: (function (param, $$new) {
              return $$new;
            })
        };
}

function makeView(t, lens, $staropt$star) {
  var skipOrig = t.skipUpdate;
  var skipUpdate = $staropt$star !== undefined ? $staropt$star : (function (param) {
        return false;
      });
  var chained = chain({
        read: t.read,
        write: t.write
      }, lens);
  return {
          ref: t.ref,
          debug: t.debug,
          skipUpdate: (function (r) {
              if (skipOrig(r)) {
                return true;
              } else {
                return skipUpdate(r);
              }
            }),
          read: chained.read,
          write: chained.write
        };
}

function getRoot(t, alterSkipUpdate) {
  var tmp;
  tmp = typeof alterSkipUpdate !== "object" ? t.skipUpdate : alterSkipUpdate._0;
  return {
          ref: t.ref,
          debug: t.debug,
          skipUpdate: tmp,
          read: (function (a) {
              return a;
            }),
          write: (function (param, a) {
              return a;
            })
        };
}

function addSkipUpdateCheck(t, check) {
  var read = t.read;
  var skipOrig = t.skipUpdate;
  return {
          ref: t.ref,
          debug: t.debug,
          skipUpdate: (function (r) {
              if (skipOrig(r)) {
                return true;
              } else {
                return check(read(r));
              }
            }),
          read: t.read,
          write: t.write
        };
}

function defaultIntervention(a) {
  return a;
}

function useOptionalDebounceIntervention(millisOpt) {
  var millis = millisOpt !== undefined ? Caml_option.valFromOption(millisOpt) : undefined;
  var timerId = React.useRef(undefined);
  if (millis !== undefined) {
    return function (effectToWrap) {
      return function (val) {
        var tid = timerId.current;
        if (tid !== undefined) {
          clearTimeout(Caml_option.valFromOption(tid));
        }
        timerId.current = Caml_option.some(setTimeout((function () {
                    effectToWrap(val);
                  }), millis));
      };
    };
  } else {
    return defaultIntervention;
  }
}

function useSubscribeOnChange(t, effect, equalityFnOpt, interventionOpt) {
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "RescriptEquals";
  var intervention = interventionOpt !== undefined ? interventionOpt : defaultIntervention;
  var guid = React.useMemo((function () {
          return (crypto.randomUUID());
        }), []);
  React.useEffect((function () {
          subscribe(t, guid, intervention(effect), equalityFn);
          return (function () {
                    unsubscribe(t, guid);
                  });
        }), [guid]);
  return guid;
}

function makeExnOptionView(t) {
  var write = t.write;
  var read = t.read;
  return makeView(getRoot(addSkipUpdateCheck(t, Belt_Option.isNone), "RetainSkipUpdateFromView"), {
              read: (function (o) {
                  return Belt_Option.getExn(read(o));
                }),
              write: (function (p, v) {
                  return write(p, Caml_option.some(v));
                })
            }, undefined);
}

function asSetter(view) {
  return function (sfunc) {
    notify(view, sfunc);
  };
}

function useReadOnly(t, equalityFnOpt, interventionOpt) {
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "RescriptEquals";
  var intervention = interventionOpt !== undefined ? interventionOpt : defaultIntervention;
  var local = React.useState(function () {
        return dangerouslyRead(t);
      });
  useSubscribeOnChange(t, (function (v) {
          ReactState$Util.setter(local)(function (param) {
                return v;
              });
        }), equalityFn, intervention);
  React.useEffect((function () {
          if (equalityFn === "PassThru") {
            ReactState$Util.setter(local)(function (param) {
                  return dangerouslyRead(t);
                });
          } else if (Caml_obj.notequal(dangerouslyRead(t), ReactState$Util.getter(local))) {
            ReactState$Util.setter(local)(function (param) {
                  return dangerouslyRead(t);
                });
          }
          
        }), []);
  return ReactState$Util.getter(local);
}

function useComputed(t, project, equalityFnOpt, interventionOpt) {
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "RescriptEquals";
  var intervention = interventionOpt !== undefined ? interventionOpt : defaultIntervention;
  var bsView = makeView(t, {
        read: (function (p) {
            return project(p);
          }),
        write: (function (param, param$1) {
            throw {
                  RE_EXN_ID: WriteOnReadOnlyDerivedQuantity,
                  Error: new Error()
                };
          })
      }, undefined);
  return useReadOnly(bsView, equalityFn, intervention);
}

function useReadWrite(t, equalityFnOpt) {
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "RescriptEquals";
  return [
          useReadOnly(t, equalityFn, undefined),
          (function (sfunc) {
              notify(t, sfunc);
            })
        ];
}

function useManagedQueryString(store, decodedLens, urlLens, param, shouldManage, debounceMs) {
  var getUnsubscribedParams = param.getUnsubscribedParams;
  var match = param.codec;
  var encode = match.encode;
  var decode = match.decode;
  var urlStruct = makeView(store, urlLens, undefined);
  var decoded = makeView(store, decodedLens, undefined);
  React.useEffect((function () {
          var initialUrl = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
          notify(urlStruct, (function (param) {
                  return initialUrl;
                }));
          notify(decoded, (function (prev) {
                  if (shouldManage(initialUrl)) {
                    return decode(initialUrl.search);
                  } else {
                    return prev;
                  }
                }));
          var watcherId = RescriptReactRouter.watchUrl(function (newUrl) {
                notify(urlStruct, (function (prevUrl) {
                        if (shouldManage(prevUrl) && shouldManage(newUrl)) {
                          return prevUrl;
                        } else {
                          return newUrl;
                        }
                      }));
              });
          return (function () {
                    RescriptReactRouter.unwatchUrl(watcherId);
                  });
        }), []);
  useSubscribeOnChange(decoded, (function (decoded) {
          notify(urlStruct, (function (cururl) {
                  if (!shouldManage(cururl)) {
                    return cururl;
                  }
                  var str = encode(decoded);
                  var tmp = str === "" ? undefined : str;
                  var newurl_path = cururl.path;
                  var newurl_hash = cururl.hash;
                  var newurl_search = Belt_Option.getWithDefault(QueryString$Util.QSUtil.mergeRepr(tmp, getUnsubscribedParams(cururl.search)), "");
                  var newurl = {
                    path: newurl_path,
                    hash: newurl_hash,
                    search: newurl_search
                  };
                  RescriptReactRouter.replace(renderUrl(newurl));
                  return newurl;
                }));
        }), "RescriptEquals", useOptionalDebounceIntervention(Caml_option.some(debounceMs)));
}

function useStorageBackedState(view, schema, storageKey, storageOpt, deleteStorageOnUnmountOpt, equalityFnOpt) {
  var storage = storageOpt !== undefined ? Caml_option.valFromOption(storageOpt) : sessionStorage;
  var deleteStorageOnUnmount = deleteStorageOnUnmountOpt !== undefined ? deleteStorageOnUnmountOpt : false;
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "RescriptEquals";
  var dangerouslyGetKey = function () {
    return storageKey(dangerouslyRead(view));
  };
  useSubscribeOnChange(view, (function (x) {
          storage.setItem(dangerouslyGetKey(), JSON.stringify(S$RescriptSchema.serializeOrRaiseWith(x, schema)));
        }), equalityFn, undefined);
  var getStorage = function () {
    return Belt_Option.flatMap(Caml_option.null_to_opt(storage.getItem(dangerouslyGetKey())), (function (str) {
                  var rse = S$RescriptSchema.parseAnyWith(str, S$RescriptSchema.jsonString(schema, undefined));
                  if (rse.TAG === "Ok") {
                    return Caml_option.some(rse._0);
                  }
                  console.error("Trouble parsing useStorageBackedState item @ [" + dangerouslyGetKey() + "]: " + S$RescriptSchema.$$Error.message(rse._0));
                }));
  };
  React.useEffect((function () {
          var foundItem = getStorage();
          if (foundItem !== undefined) {
            var foundItem$1 = Caml_option.valFromOption(foundItem);
            notify(view, (function (param) {
                    return foundItem$1;
                  }));
          }
          if (deleteStorageOnUnmount) {
            return (function () {
                      storage.removeItem(dangerouslyGetKey());
                    });
          }
          
        }), []);
}

function makeStringableSchema(toString, fromString) {
  return S$RescriptSchema.transform(S$RescriptSchema.string, (function (s) {
                return {
                        p: (function (str) {
                            var v = fromString(str);
                            if (v !== undefined) {
                              return Caml_option.valFromOption(v);
                            } else {
                              return s.fail(str + " could not be parsed", undefined);
                            }
                          }),
                        s: (function (val) {
                            return toString(val);
                          })
                      };
              }));
}

var jsonUnitSchema = S$RescriptSchema.transform(S$RescriptSchema.literal(null), (function (param) {
        return {
                p: (function (param) {
                    
                  }),
                s: (function () {
                    return null;
                  })
              };
      }));

function reduceParser(carry, fieldReducerSchema, fieldCounter, intermediate, s) {
  return function (dict) {
    var prevParse = Belt_Option.mapWithDefault(carry, {
          TAG: "Ok",
          _0: intermediate
        }, (function (prevReducer) {
            return S$RescriptSchema.parseAnyWith(dict, prevReducer(intermediate));
          }));
    var match = Belt_Option.mapWithDefault(dict[String(fieldCounter)], prevParse, (function (json) {
            return S$RescriptSchema.parseWith(json, fieldReducerSchema(Belt_Result.getWithDefault(prevParse, intermediate)));
          }));
    var e;
    if (prevParse.TAG === "Ok") {
      if (match.TAG === "Ok") {
        return match._0;
      }
      e = match._0;
    } else {
      e = prevParse._0;
    }
    return s.fail(S$RescriptSchema.$$Error.message(e), undefined);
  };
}

function reduceSerializer(carry, fieldReducerSchema, fieldCounter) {
  return function (intermediate) {
    var resultantDict = Belt_Option.mapWithDefault(carry, {}, (function (prevReducer) {
            return S$RescriptSchema.parseOrRaiseWith(S$RescriptSchema.serializeOrRaiseWith(intermediate, prevReducer(intermediate)), S$RescriptSchema.dict(S$RescriptSchema.json(false)));
          }));
    resultantDict[String(fieldCounter)] = S$RescriptSchema.serializeOrRaiseWith(intermediate, fieldReducerSchema(intermediate));
    return resultantDict;
  };
}

function childParserToFieldParser(lens, blankChild, param, intermediate, s) {
  var childReducer = param.reducerSchema;
  var existingChild = function (key) {
    return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(lens.read(intermediate), (function (param) {
                          if (Caml_obj.equal(param[0], key)) {
                            return Caml_option.some(param[1]);
                          }
                          
                        })), 0), blankChild(key));
  };
  return function (arr) {
    return lens.write(intermediate, Belt_Array.map(arr, (function (param) {
                      var key = param[0];
                      var existingChild$1 = existingChild(key);
                      var child = S$RescriptSchema.parseWith(param[1], childReducer(existingChild$1));
                      if (child.TAG === "Ok") {
                        return [
                                key,
                                child._0
                              ];
                      } else {
                        return s.fail(S$RescriptSchema.$$Error.message(child._0), undefined);
                      }
                    })));
  };
}

function childSerializerToFieldSerializer(lens, param) {
  var childReducer = param.reducerSchema;
  return function (intermediate) {
    return Belt_Array.map(lens.read(intermediate), (function (param) {
                  var v = param[1];
                  return [
                          param[0],
                          S$RescriptSchema.serializeOrRaiseWith(v, childReducer(v))
                        ];
                }));
  };
}

var SchemaImpl = {
  reduceParser: reduceParser,
  reduceSerializer: reduceSerializer,
  childParserToFieldParser: childParserToFieldParser,
  childSerializerToFieldSerializer: childSerializerToFieldSerializer
};

function fromCore(param, fieldCounter, prevReducer) {
  var reducerSchema = param.reducerSchema;
  var reducerSchema$1 = function (intermediate) {
    return S$RescriptSchema.transform(S$RescriptSchema.dict(S$RescriptSchema.json(false)), (function (s) {
                  return {
                          p: reduceParser(prevReducer, reducerSchema, fieldCounter, intermediate, s),
                          s: reduceSerializer(prevReducer, reducerSchema, fieldCounter)
                        };
                }));
  };
  return {
          checkIsDirty: param.checkIsDirty,
          polyReducer: param.polyReducer,
          reducerSchema: reducerSchema$1,
          fieldCounter: fieldCounter,
          makeSchema: (function (schemaVersion, getBlank) {
              return S$RescriptSchema.transform(S$RescriptSchema.tuple2(S$RescriptSchema.literal(schemaVersion), S$RescriptSchema.json(false)), (function (s) {
                            return {
                                    p: (function (param) {
                                        var intermediate = S$RescriptSchema.parseWith(param[1], reducerSchema$1(getBlank()));
                                        if (intermediate.TAG === "Ok") {
                                          return intermediate._0;
                                        } else {
                                          return s.fail(S$RescriptSchema.$$Error.message(intermediate._0), undefined);
                                        }
                                      }),
                                    s: (function (intermediate) {
                                        return [
                                                schemaVersion,
                                                S$RescriptSchema.serializeOrRaiseWith(intermediate, reducerSchema$1(intermediate))
                                              ];
                                      })
                                  };
                          }));
            })
        };
}

function emptyContainer() {
  return fromCore({
              checkIsDirty: (function (param) {
                  return false;
                }),
              polyReducer: (function (param, a) {
                  return a;
                }),
              reducerSchema: (function (i) {
                  return S$RescriptSchema.transform(jsonUnitSchema, (function (param) {
                                return {
                                        p: (function () {
                                            return i;
                                          }),
                                        s: (function (param) {
                                            
                                          })
                                      };
                              }));
                })
            }, 0, undefined);
}

function container(nonFormFieldSchema) {
  return fromCore({
              checkIsDirty: (function (param) {
                  return false;
                }),
              polyReducer: (function (param, a) {
                  return a;
                }),
              reducerSchema: (function (i) {
                  return nonFormFieldSchema(i);
                })
            }, 0, undefined);
}

function add(param, param$1) {
  var polyReducer = param$1.polyReducer;
  var checkIsDirty = param$1.checkIsDirty;
  var prevPolyReducer = param.polyReducer;
  var prevDirty = param.checkIsDirty;
  return fromCore({
              checkIsDirty: (function (i) {
                  if (prevDirty(i)) {
                    return true;
                  } else {
                    return checkIsDirty(i);
                  }
                }),
              polyReducer: (function (t, i) {
                  return polyReducer(t, prevPolyReducer(t, i));
                }),
              reducerSchema: param$1.reducerSchema
            }, param.fieldCounter + 1 | 0, param.reducerSchema);
}

function addChildren(param, lens, childUtil, keySchema, blankChild) {
  var prevPolyReducer = param.polyReducer;
  var prevDirty = param.checkIsDirty;
  var cmap = function (i, m) {
    return Belt_Array.map(lens.read(i), (function (param) {
                  return [
                          param[0],
                          m(param[1])
                        ];
                }));
  };
  return fromCore({
              checkIsDirty: (function (i) {
                  if (prevDirty(i)) {
                    return true;
                  } else {
                    return Belt_Array.some(cmap(i, (function (child) {
                                      return childUtil.checkIsDirty(child);
                                    })), (function (param) {
                                  return param[1];
                                }));
                  }
                }),
              polyReducer: (function (t, i) {
                  var prevModified = prevPolyReducer(t, i);
                  var modifiedChildren = cmap(prevModified, (function (child) {
                          return childUtil.polyReducer(t, child);
                        }));
                  return lens.write(prevModified, modifiedChildren);
                }),
              reducerSchema: (function (intermediate) {
                  return S$RescriptSchema.transform(S$RescriptSchema.array(S$RescriptSchema.tuple2(keySchema, S$RescriptSchema.json(false))), (function (s) {
                                return {
                                        p: childParserToFieldParser(lens, blankChild, childUtil, intermediate, s),
                                        s: childSerializerToFieldSerializer(lens, childUtil)
                                      };
                              }));
                })
            }, param.fieldCounter + 1 | 0, param.reducerSchema);
}

var Group = {
  SchemaImpl: SchemaImpl,
  fromCore: fromCore,
  emptyContainer: emptyContainer,
  container: container,
  add: add,
  addChildren: addChildren
};

function blankStore(blank) {
  return {
          initialValue: blank,
          value: blank,
          lastTouched: undefined
        };
}

function make(intermediateToStore, validate, toPrimitive, schema, blankValue, extendValidationWith) {
  var storeSchema = function (ps) {
    return S$RescriptSchema.transform(S$RescriptSchema.tuple3(ps, ps, S$RescriptSchema.$$null(S$RescriptSchema.$$float)), (function (param) {
                  return {
                          p: (function (param) {
                              return {
                                      initialValue: param[0],
                                      value: param[1],
                                      lastTouched: param[2]
                                    };
                            }),
                          s: (function (param) {
                              return [
                                      param.initialValue,
                                      param.value,
                                      param.lastTouched
                                    ];
                            })
                        };
                }));
  };
  var storeToValue_read = function (param) {
    return param.value;
  };
  var storeToValue_write = function (store, v) {
    if (Caml_obj.equal(store.value, v)) {
      return store;
    } else {
      return {
              initialValue: store.initialValue,
              value: v,
              lastTouched: Date.now()
            };
    }
  };
  var storeToValue = {
    read: storeToValue_read,
    write: storeToValue_write
  };
  var storeToInitialValue = function (asPrefill) {
    return {
            read: (function (param) {
                return param.initialValue;
              }),
            write: (function (store, initialValue) {
                var value = store.value;
                return {
                        initialValue: initialValue,
                        value: !asPrefill || Belt_Option.isSome(store.lastTouched) ? value : initialValue,
                        lastTouched: store.lastTouched
                      };
              })
          };
  };
  var storeToChangedValue_read = function (param) {
    var value = param.value;
    if (Caml_obj.equal(param.initialValue, value)) {
      return ;
    } else {
      return Caml_option.some(value);
    }
  };
  var storeToChangedValue_write = function (store, o) {
    return {
            initialValue: store.initialValue,
            value: Belt_Option.getWithDefault(o, blankValue),
            lastTouched: store.lastTouched
          };
  };
  var storeToChangedValue = {
    read: storeToChangedValue_read,
    write: storeToChangedValue_write
  };
  var getLens = function (lensT) {
    switch (lensT) {
      case "Value" :
          return chain(intermediateToStore, storeToValue);
      case "InitialValueOnly" :
          return chain(intermediateToStore, storeToInitialValue(false));
      case "InitialValueAsPrefill" :
          return chain(intermediateToStore, storeToInitialValue(true));
      
    }
  };
  var validate$1 = function (intermediate, targetOpt) {
    var target = targetOpt !== undefined ? targetOpt : "ValidateInputValue";
    var tmp;
    tmp = target === "ValidateInputValue" ? "Value" : "InitialValueOnly";
    var validated = validate(getLens(tmp).read(intermediate));
    return Belt_Option.mapWithDefault(extendValidationWith, validated, (function (fn) {
                  return fn(intermediate, validated);
                }));
  };
  var projectInput = function (intermediate) {
    var match = intermediateToStore.read(intermediate);
    var lastTouched = match.lastTouched;
    return {
            initialValue: match.initialValue,
            value: match.value,
            lastTouched: lastTouched,
            wasTouched: Belt_Option.isSome(lastTouched),
            result: validate$1(intermediate, "ValidateInputValue")
          };
  };
  return {
          checkIsDirty: (function (intermediate) {
              return Belt_Option.isSome(chain(intermediateToStore, storeToChangedValue).read(intermediate));
            }),
          polyReducer: (function (t, intermediate) {
              if (t === "MarkTouchedNow") {
                var init = intermediateToStore.read(intermediate);
                return intermediateToStore.write(intermediate, {
                            initialValue: init.initialValue,
                            value: init.value,
                            lastTouched: Date.now()
                          });
              }
              var init$1 = intermediateToStore.read(intermediate);
              return intermediateToStore.write(intermediate, {
                          initialValue: init$1.initialValue,
                          value: intermediateToStore.read(intermediate).initialValue,
                          lastTouched: init$1.lastTouched
                        });
            }),
          reducerSchema: (function (carry) {
              return S$RescriptSchema.transform(storeSchema(schema), (function (param) {
                            return {
                                    p: (function (store) {
                                        return intermediateToStore.write(carry, store);
                                      }),
                                    s: (function (intermediate) {
                                        return intermediateToStore.read(intermediate);
                                      })
                                  };
                          }));
            }),
          blankValue: blankValue,
          blankStore: {
            initialValue: blankValue,
            value: blankValue,
            lastTouched: undefined
          },
          getValue: (function (i) {
              return chain(intermediateToStore, storeToValue).read(i);
            }),
          getInitialValue: (function (i) {
              return getLens("InitialValueOnly").read(i);
            }),
          set: (function (intermediate, prefill, lensOpt) {
              var lens = lensOpt !== undefined ? lensOpt : "Value";
              return getLens(lens).write(intermediate, prefill);
            }),
          setFromValid: (function (intermediate, prefill, lensOpt) {
              var lens = lensOpt !== undefined ? lensOpt : "Value";
              return getLens(lens).write(intermediate, toPrimitive(prefill));
            }),
          validate: validate$1,
          lens: getLens,
          projectInput: projectInput,
          changedInputLens: chain(intermediateToStore, storeToChangedValue)
        };
}

function makeString(none, none$1, none$2, extra, extra$1) {
  return make(none, none$1, none$2, S$RescriptSchema.string, extra, extra$1);
}

function makeBool(none, none$1, none$2, extra, extra$1) {
  return make(none, none$1, none$2, S$RescriptSchema.bool, extra, extra$1);
}

function makeInt(none, none$1, none$2, extra, extra$1) {
  return make(none, none$1, none$2, S$RescriptSchema.$$int, extra, extra$1);
}

function makeOptional(lens, validate, toPrimitive, schema, blankValidationResult, blankValueOpt, extendValidationWith) {
  var blankValue = blankValueOpt !== undefined ? Caml_option.valFromOption(blankValueOpt) : undefined;
  var validateOptional = function (o) {
    if (o !== undefined) {
      return Belt_Result.map(validate(Caml_option.valFromOption(o)), (function (v) {
                    return Caml_option.some(v);
                  }));
    } else {
      return blankValidationResult;
    }
  };
  return make(lens, validateOptional, (function (o) {
                return Belt_Option.map(o, toPrimitive);
              }), S$RescriptSchema.$$null(schema), blankValue, extendValidationWith);
}

function makeOptionalString(none, none$1, none$2, extra, extra$1, extra$2) {
  return makeOptional(none, none$1, none$2, S$RescriptSchema.string, extra, extra$1, extra$2);
}

function makeOptionalBool(none, none$1, none$2, extra, extra$1, extra$2) {
  return makeOptional(none, none$1, none$2, S$RescriptSchema.bool, extra, extra$1, extra$2);
}

function makeOptionalInt(none, none$1, none$2, extra, extra$1, extra$2) {
  return makeOptional(none, none$1, none$2, S$RescriptSchema.$$int, extra, extra$1, extra$2);
}

function delegate(param, delegateLens) {
  var write = delegateLens.write;
  var read = delegateLens.read;
  var projectInput = param.projectInput;
  var lens = param.lens;
  var validate = param.validate;
  var setFromValid = param.setFromValid;
  var set = param.set;
  var getInitialValue = param.getInitialValue;
  var getValue = param.getValue;
  var reducerSchema = param.reducerSchema;
  var polyReducer = param.polyReducer;
  var checkIsDirty = param.checkIsDirty;
  return {
          checkIsDirty: (function (i) {
              return checkIsDirty(read(i));
            }),
          polyReducer: (function (pt, parent) {
              return write(parent, polyReducer(pt, read(parent)));
            }),
          reducerSchema: (function (carry) {
              return S$RescriptSchema.transform(reducerSchema(read(carry)), (function (param) {
                            return {
                                    p: (function (child) {
                                        return write(carry, child);
                                      }),
                                    s: (function (intermediate) {
                                        return read(intermediate);
                                      })
                                  };
                          }));
            }),
          blankValue: param.blankValue,
          blankStore: param.blankStore,
          getValue: (function (i) {
              return getValue(read(i));
            }),
          getInitialValue: (function (i) {
              return getInitialValue(read(i));
            }),
          set: (function (i, p, lensOpt) {
              var lens = lensOpt !== undefined ? lensOpt : "Value";
              return write(i, set(read(i), p, lens));
            }),
          setFromValid: (function (i, p, lensOpt) {
              var lens = lensOpt !== undefined ? lensOpt : "Value";
              return write(i, setFromValid(read(i), p, lens));
            }),
          validate: (function (i, targetOpt) {
              var target = targetOpt !== undefined ? targetOpt : "ValidateInputValue";
              return validate(read(i), target);
            }),
          lens: (function (t) {
              return chain(delegateLens, lens(t));
            }),
          projectInput: (function (i) {
              return projectInput(read(i));
            }),
          changedInputLens: chain(delegateLens, param.changedInputLens)
        };
}

function useState(v, param, $staropt$star) {
  var equalityFn = $staropt$star !== undefined ? $staropt$star : "RescriptEquals";
  return useReadWrite(makeView(v, param.lens("Value"), undefined), equalityFn);
}

function use(v, param, $staropt$star) {
  var lens = param.lens;
  var equalityFn = $staropt$star !== undefined ? $staropt$star : "RescriptEquals";
  return [
          useComputed(v, param.projectInput, equalityFn, undefined),
          (function (sfunc) {
              notify(makeView(v, lens("Value"), undefined), sfunc);
            })
        ];
}

function useOption(v, ff, blank, writeBackBlankAsNoneOpt, equalityFnOpt) {
  var writeBackBlankAsNone = writeBackBlankAsNoneOpt !== undefined ? writeBackBlankAsNoneOpt : true;
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "RescriptEquals";
  return use(makeView(v, {
                  read: (function (opt) {
                      return Belt_Option.getWithDefault(opt, blank);
                    }),
                  write: (function (param, val) {
                      if (writeBackBlankAsNone && Caml_obj.equal(val, blank)) {
                        return ;
                      } else {
                        return Caml_option.some(val);
                      }
                    })
                }, undefined), ff, equalityFn);
}

function useStateOption(v, ff, blank, writeBackBlankAsNoneOpt, equalityFnOpt) {
  var writeBackBlankAsNone = writeBackBlankAsNoneOpt !== undefined ? writeBackBlankAsNoneOpt : true;
  var equalityFn = equalityFnOpt !== undefined ? equalityFnOpt : "RescriptEquals";
  return useState(makeView(v, {
                  read: (function (opt) {
                      return Belt_Option.getWithDefault(opt, blank);
                    }),
                  write: (function (param, val) {
                      if (writeBackBlankAsNone && Caml_obj.equal(val, blank)) {
                        return ;
                      } else {
                        return Caml_option.some(val);
                      }
                    })
                }, undefined), ff, equalityFn);
}

function useIsDirty(v, utils) {
  return useComputed(v, utils.checkIsDirty, undefined, undefined);
}

function resetToInitialValue(v, utils) {
  notify(v, (function (i) {
          return utils.polyReducer("ResetToInitialValue", i);
        }));
}

function markAsTouched(v, utils) {
  notify(v, (function (i) {
          return utils.polyReducer("MarkTouchedNow", i);
        }));
}

function HopperState$Observable$FormField$Component$WithMapping(props) {
  var map = props.map;
  var input = props.input;
  var view = props.view;
  var match;
  match = view.TAG === "Direct" ? use(view._0, input, undefined) : useOption(view._0, input, view._1, undefined, undefined);
  var fromVal = map[1];
  var toVal = map[0];
  var set = match[1];
  var match$1 = match[0];
  var result = match$1.result;
  var tmp;
  tmp = result.TAG === "Ok" ? undefined : result._0;
  return props.children({
              initialValue: toVal(match$1.initialValue),
              value: toVal(match$1.value),
              lastTouched: match$1.lastTouched,
              wasTouched: match$1.wasTouched,
              result: result,
              set: React.useCallback((function (sfunc) {
                      set(function (prim) {
                            return fromVal(sfunc(toVal(prim)));
                          });
                    }), []),
              error: Belt_Result.isError(result),
              valid: Belt_Result.isOk(result),
              errorText: tmp
            });
}

var WithMapping = {
  make: HopperState$Observable$FormField$Component$WithMapping
};

function HopperState$Observable$FormField$Component(props) {
  return JsxRuntime.jsx(HopperState$Observable$FormField$Component$WithMapping, {
              view: props.view,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              children: props.children
            });
}

var Component = {
  WithMapping: WithMapping,
  make: HopperState$Observable$FormField$Component
};

var FormField = {
  makeStringableSchema: makeStringableSchema,
  jsonUnitSchema: jsonUnitSchema,
  Group: Group,
  blankStore: blankStore,
  make: make,
  makeString: makeString,
  makeBool: makeBool,
  makeInt: makeInt,
  makeOptional: makeOptional,
  makeOptionalString: makeOptionalString,
  makeOptionalBool: makeOptionalBool,
  makeOptionalInt: makeOptionalInt,
  delegate: delegate,
  useState: useState,
  use: use,
  useOption: useOption,
  useStateOption: useStateOption,
  useIsDirty: useIsDirty,
  resetToInitialValue: resetToInitialValue,
  markAsTouched: markAsTouched,
  Component: Component
};

var Observable = {
  WriteOnReadOnlyDerivedQuantity: WriteOnReadOnlyDerivedQuantity,
  dangerouslyRead: dangerouslyRead,
  noopEffect: noopEffect,
  compileEffects: compileEffects,
  subscribe: subscribe,
  unsubscribe: unsubscribe,
  doNotification: doNotification,
  notify: notify,
  useStore: useStore,
  makeView: makeView,
  getRoot: getRoot,
  addSkipUpdateCheck: addSkipUpdateCheck,
  defaultIntervention: defaultIntervention,
  useOptionalDebounceIntervention: useOptionalDebounceIntervention,
  useSubscribeOnChange: useSubscribeOnChange,
  makeExnOptionView: makeExnOptionView,
  asSetter: asSetter,
  useReadOnly: useReadOnly,
  useComputed: useComputed,
  useReadWrite: useReadWrite,
  useManagedQueryString: useManagedQueryString,
  useStorageBackedState: useStorageBackedState,
  FormField: FormField
};

var setter = ReactState$Util.setter;

var getter = ReactState$Util.getter;

exports.setter = setter;
exports.getter = getter;
exports.renderUrl = renderUrl;
exports.Lens = Lens;
exports.Observable = Observable;
/* jsonUnitSchema Not a pure module */
