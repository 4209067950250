// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppAlert$Thin = require("../../atoms/AppAlert.bs.js");
var Stack = require("@mui/joy/Stack").default;
var ReactState$Util = require("util/src/ReactState.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var JsxRuntime = require("react/jsx-runtime");
var PaymentSuggestions$Thin = require("../../../../utils/sales-flow/PaymentSuggestions.bs.js");
var CircularProgress = require("@mui/joy/CircularProgress").default;
var Processor_BraintreeHostedFields$Thin = require("../../../../utils/payments/Processor_BraintreeHostedFields.bs.js");

function BraintreeFlow$UnconditionallyFireEffect(props) {
  var effect = props.effect;
  React.useEffect(function () {
        effect();
      });
  return null;
}

var UnconditionallyFireEffect = {
  make: BraintreeFlow$UnconditionallyFireEffect
};

function BraintreeFlow$WithLoaded(props) {
  var paymentReturn = props.paymentReturn;
  var isSelected = props.isSelected;
  var setPaymentAction = props.setPaymentAction;
  var bookingId = props.bookingId;
  var payment = props.payment;
  var processPaymentEffect = props.loaded.processPaymentEffect;
  var isSessionPayment;
  isSessionPayment = payment.TAG === "Manage" ? false : true;
  React.useEffect((function () {
          var match = PaymentSuggestions$Thin.getPaymentCents(payment);
          if (processPaymentEffect !== undefined) {
            if (isSessionPayment) {
              if (match !== undefined && isSelected) {
                setPaymentAction(function (param) {
                      return (function () {
                                processPaymentEffect(paymentReturn, {
                                      TAG: "AgainstSession",
                                      netAmountInPositiveCents: match
                                    });
                              });
                    });
              }
              
            } else if (bookingId !== undefined && match !== undefined && isSelected) {
              var bookingId$1 = Caml_option.valFromOption(bookingId);
              setPaymentAction(function (param) {
                    return (function () {
                              processPaymentEffect(paymentReturn, {
                                    TAG: "AgainstBooking",
                                    bookingId: bookingId$1,
                                    netAmountInPositiveCents: match
                                  });
                            });
                  });
            }
            
          }
          
        }), [
        isSelected,
        PaymentSuggestions$Thin.getPaymentCents(payment)
      ]);
  return null;
}

var WithLoaded = {
  make: BraintreeFlow$WithLoaded
};

function BraintreeFlow$SupportedCards$CardImg(props) {
  var card = props.card;
  var src = card.imgUrl;
  if (src !== undefined) {
    return JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx("img", {
                      style: {
                        cursor: "default",
                        width: "64px"
                      },
                      alt: card.niceName,
                      src: src
                    }),
                arrow: true,
                size: "sm",
                title: Caml_option.some(card.niceName)
              });
  } else {
    return JsxRuntime.jsx("div", {});
  }
}

var CardImg = {
  make: BraintreeFlow$SupportedCards$CardImg
};

function BraintreeFlow$SupportedCards(props) {
  return JsxRuntime.jsx(Stack, {
              direction: "row",
              flexWrap: "wrap",
              children: Caml_option.some(Belt_Array.mapWithIndex(props.supportedCards, (function (i, card) {
                          return JsxRuntime.jsx(BraintreeFlow$SupportedCards$CardImg, {
                                      card: card
                                    }, String(i));
                        })))
            });
}

var SupportedCards = {
  CardImg: CardImg,
  make: BraintreeFlow$SupportedCards
};

function BraintreeFlow(props) {
  var setCalculatedFee = props.setCalculatedFee;
  var paymentReturn = props.paymentReturn;
  var isSelected = props.isSelected;
  var payment = props.payment;
  var match = props.useHostedFields();
  var getFee = match.getFee;
  var loaded = match.loaded;
  React.useEffect((function () {
          var match = PaymentSuggestions$Thin.getPaymentCents(payment);
          var exit = 0;
          if (isSelected && match !== undefined) {
            if (match === 0) {
              setCalculatedFee(function (param) {
                    
                  });
            } else {
              setCalculatedFee(function (param) {
                    return getFee(match);
                  });
            }
          } else {
            exit = 1;
          }
          if (exit === 1) {
            setCalculatedFee(function (param) {
                  
                });
          }
          
        }), [
        isSelected,
        payment
      ]);
  var tmp;
  tmp = typeof loaded !== "object" || loaded.TAG !== "Success" ? "" : "1px solid #E6EBEF";
  var tmp$1;
  tmp$1 = typeof loaded !== "object" || loaded.TAG !== "Success" ? 0 : 2;
  var tmp$2;
  tmp$2 = typeof loaded !== "object" || loaded.TAG !== "Success" ? 0 : 3;
  var tmp$3;
  var exit = 0;
  if (typeof loaded !== "object") {
    exit = 1;
  } else if (loaded.TAG === "Success") {
    var loaded$1 = loaded._0;
    tmp$3 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(BraintreeFlow$WithLoaded, {
                  loaded: loaded$1,
                  getFee: getFee,
                  payment: payment,
                  bookingId: props.bookingId,
                  setPaymentAction: props.setPaymentAction,
                  isSelected: isSelected,
                  paymentReturn: paymentReturn
                }),
            JsxRuntime.jsx(BraintreeFlow$SupportedCards, {
                  supportedCards: loaded$1.supportedCardTypes
                })
          ]
        });
  } else {
    tmp$3 = JsxRuntime.jsx(AppAlert$Thin.make, {
          message: "Credit card processor couldn't load",
          type_: "error"
        });
  }
  if (exit === 1) {
    tmp$3 = JsxRuntime.jsx(Stack, {
          direction: "row",
          justifyContent: "center",
          children: Caml_option.some(JsxRuntime.jsx(CircularProgress, {
                    size: "md",
                    variant: "solid"
                  }))
        });
  }
  var match$1 = ReactState$Util.getter(paymentReturn);
  var tmp$4;
  if (isSelected && !(typeof match$1 !== "object" || match$1.TAG !== "Failure")) {
    var err = match$1._0;
    switch (err.TAG) {
      case "StringError" :
          tmp$4 = JsxRuntime.jsx(AppAlert$Thin.make, {
                message: err._0,
                type_: "error"
              });
          break;
      case "BraintreeHostedFieldsError" :
          tmp$4 = JsxRuntime.jsx(AppAlert$Thin.make, {
                message: err._0.displayableString,
                type_: "error"
              });
          break;
      case "PlaidError" :
          tmp$4 = null;
          break;
      
    }
  } else {
    tmp$4 = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      sx: {
                        backgroundColor: "#F0F3F5",
                        border: tmp,
                        p: {
                          xs: tmp$1,
                          md: tmp$2
                        },
                        borderRadius: "4px"
                      },
                      children: Caml_option.some(JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Processor_BraintreeHostedFields$Thin.Form.make, {
                                      show: RemoteData$Util.isSuccess(loaded)
                                    }),
                                id: "hosted-fields"
                              }))
                    }),
                JsxRuntime.jsx(BraintreeFlow$UnconditionallyFireEffect, {
                      effect: match.loadAfterFormInDOM
                    }),
                tmp$3,
                tmp$4
              ]
            });
}

var make = BraintreeFlow;

exports.UnconditionallyFireEffect = UnconditionallyFireEffect;
exports.WithLoaded = WithLoaded;
exports.SupportedCards = SupportedCards;
exports.make = make;
/* react Not a pure module */
