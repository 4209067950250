// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var HopperURL$Thin = require("../context/HopperURL.bs.js");
var CurrentUser$Thin = require("../context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var ClaimNewPaxInvitationPage$Thin = require("../pages/ClaimNewPaxInvitationPage.bs.js");
var ClaimNewPaxInvitationModel$Thin = require("../utils/accounts/ClaimNewPaxInvitationModel.bs.js");
var ClaimNewPaxInvitationRouteQuery_graphql$Thin = require("../__generated__/ClaimNewPaxInvitationRouteQuery_graphql.bs.js");

var convertVariables = ClaimNewPaxInvitationRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = ClaimNewPaxInvitationRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ClaimNewPaxInvitationRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ClaimNewPaxInvitationRouteQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ClaimNewPaxInvitationRouteQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ClaimNewPaxInvitationRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ClaimNewPaxInvitationRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ClaimNewPaxInvitationRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ClaimNewPaxInvitationRouteQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ClaimNewPaxInvitationRoute(props) {
  var match = props.nav;
  var navSelector = match[1];
  var allegedToken = match[0];
  var match$1 = use({
        allegedToken: allegedToken
      }, undefined, undefined, undefined);
  var fragmentRefs = match$1.fragmentRefs;
  var invitation = ClaimNewPaxInvitationModel$Thin.Query.Deref.use(fragmentRefs).derefClaimNewPassengerInvitation;
  var currentUser = Belt_Array.get(Belt_Option.mapWithDefault(ClaimNewPaxInvitationModel$Thin.Query.CurrentAccount.use(fragmentRefs).currentUserProfiles, [], (function (param) {
              return param.nodes;
            })), 0);
  var match$2 = CurrentUser$Thin.Context.use();
  var canBookVoyage = match$2.canBookVoyage;
  var signOnStatus = match$2.signOnStatus;
  var view = HopperState$Util.Observable.useStore((function () {
          return ClaimNewPaxInvitationModel$Thin.blankModel;
        }), undefined);
  var isSuccess = HopperState$Util.Observable.useComputed(view, (function (param) {
          var tmp = param.claimProgress;
          if (typeof tmp !== "object" || tmp.TAG !== "Success") {
            return false;
          } else {
            return true;
          }
        }), undefined, undefined);
  React.useEffect(function () {
        if (isSuccess && invitation !== undefined) {
          var voyageSlug = invitation.voyageSlug;
          HopperURL$Thin.redirectToPath(SysCtx$Thin.vars.bookingsLink, (function (urlState) {
                  return {
                          dismissibleAlerts: [{
                              TAG: "YouWereAddedToABooking",
                              _0: voyageSlug
                            }],
                          loginRedirect: urlState.loginRedirect,
                          token: urlState.token,
                          landing: urlState.landing,
                          expiryMillis: urlState.expiryMillis
                        };
                }), undefined);
        }
        
      });
  React.useEffect((function () {
          var isNonAnonymouslyTryingToClaimAnonymously = navSelector === "ClaimAnonymously" && signOnStatus !== "ANONYMOUS";
          var isNotLoggedInRightButTryingToClaimLoggedIn = navSelector === "ClaimLoggedIn" && !(signOnStatus === "SIGNED_ON" || signOnStatus === "VERIFY_EMAIL");
          var cannotClaimOnThisVoyage = navSelector === "ClaimLoggedIn" && Belt_Option.mapWithDefault(invitation, false, (function (param) {
                  return !canBookVoyage(param.voyageSlug);
                }));
          if (isNonAnonymouslyTryingToClaimAnonymously || isNotLoggedInRightButTryingToClaimLoggedIn || cannotClaimOnThisVoyage) {
            ClaimNewPaxInvitationModel$Thin.$$URL.redirect([
                  allegedToken,
                  "SelectClaimMethod"
                ]);
          }
          
        }), []);
  var tmp;
  if (invitation !== undefined) {
    switch (navSelector) {
      case "SelectClaimMethod" :
          tmp = JsxRuntime.jsx(ClaimNewPaxInvitationPage$Thin.SelectClaimMethod.make, {
                prefill: invitation,
                allegedToken: allegedToken
              });
          break;
      case "ClaimAnonymously" :
          tmp = JsxRuntime.jsx(ClaimNewPaxInvitationPage$Thin.ClaimAnonymously.make, {
                view: view,
                prefill: invitation,
                allegedToken: allegedToken
              });
          break;
      case "ClaimLoggedIn" :
          tmp = currentUser !== undefined ? JsxRuntime.jsx(ClaimNewPaxInvitationPage$Thin.ClaimLoggedIn.make, {
                  view: view,
                  prefill: invitation,
                  realAccountData: currentUser,
                  allegedToken: allegedToken
                }) : JsxRuntime.jsx(ClaimNewPaxInvitationPage$Thin.SelectClaimMethod.make, {
                  prefill: invitation,
                  allegedToken: allegedToken
                });
          break;
      
    }
  } else {
    tmp = JsxRuntime.jsx(ClaimNewPaxInvitationPage$Thin.InvitationNotFound.make, {
          allegedToken: allegedToken
        });
  }
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsx(StandardLayout$Thin.make, {
                        dash: false,
                        children: tmp
                      }))
            });
}

var $$URL;

var make = ClaimNewPaxInvitationRoute;

exports.$$URL = $$URL;
exports.Query = Query;
exports.make = make;
/* use Not a pure module */
