// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"voyStartDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"voyEndDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"orgSlug":{"c":"Util.CustomScalars.OrgSlug.Exn"},"brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.parse,
  "Util.CustomScalars.OrgSlug.Exn": CS_Slugs$Util.OrgSlug.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDHero_listitem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandFamilySlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orgSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyageSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingId",
      "storageKey": null
    }
  ],
  "type": "BookingListitem",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Slugs-Util Not a pure module */
