// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var DOM$Util = require("util/src/DOM.bs.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppLink$Thin = require("../common/atoms/AppLink.bs.js");
var Card = require("@mui/joy/Card").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var Button = require("@mui/joy/Button").default;
var CurrentUser$Thin = require("../../context/CurrentUser.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CardContent = require("@mui/joy/CardContent").default;
var CardOverflow = require("@mui/joy/CardOverflow").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var BDHeroQuery_graphql$Thin = require("../../__generated__/BDHeroQuery_graphql.bs.js");
var BDHero_listitem_graphql$Thin = require("../../__generated__/BDHero_listitem_graphql.bs.js");
var OpenInNew = require("@mui/icons-material/OpenInNew").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;

var convertVariables = BDHeroQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = BDHeroQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = BDHeroQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, BDHeroQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BDHeroQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BDHeroQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BDHeroQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BDHeroQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BDHeroQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertFragment = BDHero_listitem_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BDHero_listitem_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDHero_listitem_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDHero_listitem_graphql$Thin.node, convertFragment, fRef);
}

var ListitemFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BDHero(props) {
  var canEdit = props.canEdit;
  var setEditBannerVisible = props.setEditBannerVisible;
  var setDraftMode = props.setDraftMode;
  var data = use$1(props.fragmentRefs);
  var query = use({
        voyageSlug: CS_Slugs$Util.VoyageSlug.toString(data.voyageSlug)
      }, undefined, undefined, undefined);
  var match = React.useState(function () {
        return false;
      });
  var setViewMore = match[1];
  var viewMore = match[0];
  var bookingId = CS_NonemptyStrings$Util.BookingId.toString(data.bookingId);
  var match$1 = CurrentUser$Thin.Context.use();
  var accountId = match$1.accountId;
  var email = match$1.email;
  var match$2 = query.voyageLinks;
  var links = match$2 !== undefined ? Belt_Array.map(Belt_SortArray.stableSortBy(match$2.nodes, (function (l, r) {
                if (l.ordinality < r.ordinality) {
                  return -1;
                } else {
                  return 1;
                }
              })), (function (l) {
            var description = l.description;
            return [
                    CS_NonemptyStrings$Util.NonEmptyString.toString(l.name),
                    CS_NonemptyStrings$Util.NonEmptyString.toString(l.url),
                    description !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(description)) : "",
                    true,
                    l.attachBfcustIdentifiers ? [
                        [
                          "email",
                          email
                        ],
                        [
                          "accountID",
                          accountId
                        ]
                      ] : undefined
                  ];
          })) : [];
  var match$3 = CS_Slugs$Util.VoyageSlug.toString(data.voyageSlug);
  var heroImage = match$3 === "2026" ? (new URL("./../../static/JoCo2026banner.jpg", import.meta.url)) : (new URL("./../../static/NADMBeachProfile-r.jpg", import.meta.url));
  var displayLinks = React.useMemo((function () {
          if (viewMore) {
            return links;
          }
          var match = links.length;
          if (match !== 4) {
            return Belt_Array.slice(links, 0, 4);
          } else {
            return links;
          }
        }), [viewMore]);
  var heroButtonRef = React.useRef(null);
  React.useEffect((function () {
          var toggleVisibility = function () {
            var heroButton = heroButtonRef.current;
            return Belt_Option.map((heroButton == null) ? undefined : Caml_option.some(heroButton), (function (el) {
                          var rect = el.getBoundingClientRect();
                          var shouldBeVisible = rect.top + window.scrollY < window.scrollY;
                          if (shouldBeVisible) {
                            setEditBannerVisible(function (param) {
                                  return true;
                                });
                          } else {
                            setEditBannerVisible(function (param) {
                                  return false;
                                });
                          }
                        }));
          };
          if (canEdit) {
            DOM$Util.addEventListener("scroll", toggleVisibility);
          }
          return (function () {
                    if (canEdit) {
                      DOM$Util.removeEventListener("scroll", toggleVisibility);
                    }
                    
                  });
        }), []);
  return JsxRuntime.jsx(Stack, {
              direction: "row",
              sx: {
                width: "100%"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Card, {
                        children: [
                          JsxRuntime.jsx(CardOverflow, {
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          direction: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          spacing: 2,
                                          sx: {
                                            py: 3,
                                            px: 2,
                                            height: "14rem"
                                          },
                                          children: [
                                            JsxRuntime.jsxs(Stack, {
                                                  direction: "column",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  children: [
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          textColor: "neutral.100",
                                                          children: Caml_option.some(DateOnly$Util.prettyFormatDateRange(data.voyStartDate, data.voyEndDate))
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "h1",
                                                          textColor: "common.white",
                                                          fontWeight: "normal",
                                                          children: Caml_option.some(bookingId)
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "h3",
                                                          textColor: "common.white",
                                                          fontWeight: "normal",
                                                          children: Caml_option.some(props.voyageName)
                                                        })
                                                  ]
                                                }),
                                            canEdit ? JsxRuntime.jsx(Stack, {
                                                    ref: heroButtonRef,
                                                    children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                              onClick: (function (param) {
                                                                  setDraftMode(function (param) {
                                                                        return true;
                                                                      });
                                                                }),
                                                              endDecorator: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {})),
                                                              children: Caml_option.some(Txt$Thin.BookingDetails.manageBookingButtonText.value)
                                                            }))
                                                  }) : null
                                          ]
                                        })),
                                sx: {
                                  borderRadius: links.length !== 0 ? "" : "md",
                                  backgroundImage: links.length !== 0 ? "linear-gradient(\n                rgba(0, 0, 0, 0.4), \n                rgba(0, 0, 0, 0.4)\n                ), url(" + heroImage + ")" : "",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  position: "relative"
                                }
                              }),
                          links.length !== 0 ? JsxRuntime.jsx(CardContent, {
                                  children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                            children: [
                                              Belt_Array.mapWithIndex(displayLinks, (function (i, param) {
                                                      return JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                                                  children: [
                                                                    JsxRuntime.jsx(AppLink$Thin.make, {
                                                                          to: param[1],
                                                                          forceTargetBlank: param[3],
                                                                          textColor: "neutral.800",
                                                                          searchParams: param[4],
                                                                          children: JsxRuntime.jsx(Stack, {
                                                                                direction: "row",
                                                                                justifyContent: "flex-start",
                                                                                alignItems: "center",
                                                                                spacing: 1,
                                                                                children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                                                          level: "body-md",
                                                                                          noWrap: false,
                                                                                          sx: {
                                                                                            display: "inline"
                                                                                          },
                                                                                          textColor: "inherit",
                                                                                          children: [
                                                                                            param[0],
                                                                                            JsxRuntime.jsx(OpenInNew, {
                                                                                                  fontSize: "inherit",
                                                                                                  sx: {
                                                                                                    position: "relative",
                                                                                                    top: 3,
                                                                                                    left: 3
                                                                                                  }
                                                                                                })
                                                                                          ]
                                                                                        }))
                                                                              })
                                                                        }),
                                                                    JsxRuntime.jsx(Typography, {
                                                                          level: "body-xs",
                                                                          textColor: "text.tertiary",
                                                                          children: Caml_option.some(param[2])
                                                                        })
                                                                  ],
                                                                  alignItems: {
                                                                    TAG: "Fixed",
                                                                    _0: "flex-start"
                                                                  },
                                                                  xs: 12,
                                                                  md: 3
                                                                }, String(i));
                                                    })),
                                              links.length > 4 ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                                direction: "row",
                                                                spacing: 1,
                                                                sx: {
                                                                  "&:hover": {
                                                                    cursor: "pointer"
                                                                  }
                                                                },
                                                                onClick: (function (param) {
                                                                    setViewMore(function (param) {
                                                                          return !viewMore;
                                                                        });
                                                                  }),
                                                                children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Link.make, {
                                                                          children: [
                                                                            viewMore ? Txt$Thin.BookingDetails.heroLinksViewFewer.value : Txt$Thin.BookingDetails.heroLinksViewMore.value,
                                                                            JsxRuntime.jsx(ChevronRight, {
                                                                                  color: "primary",
                                                                                  fontSize: "md",
                                                                                  sx: {
                                                                                    position: "relative",
                                                                                    top: 3,
                                                                                    left: 3,
                                                                                    transform: viewMore ? "rotate(-90deg)" : "rotate(90deg)"
                                                                                  }
                                                                                })
                                                                          ],
                                                                          level: "body-sm",
                                                                          component: "button",
                                                                          textColor: "primary.500",
                                                                          sx: {
                                                                            display: "inline",
                                                                            "&:hover": {
                                                                              textDecoration: "none"
                                                                            }
                                                                          }
                                                                        }))
                                                              })),
                                                      justifyContent: {
                                                        TAG: "Responsive",
                                                        _0: {
                                                          xs: "flex-start",
                                                          md: "flex-end"
                                                        }
                                                      },
                                                      alignItems: {
                                                        TAG: "Fixed",
                                                        _0: "flex-end"
                                                      },
                                                      sx: {
                                                        display: "flex"
                                                      },
                                                      xs: 12,
                                                      md: Math.imul(4 - displayLinks.length % 4 | 0, 3)
                                                    }) : null
                                            ],
                                            container: true,
                                            rowSpacing: 2,
                                            columnSpacing: 2,
                                            sx: {
                                              flexGrow: 1
                                            }
                                          }))
                                }) : null
                        ],
                        color: "neutral",
                        variant: "outlined",
                        sx: {
                          backgroundImage: links.length !== 0 ? "" : "linear-gradient(\n          rgba(0, 0, 0, 0.4), \n          rgba(0, 0, 0, 0.4)\n        ), url(" + heroImage + ")",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          flex: "1"
                        }
                      }))
            });
}

var make = BDHero;

exports.Query = Query;
exports.ListitemFragment = ListitemFragment;
exports.make = make;
/* use Not a pure module */
