// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var AppChip$Thin = require("../../common/atoms/AppChip.bs.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var $$Option = require("@mui/joy/Option").default;
var BookingDiff$Thin = require("../../../utils/booking/BookingDiff.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ListItemContent = require("@mui/joy/ListItemContent").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var DirectionsBoat = require("@mui/icons-material/DirectionsBoat").default;
var DoNotDisturbAlt = require("@mui/icons-material/DoNotDisturbAlt").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var AccountCircleOutlined = require("@mui/icons-material/AccountCircleOutlined").default;

function AddManagedAccount(props) {
  var managedOptions = props.managedOptions;
  var isPrimaryPax = props.isPrimaryPax;
  var action = props.action;
  var addOrErr = props.addOrErr;
  var idx = props.idx;
  var __mobile = props.mobile;
  var setConnectedAcct = props.setConnectedAcct;
  var connectedAcct = props.connectedAcct;
  var mobile = __mobile !== undefined ? __mobile : false;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var onSubmit = function (e) {
    e.preventDefault();
    if (connectedAcct !== undefined) {
      var acct = Caml_option.valFromOption(connectedAcct);
      var applyDiff;
      applyDiff = action === "Attach" ? BookingDiff$Thin.Alter.attachAnExistingPax(diff, idx, acct) : BookingDiff$Thin.Alter.replaceWithExistingPax(diff, idx, acct);
      return addOrErr(applyDiff);
    }
    console.log("No connected account selected");
  };
  if (mobile) {
    return JsxRuntime.jsxs(Stack, {
                sx: {
                  p: 2
                },
                children: [
                  JsxRuntime.jsx(Typography, {
                        level: "body-md",
                        sx: {
                          mb: 1,
                          color: "text.tertiary"
                        },
                        children: Caml_option.some(Txt$Thin.BookingDetails.addPassConnectedMobileDesc.value)
                      }),
                  JsxRuntime.jsx(Stack, {
                        sx: {
                          py: 1
                        },
                        children: Caml_option.some(JsxRuntime.jsx(List, {
                                  children: Caml_option.some(Belt_Array.map(managedOptions, (function (param) {
                                              var canBePax1 = param.canBePax1;
                                              var conflictBookingId = param.conflictBookingId;
                                              var cantAdd = param.cantAdd;
                                              var accountId = param.accountId;
                                              var tmp;
                                              tmp = cantAdd !== undefined ? (
                                                  cantAdd === "Booked" ? JsxRuntime.jsx(Stack, {
                                                          component: "span",
                                                          sx: {
                                                            pl: 1
                                                          },
                                                          children: Caml_option.some(JsxRuntime.jsx(AppChip$Thin.make, {
                                                                    children: conflictBookingId !== undefined ? "Booked (" + CS_NonemptyStrings$Util.BookingId.toString(Caml_option.valFromOption(conflictBookingId)) + ")" : "Already booked",
                                                                    color: "Neutral",
                                                                    noPad: true
                                                                  }))
                                                        }) : JsxRuntime.jsx(Stack, {
                                                          component: "span",
                                                          sx: {
                                                            pl: 1
                                                          },
                                                          children: Caml_option.some(JsxRuntime.jsx(AppChip$Thin.make, {
                                                                    children: "Already added",
                                                                    color: "Neutral",
                                                                    noPad: true
                                                                  }))
                                                        })
                                                ) : (
                                                  isPrimaryPax && !canBePax1 ? JsxRuntime.jsx(Stack, {
                                                          component: "span",
                                                          sx: {
                                                            pl: 1
                                                          },
                                                          children: Caml_option.some(JsxRuntime.jsx(AppChip$Thin.make, {
                                                                    children: "Cannot be primary",
                                                                    color: "Neutral",
                                                                    noPad: true
                                                                  }))
                                                        }) : null
                                                );
                                              return JsxRuntime.jsx(ListItem, {
                                                          children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.ListItemButton.make, {
                                                                    children: [
                                                                      JsxRuntime.jsx(ListItemDecorator, {
                                                                            children: Caml_option.some(JsxRuntime.jsx(AccountCircleOutlined, {})),
                                                                            sx: {
                                                                              "--ListItemDecorator-size": "1.75rem"
                                                                            }
                                                                          }),
                                                                      JsxRuntime.jsx(ListItemContent, {
                                                                            children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                                                      direction: "row",
                                                                                      alignItems: "center",
                                                                                      spacing: 1,
                                                                                      children: [
                                                                                        JsxRuntime.jsx(Typography, {
                                                                                              level: "inherit",
                                                                                              children: Caml_option.some(param.name)
                                                                                            }),
                                                                                        tmp
                                                                                      ]
                                                                                    }))
                                                                          })
                                                                    ],
                                                                    disabled: Belt_Option.isSome(cantAdd) || isPrimaryPax && !canBePax1,
                                                                    selected: connectedAcct !== undefined ? Caml_obj.equal(Caml_option.valFromOption(connectedAcct), accountId) : false,
                                                                    onClick: (function (param) {
                                                                        setConnectedAcct(function (param) {
                                                                              return Caml_option.some(accountId);
                                                                            });
                                                                      })
                                                                  }))
                                                        }, CS_NonemptyStrings$Util.AccountId.toString(accountId));
                                            })))
                                }))
                      })
                ]
              });
  }
  var tmp;
  tmp = action === "Attach" ? Txt$Thin.BookingDetails.addPassAddPassenger.value : Txt$Thin.BookingDetails.addPassReplacePassenger.value;
  return JsxRuntime.jsxs(Stack, {
              component: "form",
              spacing: 2,
              sx: {
                py: 1
              },
              children: [
                JsxRuntime.jsxs(FormControl, {
                      children: [
                        JsxRuntime.jsx(FormLabel, {
                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassConnectedLabel.value)
                            }),
                        JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                              children: Caml_option.some(Belt_Array.map(managedOptions, (function (param) {
                                          var canBePax1 = param.canBePax1;
                                          var conflictBookingId = param.conflictBookingId;
                                          var cantAdd = param.cantAdd;
                                          var name = param.name;
                                          var accountId = param.accountId;
                                          var tmp;
                                          tmp = cantAdd !== undefined ? (
                                              cantAdd === "Booked" ? JsxRuntime.jsx(DirectionsBoat, {}) : JsxRuntime.jsx(ModeEditOutlined, {})
                                            ) : (
                                              isPrimaryPax && !canBePax1 ? JsxRuntime.jsx(DoNotDisturbAlt, {}) : JsxRuntime.jsx(AccountCircleOutlined, {})
                                            );
                                          var tmp$1;
                                          var exit = 0;
                                          if (isPrimaryPax && !canBePax1) {
                                            tmp$1 = name + " (can't be Primary Contact)";
                                          } else {
                                            exit = 1;
                                          }
                                          if (exit === 1) {
                                            tmp$1 = cantAdd !== undefined ? (
                                                cantAdd === "Booked" ? name + " (booked in " + (
                                                    conflictBookingId !== undefined ? CS_NonemptyStrings$Util.BookingId.toString(Caml_option.valFromOption(conflictBookingId)) : ""
                                                  ) + ")" : name + " (already added)"
                                              ) : name;
                                          }
                                          return JsxRuntime.jsxs($$Option, {
                                                      value: Caml_option.some(accountId),
                                                      children: [
                                                        JsxRuntime.jsx(ListItemDecorator, {
                                                              children: Caml_option.some(tmp),
                                                              sx: {
                                                                "--ListItemDecorator-size": "1.75rem"
                                                              }
                                                            }),
                                                        tmp$1
                                                      ],
                                                      disabled: Belt_Option.isSome(cantAdd) || isPrimaryPax && !canBePax1,
                                                      label: name
                                                    }, CS_NonemptyStrings$Util.AccountId.toString(accountId));
                                        }))),
                              onChange: (function (param, v) {
                                  setConnectedAcct(function (param) {
                                        return Belt_Option.getWithDefault(v, undefined);
                                      });
                                }),
                              placeholder: Txt$Thin.BookingDetails.addPassConnectedPlaceholder.value,
                              slotProps: {
                                listbox: {
                                  sx: {
                                    zIndex: "modal"
                                  }
                                }
                              },
                              value: connectedAcct,
                              variant: "outlined"
                            }),
                        JsxRuntime.jsx(FormHelperText, {
                              children: Caml_option.some(Txt$Thin.BookingDetails.addPassConnectedHelperText.value)
                            })
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(AppButton$Thin.make, {
                            fullWidth: false,
                            loading: props.loading,
                            type_: "submit",
                            onClick: onSubmit,
                            size: "lg",
                            disabled: connectedAcct === undefined,
                            children: Caml_option.some(tmp)
                          })
                    })
              ]
            });
}

var make = AddManagedAccount;

exports.make = make;
/* Joy-Util Not a pure module */
