// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var DOM$Util = require("util/src/DOM.bs.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var AppChip$Thin = require("../../common/atoms/AppChip.bs.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var SalesCabin$Thin = require("../../../utils/sales-flow/SalesCabin.bs.js");
var CurrentUser$Thin = require("../../../context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FareClassDrawer$Thin = require("./FareClassDrawer.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var RequestCabinHoldByCategory$Thin = require("../mutations/RequestCabinHoldByCategory.bs.js");
var PeopleOutlined = require("@mui/icons-material/PeopleOutlined").default;
var KeyboardArrowDown = require("@mui/icons-material/KeyboardArrowDown").default;
var SquareFootOutlined = require("@mui/icons-material/SquareFootOutlined").default;

function CategoryCard(props) {
  var sessionData = props.sessionData;
  var voyageSlug = props.voyageSlug;
  var urlState = props.urlState;
  var url = props.url;
  var nextTarget = props.nextTarget;
  var setLoginModalOpen = props.setLoginModalOpen;
  var displayTotal = props.displayTotal;
  var c = props.c;
  var flow = props.flow;
  var occupancy = props.occupancy;
  var needsFareClassDrawer = c.fareClasses.length > 1;
  var fc = Belt_Array.get(c.fareClasses, 0);
  var firstFc = fc !== undefined ? Caml_option.some(fc.fcSlug) : undefined;
  var match = React.useState(function () {
        return false;
      });
  var setShowMore = match[1];
  var showMore = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setFareDrawerOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setDisplayToggle = match$2[1];
  var cardTextRef = React.useRef(null);
  var quoteFc = HopperState$Util.Observable.useComputed(sessionData, (function (m) {
          var match = m.quote;
          if (typeof match !== "object") {
            return ;
          } else {
            return Caml_option.some(match._0.fareClassSlug);
          }
        }), undefined, undefined);
  var setQuote = function (quote) {
    HopperState$Util.Observable.notify(sessionData, (function (m) {
            return {
                    paxes: m.paxes,
                    bc: m.bc,
                    pax1: m.pax1,
                    paymentOption: m.paymentOption,
                    sessionSaveIsMutating: m.sessionSaveIsMutating,
                    signup: m.signup,
                    url: m.url,
                    paymentSuggestions: m.paymentSuggestions,
                    quote: quote,
                    referral: m.referral,
                    referralSuccess: m.referralSuccess,
                    referralError: m.referralError
                  };
          }));
  };
  var match$3 = c.availability;
  var unselectable;
  switch (match$3) {
    case "Available" :
    case "Limited" :
    case "LimitedAtPrice" :
        unselectable = false;
        break;
    default:
      unselectable = true;
  }
  React.useEffect((function () {
          var toggleShowMore = function () {
            var cardText = cardTextRef.current;
            return Belt_Option.map((cardText == null) ? undefined : Caml_option.some(cardText), (function (el) {
                          var isOverflown = el.scrollHeight > el.clientHeight;
                          var rect = el.getBoundingClientRect();
                          var twoLinesTall = 0.875 * 1.5 * 16.0 * 2.0;
                          var cardTooTall = rect.bottom - rect.top > twoLinesTall;
                          if (isOverflown || cardTooTall) {
                            return setDisplayToggle(function (param) {
                                        return true;
                                      });
                          } else {
                            return setDisplayToggle(function (param) {
                                        return false;
                                      });
                          }
                        }));
          };
          toggleShowMore();
          DOM$Util.addEventListener("resize", toggleShowMore);
          return (function () {
                    DOM$Util.removeEventListener("resize", toggleShowMore);
                  });
        }), []);
  var match$4;
  switch (flow) {
    case "ByCategory" :
        match$4 = [
          SFState$Thin.ByCategory.CabinTarget.setCat,
          SFState$Thin.ByCategory.CabinTarget.setFare
        ];
        break;
    case "AllNoCabin" :
        match$4 = [
          SFState$Thin.ByAllNoCabin.CabinTarget.setCat,
          SFState$Thin.ByAllNoCabin.CabinTarget.setFare
        ];
        break;
    case "All" :
        match$4 = [
          SFState$Thin.ByAll.CabinTarget.setCat,
          SFState$Thin.ByAll.CabinTarget.setFare
        ];
        break;
    
  }
  var setFare = match$4[1];
  var setCat = match$4[0];
  var match$5 = displayTotal ? [
      c.quotePricePerBooking,
      c.referencePricePerBooking
    ] : [
      c.quotePricePerPerson,
      c.referencePricePerPerson
    ];
  var referencePrice = match$5[1];
  var quotePrice = match$5[0];
  var match$6 = CurrentUser$Thin.Context.use();
  var signOnStatus = match$6.signOnStatus;
  var completableStatuses = props.getBookableStatuses(voyageSlug);
  var canCompleteBooking = function (s) {
    return Belt_Array.some(completableStatuses, (function (status) {
                  return Caml_obj.equal(status, s);
                }));
  };
  var needsToLogIn;
  needsToLogIn = (signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") && signOnStatus === "ANONYMOUS" ? true : false;
  var redirectNoCabinHold = function (newUrlState) {
    var redirect = SFState$Thin.urlString(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(newUrlState, nextTarget)), url);
    HopperState$Util.Observable.notify(sessionData, (function (model) {
            return {
                    paxes: model.paxes,
                    bc: model.bc,
                    pax1: model.pax1,
                    paymentOption: undefined,
                    sessionSaveIsMutating: model.sessionSaveIsMutating,
                    signup: model.signup,
                    url: model.url,
                    paymentSuggestions: model.paymentSuggestions,
                    quote: model.quote,
                    referral: model.referral,
                    referralSuccess: model.referralSuccess,
                    referralError: model.referralError
                  };
          }));
    RescriptReactRouter.push(redirect);
  };
  var onCabinHoldSuccess = function (newUrlState) {
    return function (expiry) {
      var redirect = SFState$Thin.urlString(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(SFState$Thin.Alter.setCabinHold(newUrlState, expiry), nextTarget)), url);
      HopperState$Util.Observable.notify(sessionData, (function (model) {
              return {
                      paxes: model.paxes,
                      bc: model.bc,
                      pax1: model.pax1,
                      paymentOption: undefined,
                      sessionSaveIsMutating: model.sessionSaveIsMutating,
                      signup: model.signup,
                      url: model.url,
                      paymentSuggestions: model.paymentSuggestions,
                      quote: model.quote,
                      referral: model.referral,
                      referralSuccess: model.referralSuccess,
                      referralError: model.referralError
                    };
            }));
      RescriptReactRouter.push(redirect);
    };
  };
  var match$7 = RequestCabinHoldByCategory$Thin.useMutation(flow, voyageSlug, undefined);
  var cabinHoldError = match$7.error;
  var requestCabinHoldByCategory = match$7.requestCabinHoldByCategory;
  var onClick = function (param) {
    if (needsFareClassDrawer) {
      return setFareDrawerOpen(function (param) {
                  return true;
                });
    }
    if (firstFc === undefined) {
      return ;
    }
    var newUrlState = setFare(setCat(urlState, c.slug), Caml_option.valFromOption(firstFc));
    SFState$Thin.navigate(SFState$Thin.URLState.serialize(newUrlState), url);
    if (quoteFc === firstFc) {
      
    } else {
      setQuote("Unavailable");
    }
    if (canCompleteBooking(signOnStatus)) {
      return requestCabinHoldByCategory(newUrlState, onCabinHoldSuccess(newUrlState));
    } else if ((signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") && signOnStatus === "ANONYMOUS") {
      return setLoginModalOpen(function (param) {
                  return true;
                });
    } else {
      return redirectNoCabinHold(newUrlState);
    }
  };
  var availability = c.availability;
  var availChip;
  var exit = 0;
  switch (availability) {
    case "Limited" :
    case "LimitedAtPrice" :
        exit = 1;
        break;
    default:
      availChip = undefined;
  }
  if (exit === 1) {
    availChip = Caml_option.some(SalesCabin$Thin.getAvailChip(availability, occupancy, c.catName));
  }
  var maxOccupancy = c.catMaxOccupancy;
  var blurb = c.blurb;
  var a = c.availability;
  var tmp;
  var exit$1 = 0;
  switch (a) {
    case "Available" :
    case "Limited" :
    case "LimitedAtPrice" :
        exit$1 = 1;
        break;
    default:
      tmp = SalesCabin$Thin.getAvailText(a, occupancy);
  }
  if (exit$1 === 1) {
    tmp = needsFareClassDrawer ? "Select Fare" : "Reserve for " + String(occupancy) + " guest" + (
        occupancy > 1 ? "s" : ""
      );
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      spacing: 1,
                      sx: {
                        backgroundColor: "background.surface",
                        border: "1px solid",
                        borderColor: "neutral.200",
                        borderRadius: "8px",
                        position: "relative",
                        p: {
                          xs: 2,
                          md: 4
                        }
                      },
                      children: [
                        availChip !== undefined ? JsxRuntime.jsx(Stack, {
                                sx: {
                                  display: {
                                    xs: "flex",
                                    md: "none"
                                  }
                                },
                                children: Caml_option.some(Caml_option.valFromOption(availChip))
                              }) : null,
                        JsxRuntime.jsxs(Stack, {
                              direction: {
                                xs: "column",
                                md: "row"
                              },
                              spacing: {
                                xs: 6,
                                md: 8
                              },
                              sx: {
                                flex: 1
                              },
                              children: [
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      spacing: 2,
                                      sx: {
                                        flex: 1
                                      },
                                      children: [
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              spacing: 1,
                                              children: [
                                                JsxRuntime.jsx(Typography, {
                                                      level: "h4",
                                                      children: Caml_option.some(CS_Slugs$Util.CabinCategorySlug.toPrimitive(c.slug).toUpperCase() + " (" + c.catName + ")")
                                                    }),
                                                JsxRuntime.jsxs(Stack, {
                                                      direction: "row",
                                                      flexWrap: "wrap",
                                                      children: [
                                                        JsxRuntime.jsx(AppChip$Thin.make, {
                                                              children: String(c.catMinSqFoot) + "-" + String(c.catMaxSqFoot) + " SQ FT",
                                                              startDecorator: Caml_option.some(JsxRuntime.jsx(SquareFootOutlined, {}))
                                                            }),
                                                        JsxRuntime.jsx(Stack, {
                                                              sx: {
                                                                width: "0.5rem"
                                                              }
                                                            }),
                                                        JsxRuntime.jsx(AppChip$Thin.make, {
                                                              children: maxOccupancy !== 1 ? "Up to " + String(maxOccupancy) + " guests" : "1 guest",
                                                              startDecorator: Caml_option.some(JsxRuntime.jsx(PeopleOutlined, {}))
                                                            })
                                                      ]
                                                    })
                                              ]
                                            }),
                                        blurb !== undefined ? JsxRuntime.jsx(Stack, {
                                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                          direction: "column",
                                                          sx: {
                                                            position: "relative"
                                                          },
                                                          children: [
                                                            JsxRuntime.jsx(Typography, {
                                                                  ref: cardTextRef,
                                                                  level: "body-sm",
                                                                  sx: {
                                                                    height: "auto",
                                                                    overflow: "hidden",
                                                                    textOverflow: showMore ? "auto" : "ellipsis",
                                                                    display: "-webkit-box",
                                                                    "-webkit-line-clamp": showMore ? "unset" : "2",
                                                                    "-webkit-box-orient": "vertical"
                                                                  },
                                                                  children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(Caml_option.valFromOption(blurb)))
                                                                }),
                                                            JsxRuntime.jsx(Stack, {
                                                                  sx: {
                                                                    display: match$2[0] ? "flex" : "none",
                                                                    cursor: "pointer",
                                                                    pt: "5px"
                                                                  },
                                                                  onClick: (function (e) {
                                                                      e.stopPropagation();
                                                                      setShowMore(function (prev) {
                                                                            return !prev;
                                                                          });
                                                                    }),
                                                                  children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                            children: showMore ? "Show less" : "Show more",
                                                                            level: "body-sm",
                                                                            component: "button",
                                                                            color: "primary",
                                                                            endDecorator: Caml_option.some(JsxRuntime.jsx(KeyboardArrowDown, {
                                                                                      sx: {
                                                                                        transform: showMore ? "rotate(180deg)" : "rotate(0deg)",
                                                                                        "&:hover": {
                                                                                          textDecoration: "none"
                                                                                        }
                                                                                      }
                                                                                    }))
                                                                          }))
                                                                })
                                                          ]
                                                        }))
                                              }) : null
                                      ]
                                    }),
                                JsxRuntime.jsxs(Stack, {
                                      direction: "column",
                                      justifyContent: "space-between",
                                      sx: {
                                        height: "100%",
                                        width: {
                                          xs: "100%",
                                          md: "fit-content"
                                        },
                                        alignItems: {
                                          xs: "flex-start",
                                          md: "flex-end"
                                        }
                                      },
                                      children: [
                                        availChip !== undefined ? JsxRuntime.jsx(Stack, {
                                                sx: {
                                                  display: {
                                                    xs: "none",
                                                    md: "flex"
                                                  },
                                                  pb: 2
                                                },
                                                children: Caml_option.some(Caml_option.valFromOption(availChip))
                                              }) : JsxRuntime.jsx("div", {}),
                                        JsxRuntime.jsxs(Stack, {
                                              sx: {
                                                width: {
                                                  xs: "100%",
                                                  md: "fit-content"
                                                },
                                                alignItems: {
                                                  xs: "flex-start",
                                                  md: "flex-end"
                                                }
                                              },
                                              children: [
                                                JsxRuntime.jsxs(Typography, {
                                                      level: "body-xs",
                                                      sx: {
                                                        color: "text.secondary"
                                                      },
                                                      children: [
                                                        "Price for ",
                                                        JsxRuntime.jsx("span", {
                                                              children: occupancy > 1 ? String(occupancy) + " Guests" : "1 Guest",
                                                              style: {
                                                                fontWeight: "900"
                                                              }
                                                            })
                                                      ]
                                                    }),
                                                JsxRuntime.jsx(Stack, {
                                                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                                direction: {
                                                                  xs: "row",
                                                                  md: "column"
                                                                },
                                                                justifyContent: "center",
                                                                sx: {
                                                                  alignItems: {
                                                                    xs: "flex-start",
                                                                    md: "flex-end"
                                                                  }
                                                                },
                                                                children: [
                                                                  JsxRuntime.jsxs(Stack, {
                                                                        direction: "row",
                                                                        alignItems: "center",
                                                                        children: [
                                                                          needsFareClassDrawer ? JsxRuntime.jsx(Typography, {
                                                                                  level: "body-xs",
                                                                                  sx: {
                                                                                    mr: "0.25rem"
                                                                                  },
                                                                                  children: "From"
                                                                                }) : null,
                                                                          quotePrice !== undefined ? JsxRuntime.jsx(Typography, {
                                                                                  level: "body-lg",
                                                                                  children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(quotePrice))))
                                                                                }) : (
                                                                              CS_Decimal$Util.Decimal10_2.toFloat(referencePrice) !== 0.0 ? JsxRuntime.jsx(Typography, {
                                                                                      level: "body-lg",
                                                                                      children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(referencePrice)))
                                                                                    }) : null
                                                                            )
                                                                        ]
                                                                      }),
                                                                  JsxRuntime.jsx(Stack, {
                                                                        direction: "column",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        sx: {
                                                                          height: "100%",
                                                                          ml: "0.25rem"
                                                                        },
                                                                        children: Caml_option.some(quotePrice !== undefined && CS_Decimal$Util.Decimal10_2.toFloat(referencePrice) !== 0.0 ? JsxRuntime.jsx(Typography, {
                                                                                    color: "danger",
                                                                                    level: "body-xs",
                                                                                    sx: {
                                                                                      textDecoration: "line-through"
                                                                                    },
                                                                                    children: Caml_option.some(Common$Thin.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(referencePrice)))
                                                                                  }) : null)
                                                                      })
                                                                ]
                                                              }))
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsx(Stack, {
                                              sx: {
                                                my: 1,
                                                width: {
                                                  xs: "100%",
                                                  sm: "fit-content"
                                                }
                                              },
                                              children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                        loading: !needsFareClassDrawer && match$7.isMutating,
                                                        onClick: onClick,
                                                        color: "primary",
                                                        size: "sm",
                                                        disabled: unselectable,
                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                  color: "inherit",
                                                                  level: "body-sm",
                                                                  noWrap: true,
                                                                  children: Caml_option.some(tmp)
                                                                }))
                                                      }))
                                            })
                                      ]
                                    })
                              ]
                            }),
                        cabinHoldError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                message: cabinHoldError,
                                type_: "error"
                              }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                      ]
                    }),
                JsxRuntime.jsx(FareClassDrawer$Thin.make, {
                      drawerOpen: match$1[0],
                      onClose: (function () {
                          setFareDrawerOpen(function (param) {
                                return false;
                              });
                        }),
                      category: c.slug,
                      fareClasses: c.fareClasses,
                      displayTotal: displayTotal,
                      openLoginModal: (function () {
                          setLoginModalOpen(function (param) {
                                return true;
                              });
                        }),
                      needsToLogIn: needsToLogIn,
                      urlState: urlState,
                      url: url,
                      setCat: setCat,
                      setFare: setFare,
                      requestCabinHoldByCategory: requestCabinHoldByCategory,
                      signOnStatus: signOnStatus,
                      redirectNoCabinHold: redirectNoCabinHold,
                      setQuote: setQuote,
                      quoteFc: quoteFc,
                      onCabinHoldSuccess: onCabinHoldSuccess,
                      canCompleteBooking: canCompleteBooking
                    })
              ]
            });
}

var make = CategoryCard;

exports.make = make;
/* react Not a pure module */
