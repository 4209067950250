// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SignUp$Thin = require("../../common/mutations/SignUp.bs.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var PaxFrame$Thin = require("./PaxFrame.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppButton$Thin = require("../../common/atoms/AppButton.bs.js");
var CurrentUser$Thin = require("../../../context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var LoginSubmit$Thin = require("../../login/mutations/LoginSubmit.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var PassengerForms$Thin = require("../../common/molecules/PassengerForms.bs.js");
var AccountAccessSection$Thin = require("../../account/AccountAccessSection.bs.js");
var RequestCabinHoldByCategory$Thin = require("../mutations/RequestCabinHoldByCategory.bs.js");

function BCForm$VerifyEmail(props) {
  var bc = props.bc;
  var refetchAll = props.refetchAll;
  var match = HopperState$Util.Observable.FormField.useState(bc, SFState$Thin.email, undefined);
  var resendVerify = AccountAccessSection$Thin.ResendVerify.useMutationWithNotify(function (resendVerifyMutation) {
        HopperState$Util.Observable.notify(bc, (function (pax) {
                var newrecord = Caml_obj.obj_dup(pax);
                newrecord.resendVerifyMutation = resendVerifyMutation;
                return newrecord;
              }));
      });
  var match$1 = HopperState$Util.Observable.useComputed(bc, (function (param) {
          var resendVerifyMutation = param.resendVerifyMutation;
          return [
                  AccountAccessSection$Thin.ResendVerify.resendVerifyIsLoading(resendVerifyMutation),
                  AccountAccessSection$Thin.ResendVerify.verifyResendError(resendVerifyMutation)
                ];
        }), undefined, undefined);
  var resendVerifyIsLoading = match$1[0];
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              justifyContent: "center",
              spacing: 1,
              sx: {
                p: {
                  xs: 2,
                  md: 4
                },
                border: "1px solid",
                borderColor: "neutral.300",
                backgroundColor: "neutral.200",
                borderRadius: "12px"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        alignItems: "center",
                        spacing: 1,
                        sx: {
                          pb: 1
                        },
                        children: [
                          JsxRuntime.jsx(Typography, {
                                level: "h4",
                                children: "Verify Your Email"
                              }),
                          JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                sx: {
                                  textAlign: "center"
                                },
                                textColor: "neutral.600",
                                children: Caml_option.some("We've sent a verification link to " + Belt_Option.getWithDefault(match[0], "your inbox") + ". Please click the link in the email to verify your account and return to this page.")
                              }),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                spacing: 2,
                                sx: {
                                  width: "fit-content",
                                  pt: 3
                                },
                                children: [
                                  JsxRuntime.jsx(AppButton$Thin.make, {
                                        onClick: (function (e) {
                                            e.preventDefault();
                                            refetchAll();
                                          }),
                                        size: "lg",
                                        children: "I did it!"
                                      }),
                                  JsxRuntime.jsx(AppButton$Thin.make, {
                                        onClick: (function (e) {
                                            e.preventDefault();
                                            resendVerify();
                                          }),
                                        color: "neutral",
                                        size: "sm",
                                        disabled: resendVerifyIsLoading,
                                        children: Caml_option.some(resendVerifyIsLoading ? "Sending..." : "Resend my verification email")
                                      })
                                ]
                              }),
                          Belt_Option.mapWithDefault(match$1[1], null, (function (e) {
                                  return JsxRuntime.jsx(Typography, {
                                              level: "body-sm",
                                              sx: {
                                                textAlign: "center"
                                              },
                                              textColor: "neutral.600",
                                              children: Caml_option.some(e)
                                            });
                                }))
                        ]
                      }))
            });
}

var VerifyEmail = {
  make: BCForm$VerifyEmail
};

function BCForm$VerificationBanner(props) {
  var bc = props.bc;
  var refetchAll = props.refetchAll;
  var match = HopperState$Util.Observable.FormField.useState(bc, SFState$Thin.email, undefined);
  var resendVerify = AccountAccessSection$Thin.ResendVerify.useMutationWithNotify(function (resendVerifyMutation) {
        HopperState$Util.Observable.notify(bc, (function (pax) {
                var newrecord = Caml_obj.obj_dup(pax);
                newrecord.resendVerifyMutation = resendVerifyMutation;
                return newrecord;
              }));
      });
  HopperState$Util.Observable.useComputed(bc, (function (param) {
          var resendVerifyMutation = param.resendVerifyMutation;
          return [
                  AccountAccessSection$Thin.ResendVerify.resendVerifyIsLoading(resendVerifyMutation),
                  AccountAccessSection$Thin.ResendVerify.verifyResendError(resendVerifyMutation)
                ];
        }), undefined, undefined);
  var tmp = props.signOnStatus;
  if (!(tmp === "VERIFY_EMAIL" || tmp === "INVITED" || tmp === "PENDING_2FA" || tmp === "CLEAR_JWT" || tmp === "ANONYMOUS" || tmp === "SIGNED_ON")) {
    return JsxRuntime.jsx(JsxRuntime.Fragment, {});
  }
  switch (tmp) {
    case "VERIFY_EMAIL" :
        return JsxRuntime.jsx(AppAlert$Thin.make, {
                    message: JsxRuntime.jsxs(Typography, {
                          level: "body-sm",
                          textColor: "neutral.600",
                          children: [
                            "You will receive an email to  \n          " + Belt_Option.getWithDefault(match[0], "your inbox") + " to verify your account. You may verify your account now or after you complete your booking.",
                            JsxRuntime.jsx("br", {}),
                            JsxRuntime.jsx("br", {}),
                            "If the email hasn't arrived in a few minutes, ",
                            JsxRuntime.jsx(Joy$Util.Link.make, {
                                  children: "click to resend",
                                  onClick: (function (e) {
                                      e.preventDefault();
                                      resendVerify();
                                    })
                                }),
                            ".",
                            JsxRuntime.jsx("br", {}),
                            "Already verified? ",
                            JsxRuntime.jsx(Joy$Util.Link.make, {
                                  children: "Click here to refresh!",
                                  onClick: (function (e) {
                                      e.preventDefault();
                                      refetchAll();
                                    })
                                })
                          ]
                        }),
                    type_: "warning"
                  });
    case "SIGNED_ON" :
        return JsxRuntime.jsx(AppAlert$Thin.make, {
                    message: "Your email address has been verified!",
                    type_: "success"
                  });
    default:
      return JsxRuntime.jsx(JsxRuntime.Fragment, {});
  }
}

var VerificationBanner = {
  make: BCForm$VerificationBanner
};

function BCForm(props) {
  var canCompleteBooking = props.canCompleteBooking;
  var setPageLevelError = props.setPageLevelError;
  var refetchCurrentUserInfo = props.refetchCurrentUserInfo;
  var voyageSlug = props.voyageSlug;
  var urlState = props.urlState;
  var url = props.url;
  var bc = props.bc;
  var onCabinHoldSuccess = function (expiry) {
    SFState$Thin.navigate(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setCabinHold(urlState, expiry)), url);
  };
  var match = RequestCabinHoldByCategory$Thin.useMutation(props.flow, voyageSlug, setPageLevelError);
  var requestCabinHoldByCategory = match.requestCabinHoldByCategory;
  var match$1 = CurrentUser$Thin.Context.use();
  var refetchSignOnStatus = match$1.refetchSignOnStatus;
  var signOnStatus = match$1.signOnStatus;
  var refetchAll = function () {
    refetchCurrentUserInfo();
    refetchSignOnStatus();
  };
  var match$2 = LoginSubmit$Thin.useMutationOnSignup(refetchAll);
  var match$3 = SignUp$Thin.useMutation(setPageLevelError, undefined, match$2.loginOnSignUp);
  var signUpError = match$3.error;
  React.useEffect((function () {
          var match = urlState.cabinHold;
          if (match !== undefined || !canCompleteBooking(signOnStatus)) {
            
          } else {
            requestCabinHoldByCategory(urlState, onCabinHoldSuccess);
          }
        }), [
        signOnStatus,
        urlState.cabinHold
      ]);
  var intervalRef = React.useRef(null);
  React.useEffect((function () {
          var interval = setInterval((function () {
                  if (!(signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON")) {
                    return ;
                  }
                  switch (signOnStatus) {
                    case "ANONYMOUS" :
                    case "VERIFY_EMAIL" :
                        return refetchAll();
                    default:
                      return ;
                  }
                }), 10000);
          if (signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") {
            switch (signOnStatus) {
              case "VERIFY_EMAIL" :
                  intervalRef.current = interval;
                  break;
              case "SIGNED_ON" :
                  var id = intervalRef.current;
                  if (!(id == null)) {
                    clearInterval(id);
                  }
                  break;
              default:
                
            }
          }
          return (function () {
                    var id = intervalRef.current;
                    if (!(id == null)) {
                      clearInterval(id);
                      return ;
                    }
                    
                  });
        }), [signOnStatus]);
  var tmp;
  tmp = (signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") && signOnStatus === "ANONYMOUS" ? JsxRuntime.jsxs(Typography, {
          level: "body-sm",
          sx: {
            pb: 2
          },
          textColor: "neutral.600",
          children: [
            "We're creating an account for you. Your information will only be used for reservation and customer service purposes. \n          Read more in our ",
            JsxRuntime.jsx(Joy$Util.Link.make, {
                  children: "FAQ",
                  href: "https://jococruise.com/" + CS_Slugs$Util.VoyageSlug.toString(voyageSlug) + "faq/#what-is-your-privacy-policy",
                  target: "_blank"
                }),
            "."
          ]
        }) : JsxRuntime.jsx(JsxRuntime.Fragment, {});
  return JsxRuntime.jsxs(PaxFrame$Thin.make, {
              title: "Billing Contact",
              required: true,
              variant: "Grey",
              children: [
                tmp,
                JsxRuntime.jsx(PassengerForms$Thin.SignUp.make, {
                      fragmentRefs: props.countryFrag,
                      showTitleSection: false,
                      pax: bc,
                      signedUp: canCompleteBooking(signOnStatus),
                      disabled: canCompleteBooking(signOnStatus),
                      noHelperText: true,
                      loading: match$3.isMutating || match$2.isMutating,
                      signUpError: signUpError,
                      setLoginError: match$2.setError,
                      setSignUpError: match$3.setError,
                      signUp: match$3.signUp
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      spacing: 1,
                      sx: {
                        pt: 2
                      },
                      children: [
                        canCompleteBooking(signOnStatus) ? JsxRuntime.jsx(BCForm$VerificationBanner, {
                                refetchAll: refetchAll,
                                signOnStatus: signOnStatus,
                                bc: bc
                              }) : null,
                        signUpError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                                message: signUpError,
                                type_: "error"
                              }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                      ]
                    })
              ]
            });
}

var make = BCForm;

exports.VerifyEmail = VerifyEmail;
exports.VerificationBanner = VerificationBanner;
exports.make = make;
/* react Not a pure module */
