// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var HopperURL$Thin = require("../context/HopperURL.bs.js");
var LoginPage$Thin = require("../pages/LoginPage.bs.js");
var CurrentUser$Thin = require("../context/CurrentUser.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");

function LoginRoute(props) {
  var match = CurrentUser$Thin.Context.use();
  var signOnStatus = match.signOnStatus;
  var redirect = HopperURL$Thin.Context.useQueryStateProjection((function (param) {
          return param.loginRedirect;
        }), undefined, undefined);
  var forceRefresh = function (href) {
    return (window.location.href=href);
  };
  React.useEffect(function () {
        if (redirect !== undefined) {
          var exit = 0;
          if (signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") {
            switch (signOnStatus) {
              case "VERIFY_EMAIL" :
              case "SIGNED_ON" :
                  exit = 1;
                  break;
              default:
                
            }
          }
          if (exit === 1) {
            forceRefresh(HopperURL$Thin.getRenderedLink(function (param, prevGlobals) {
                      return [
                              redirect,
                              {
                                dismissibleAlerts: prevGlobals.dismissibleAlerts,
                                loginRedirect: undefined,
                                token: prevGlobals.token,
                                landing: prevGlobals.landing,
                                expiryMillis: prevGlobals.expiryMillis
                              }
                            ];
                    }));
          }
          
        } else {
          HopperURL$Thin.setGlobals((function (prevGlobals) {
                  return {
                          dismissibleAlerts: prevGlobals.dismissibleAlerts,
                          loginRedirect: SysCtx$Thin.vars.bookingsLink,
                          token: prevGlobals.token,
                          landing: prevGlobals.landing,
                          expiryMillis: prevGlobals.expiryMillis
                        };
                }), undefined);
        }
      });
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              dash: false,
              children: JsxRuntime.jsx(LoginPage$Thin.make, {})
            });
}

var make = LoginRoute;

exports.make = make;
/* react Not a pure module */
