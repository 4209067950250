// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../components/common/molecules/Dialog.bs.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var DateFns$Util = require("util/src/DateFns.bs.js");
var SFState$Thin = require("../utils/sales-flow/SFState.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Checkout$Thin = require("../components/sales-flow/checkout/Checkout.bs.js");
var RelayEnv$Thin = require("../RelayEnv.bs.js");
var SalesNav$Thin = require("../components/sales-flow/shared/SalesNav.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var PaxDetails$Thin = require("../components/sales-flow/passengers/PaxDetails.bs.js");
var SalesCabin$Thin = require("../utils/sales-flow/SalesCabin.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CategorySelection$Thin = require("../components/sales-flow/category/CategorySelection.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var SaveBookingSession$Thin = require("../components/sales-flow/mutations/SaveBookingSession.bs.js");
var CurrentUserCabinHolds$Thin = require("../components/sales-flow/queries/CurrentUserCabinHolds.bs.js");
var ClassAndSubclassSelection$Thin = require("../components/sales-flow/class-subclass/ClassAndSubclassSelection.bs.js");
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var SalesByCategoryPageQuery_graphql$Thin = require("../__generated__/SalesByCategoryPageQuery_graphql.bs.js");
var SalesByCategoryPage_query_graphql$Thin = require("../__generated__/SalesByCategoryPage_query_graphql.bs.js");
var SalesByCategoryPageVoyageQuery_graphql$Thin = require("../__generated__/SalesByCategoryPageVoyageQuery_graphql.bs.js");
var SalesByCategoryPageUserAndConnectedQuery_graphql$Thin = require("../__generated__/SalesByCategoryPageUserAndConnectedQuery_graphql.bs.js");
var SalesByCategoryPageUserAndConnectedCurrentQuery_graphql$Thin = require("../__generated__/SalesByCategoryPageUserAndConnectedCurrentQuery_graphql.bs.js");

var convertVariables = SalesByCategoryPageQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = SalesByCategoryPageQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = SalesByCategoryPageQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, SalesByCategoryPageQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, SalesByCategoryPageQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(SalesByCategoryPageQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(SalesByCategoryPageQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(SalesByCategoryPageQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(SalesByCategoryPageQuery_graphql$Thin.node, convertVariables);

var Query_availabilityStatus_decode = SalesByCategoryPageQuery_graphql$Thin.Utils.availabilityStatus_decode;

var Query_availabilityStatus_fromString = SalesByCategoryPageQuery_graphql$Thin.Utils.availabilityStatus_fromString;

var Query = {
  availabilityStatus_decode: Query_availabilityStatus_decode,
  availabilityStatus_fromString: Query_availabilityStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertVariables$1 = SalesByCategoryPageVoyageQuery_graphql$Thin.Internal.convertVariables;

var convertResponse$1 = SalesByCategoryPageVoyageQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse$1 = SalesByCategoryPageVoyageQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables$1, SalesByCategoryPageVoyageQuery_graphql$Thin.node, convertResponse$1);

var useLoader$1 = RescriptRelay_Query.useLoader(convertVariables$1, SalesByCategoryPageVoyageQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded$1 = RescriptRelay_Query.usePreloaded(SalesByCategoryPageVoyageQuery_graphql$Thin.node, convertResponse$1, (function (prim) {
        return prim;
      }));

var $$fetch$1 = RescriptRelay_Query.$$fetch(SalesByCategoryPageVoyageQuery_graphql$Thin.node, convertResponse$1, convertVariables$1);

var fetchPromised$1 = RescriptRelay_Query.fetchPromised(SalesByCategoryPageVoyageQuery_graphql$Thin.node, convertResponse$1, convertVariables$1);

var retain$1 = RescriptRelay_Query.retain(SalesByCategoryPageVoyageQuery_graphql$Thin.node, convertVariables$1);

var VoyageQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  use: use$1,
  useLoader: useLoader$1,
  usePreloaded: usePreloaded$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  retain: retain$1
};

var convertVariables$2 = SalesByCategoryPageUserAndConnectedQuery_graphql$Thin.Internal.convertVariables;

var convertResponse$2 = SalesByCategoryPageUserAndConnectedQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse$2 = SalesByCategoryPageUserAndConnectedQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use$2 = RescriptRelay_Query.useQuery(convertVariables$2, SalesByCategoryPageUserAndConnectedQuery_graphql$Thin.node, convertResponse$2);

var useLoader$2 = RescriptRelay_Query.useLoader(convertVariables$2, SalesByCategoryPageUserAndConnectedQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded$2 = RescriptRelay_Query.usePreloaded(SalesByCategoryPageUserAndConnectedQuery_graphql$Thin.node, convertResponse$2, (function (prim) {
        return prim;
      }));

var $$fetch$2 = RescriptRelay_Query.$$fetch(SalesByCategoryPageUserAndConnectedQuery_graphql$Thin.node, convertResponse$2, convertVariables$2);

var fetchPromised$2 = RescriptRelay_Query.fetchPromised(SalesByCategoryPageUserAndConnectedQuery_graphql$Thin.node, convertResponse$2, convertVariables$2);

var retain$2 = RescriptRelay_Query.retain(SalesByCategoryPageUserAndConnectedQuery_graphql$Thin.node, convertVariables$2);

var UserAndConnectedQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$2,
  convertResponse: convertResponse$2,
  convertWrapRawResponse: convertWrapRawResponse$2,
  use: use$2,
  useLoader: useLoader$2,
  usePreloaded: usePreloaded$2,
  $$fetch: $$fetch$2,
  fetchPromised: fetchPromised$2,
  retain: retain$2
};

var convertFragment = SalesByCategoryPage_query_graphql$Thin.Internal.convertFragment;

function use$3(fRef) {
  return RescriptRelay_Fragment.useFragment(SalesByCategoryPage_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SalesByCategoryPage_query_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(SalesByCategoryPage_query_graphql$Thin.node, convertFragment, fRef);
}

var makeRefetchVariables = SalesByCategoryPageUserAndConnectedCurrentQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = SalesByCategoryPageUserAndConnectedCurrentQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(SalesByCategoryPage_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var CurrentUserProfilesFragment_custSignonStatus_decode = SalesByCategoryPage_query_graphql$Thin.Utils.custSignonStatus_decode;

var CurrentUserProfilesFragment_custSignonStatus_fromString = SalesByCategoryPage_query_graphql$Thin.Utils.custSignonStatus_fromString;

var CurrentUserProfilesFragment_docGender_decode = SalesByCategoryPage_query_graphql$Thin.Utils.docGender_decode;

var CurrentUserProfilesFragment_docGender_fromString = SalesByCategoryPage_query_graphql$Thin.Utils.docGender_fromString;

var CurrentUserProfilesFragment = {
  custSignonStatus_decode: CurrentUserProfilesFragment_custSignonStatus_decode,
  custSignonStatus_fromString: CurrentUserProfilesFragment_custSignonStatus_fromString,
  docGender_decode: CurrentUserProfilesFragment_docGender_decode,
  docGender_fromString: CurrentUserProfilesFragment_docGender_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use$3,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function SalesByCategoryPage(props) {
  var getBookableStatuses = props.getBookableStatuses;
  var sessionData = props.sessionData;
  var voyageSlug = props.voyage;
  React.useEffect((function () {
          var watcherId = RescriptReactRouter.watchUrl(function (url) {
                HopperState$Util.Observable.notify(sessionData, (function (d) {
                        return {
                                paxes: d.paxes,
                                bc: d.bc,
                                pax1: d.pax1,
                                paymentOption: d.paymentOption,
                                sessionSaveIsMutating: d.sessionSaveIsMutating,
                                signup: d.signup,
                                url: url,
                                paymentSuggestions: d.paymentSuggestions,
                                quote: d.quote,
                                referral: d.referral,
                                referralSuccess: d.referralSuccess,
                                referralError: d.referralError
                              };
                      }));
              });
          return (function () {
                    RescriptReactRouter.unwatchUrl(watcherId);
                  });
        }), []);
  var match = HopperState$Util.Observable.useComputed(sessionData, (function (model) {
          var urlState = SFState$Thin.URLState.parse(model.url.search);
          return [
                  urlState,
                  Belt_List.reduce(model.url.path, "", (function (a, b) {
                          return a + "/" + b.trim();
                        })),
                  urlState.navTarget,
                  SFState$Thin.ByCategory.validNavStates(model),
                  urlState.bookingSuccess,
                  model.quote
                ];
        }), undefined, undefined);
  var quote = match[5];
  var bookingSuccess = match[4];
  var validNavStates = match[3];
  var currentTarget = match[2];
  var url = match[1];
  var urlState = match[0];
  var match$1 = React.useState(function () {
        return "Total price";
      });
  var setPricingMode = match$1[1];
  var pricingMode = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setPageLevelError = match$2[1];
  var pageLevelError = match$2[0];
  var match$3 = urlState.cabinTargetingByCategory;
  var f = match$3[4];
  var c = match$3[3];
  var occ = match$3[0];
  var cabinHold = urlState.cabinHold;
  var match$4 = React.useState(function () {
        return false;
      });
  var setCabinHoldExpired = match$4[1];
  var canCheckout = Belt_Set.has(SFState$Thin.ByCategory.validNavTargets(validNavStates), "Checkout");
  var hasCabinHold = cabinHold !== undefined ? !DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(cabinHold))) : false;
  React.useEffect((function () {
          var interval = setInterval((function () {
                  if (cabinHold !== undefined && DateFns$Util.hasExpired(CS_Make$Util.Dateable.Plain.toDate(Caml_option.valFromOption(cabinHold))) && bookingSuccess !== true) {
                    return setCabinHoldExpired(function (param) {
                                return true;
                              });
                  }
                  
                }), 1000);
          return (function () {
                    clearInterval(interval);
                  });
        }), [
        Belt_Option.getWithDefault(Belt_Option.map(cabinHold, CS_Make$Util.Dateable.Plain.toString), "nonce"),
        Belt_Option.getWithDefault(bookingSuccess, false)
      ]);
  React.useEffect((function () {
          var eligibleTargets = SFState$Thin.ByCategory.validNavTargets(validNavStates);
          if (!Belt_Set.has(eligibleTargets, currentTarget) && true) {
            var readyForTarget = SFState$Thin.ByCategory.getNavTargetFromState(SFState$Thin.ByCategory.readyForNavState(validNavStates));
            SFState$Thin.goTo(urlState, readyForTarget, url);
          }
          
        }), []);
  React.useEffect((function () {
          ((window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })));
        }), [currentTarget]);
  var match$5 = React.useState(function () {
        
      });
  var setError = match$5[1];
  var match$6 = React.useState(function () {
        
      });
  var setVoyageData = match$6[1];
  var voyageData = match$6[0];
  var match$7 = React.useState(function () {
        
      });
  var setSalesData = match$7[1];
  var salesData = match$7[0];
  var userConnectedAndSessionData = use$2({
        voySlugString: CS_Slugs$Util.VoyageSlug.toString(voyageSlug)
      }, undefined, undefined, undefined);
  var match$8 = useRefetchable(userConnectedAndSessionData.fragmentRefs);
  var refetch = match$8[1];
  var currentUserData = match$8[0];
  React.useEffect((function () {
          SFState$Thin.updatePaxesFromCurrentBookingSession(sessionData, currentUserData);
        }), [Belt_Option.getWithDefault(Belt_Option.flatMap(currentUserData.cubs, (function (nodes) {
                    return JSON.stringify(nodes);
                  })), "")]);
  var refetchCurrentUserInfo = function () {
    return refetch({
                voySlugString: CS_Slugs$Util.VoyageSlug.toString(voyageSlug)
              }, "store-and-network", undefined);
  };
  var refetchOnSave = function (onComplete) {
    return refetch({
                voySlugString: CS_Slugs$Util.VoyageSlug.toString(voyageSlug)
              }, "store-and-network", onComplete);
  };
  var match$9 = SaveBookingSession$Thin.useQuery(occ, f, voyageSlug, setPageLevelError, sessionData);
  var save = match$9.save;
  var useSessionSaveHandler = function (view, setLocalError, onLocalSuccess, setLocalLoading) {
    var input = HopperState$Util.Observable.useComputed(view, (function (model) {
            return SFState$Thin.validateSaveBookingSessionInput(model, occ, voyageSlug);
          }), undefined, undefined);
    if (input.TAG !== "Ok") {
      return {
              TAG: "Error",
              _0: undefined
            };
    }
    var input$1 = input._0;
    return {
            TAG: "Ok",
            _0: (function () {
                save(input$1, setLocalError, setLocalLoading, onLocalSuccess, refetchOnSave, currentUserData);
              })
          };
  };
  React.useEffect((function () {
          $$fetch(RelayEnv$Thin.environment, {
                occupancy: occ,
                voyage: voyageSlug
              }, (function (res) {
                  if (res.TAG === "Ok") {
                    var data = res._0;
                    return setSalesData(function (param) {
                                return data;
                              });
                  }
                  console.error(res._0);
                  setError(function (param) {
                        return "An error occurred fetching cabin data";
                      });
                }), undefined, undefined);
        }), [occ]);
  React.useEffect((function () {
          if (!(Caml_obj.equal(urlState, SFState$Thin.empty) || quote === "Unavailable" || quote === "Loading")) {
            return ;
          }
          var navTarget = Caml_obj.equal(urlState, SFState$Thin.empty) ? "EnterPassengerDetails" : SFState$Thin.ByCategory.getNavTargetFromState(SFState$Thin.ByCategory.readyForNavState(validNavStates));
          CurrentUserCabinHolds$Thin.fetchAndRedirect(voyageSlug, "ByCategory", url, sessionData, navTarget);
        }), []);
  React.useEffect((function () {
          $$fetch$1(RelayEnv$Thin.environment, {
                voyString: CS_Slugs$Util.VoyageSlug.toString(voyageSlug)
              }, (function (res) {
                  if (res.TAG === "Ok") {
                    var data = res._0;
                    return setVoyageData(function (param) {
                                return data;
                              });
                  }
                  console.error(res._0);
                  setError(function (param) {
                        return "An error occurred fetching voyage data";
                      });
                }), undefined, undefined);
        }), []);
  var classes = SalesCabin$Thin.useWrangleClasses(Belt_Option.flatMap(salesData, (function (param) {
              return param.salesQuotesAndAvailability;
            })));
  var sc = salesData !== undefined ? SalesCabin$Thin.wrangleSubclass(occ, match$3[1], match$3[2], salesData.salesQuotesAndAvailability, salesData.salesFareclass) : undefined;
  var category;
  if (sc !== undefined) {
    var c$1 = Belt_Array.get(Belt_Array.keep(sc.categories, (function (c$2) {
                return Caml_option.some(c$2.slug) === c;
              })), 0);
    category = c$1 !== undefined ? c$1 : undefined;
  } else {
    category = undefined;
  }
  var voyage = voyageData !== undefined ? SalesCabin$Thin.wrangleVoyage(voyageData.brandFamilyVoyages) : undefined;
  var pax1MustBeBornBy;
  if (voyageData !== undefined) {
    var match$10 = voyageData.brandFamilyVoyages;
    if (match$10 !== undefined) {
      var match$11 = match$10.nodes;
      if (match$11.length !== 1) {
        pax1MustBeBornBy = undefined;
      } else {
        var match$12 = match$11[0];
        pax1MustBeBornBy = Caml_option.some(match$12.pax1MustBeBornBy);
      }
    } else {
      pax1MustBeBornBy = undefined;
    }
  } else {
    pax1MustBeBornBy = undefined;
  }
  var cabinTargetingCriteria = [
    "ClassAndSubclassSelection",
    "CategorySelection"
  ];
  var tmp;
  switch (currentTarget) {
    case "ClassAndSubclassSelection" :
        tmp = JsxRuntime.jsx(ClassAndSubclassSelection$Thin.make, {
              flow: "ByCategory",
              url: url,
              urlState: urlState,
              occupancy: occ,
              classes: classes,
              voyage: voyage,
              hasCabinHold: hasCabinHold,
              sessionData: sessionData,
              pricingMode: pricingMode,
              setPricingMode: setPricingMode
            });
        break;
    case "CategorySelection" :
        tmp = JsxRuntime.jsx(CategorySelection$Thin.make, {
              flow: "ByCategory",
              sc: sc,
              occupancy: occ,
              url: url,
              urlState: urlState,
              voyage: voyage,
              voyageSlug: voyageSlug,
              refetchCurrentUserInfo: refetchCurrentUserInfo,
              sessionData: sessionData,
              pricingMode: pricingMode,
              setPricingMode: setPricingMode,
              getBookableStatuses: getBookableStatuses
            });
        break;
    case "EnterPassengerDetails" :
        tmp = JsxRuntime.jsx(PaxDetails$Thin.make, {
              currentUserInfo: Belt_Option.flatMap(currentUserData.currentUserProfiles, (function (param) {
                      return Belt_Array.get(param.nodes, 0);
                    })),
              refetchCurrentUserInfo: refetchCurrentUserInfo,
              currentUserConnectedInfo: Belt_Option.map(currentUserData.currentUserConnectedAccounts, (function (param) {
                      return param.nodes;
                    })),
              url: url,
              occupancy: occ,
              voyage: voyage,
              sessionData: sessionData,
              category: category,
              urlState: urlState,
              canCheckout: canCheckout,
              voyageSlug: voyageSlug,
              quote: quote,
              pax1MustBeBornBy: pax1MustBeBornBy,
              setPageLevelError: setPageLevelError,
              flow: "ByCategory",
              fareClass: f,
              useSessionSaveHandler: useSessionSaveHandler,
              sessionSaveLoading: match$9.isMutating,
              countryFrag: userConnectedAndSessionData.fragmentRefs,
              getBookableStatuses: getBookableStatuses
            });
        break;
    case "Checkout" :
        tmp = JsxRuntime.jsx(Checkout$Thin.make, {
              url: url,
              occupancy: occ,
              voyage: voyage,
              sessionData: sessionData,
              setPageLevelError: setPageLevelError,
              category: category,
              urlState: urlState,
              quote: quote,
              voyageSlug: voyageSlug,
              canCheckout: canCheckout,
              fareClass: f,
              useSessionSaveHandler: useSessionSaveHandler
            });
        break;
    default:
      tmp = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      sx: {
                        height: "100%"
                      },
                      children: [
                        JsxRuntime.jsx(SalesNav$Thin.make, {
                              validNavStates: validNavStates,
                              flow: "ByCategory",
                              showSteppers: true,
                              cabinTargetingCriteria: cabinTargetingCriteria,
                              urlState: urlState,
                              url: url
                            }),
                        tmp,
                        JsxRuntime.jsxs(Dialog$Thin.make, {
                              open_: match$4[0],
                              title: "Cabin Hold Expired",
                              onClose: (function () {
                                  setCabinHoldExpired(function (param) {
                                        return false;
                                      });
                                  RescriptReactRouter.push(url);
                                }),
                              children: [
                                JsxRuntime.jsx(DialogContent, {
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                color: "danger",
                                                level: "body-md",
                                                fontWeight: "400",
                                                children: "Your cabin hold has expired! Please select a new cabin."
                                              })),
                                      sx: {
                                        p: 2
                                      }
                                    }),
                                JsxRuntime.jsx(DialogActions, {
                                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                direction: {
                                                  xs: "column",
                                                  sm: "row"
                                                },
                                                component: "form",
                                                justifyContent: "flex-end",
                                                spacing: 1,
                                                sx: {
                                                  width: "100%"
                                                },
                                                onSubmit: (function (e) {
                                                    e.preventDefault();
                                                    setCabinHoldExpired(function (param) {
                                                          return false;
                                                        });
                                                    RescriptReactRouter.push(url);
                                                  }),
                                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                          type: "submit",
                                                          onClick: (function (param) {
                                                              setCabinHoldExpired(function (param) {
                                                                    return false;
                                                                  });
                                                              RescriptReactRouter.push(url);
                                                            }),
                                                          color: "primary",
                                                          children: "Return to start"
                                                        }))
                                              }))
                                    })
                              ],
                              noGap: true,
                              zIndex: 1301
                            })
                      ]
                    }),
                JsxRuntime.jsx(Dialog$Thin.make, {
                      open_: Belt_Option.isSome(pageLevelError),
                      title: "Error",
                      onClose: (function () {
                          setPageLevelError(function (param) {
                                
                              });
                        }),
                      children: Caml_option.some(JsxRuntime.jsx(DialogContent, {
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          direction: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          spacing: 1,
                                          children: [
                                            JsxRuntime.jsx(Stack, {
                                                  sx: {
                                                    width: "62px",
                                                    height: "62px"
                                                  },
                                                  children: Caml_option.some(JsxRuntime.jsx(InfoOutlined, {
                                                            color: "danger",
                                                            sx: {
                                                              width: "100%",
                                                              height: "100%"
                                                            }
                                                          }))
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  color: "danger",
                                                  level: "body-md",
                                                  children: Caml_option.some(Belt_Option.getWithDefault(pageLevelError, "An error occurred. Please try again."))
                                                })
                                          ]
                                        })),
                                sx: {
                                  pb: 4
                                }
                              }))
                    })
              ]
            });
}

var devMode = false;

var make = SalesByCategoryPage;

exports.Query = Query;
exports.VoyageQuery = VoyageQuery;
exports.UserAndConnectedQuery = UserAndConnectedQuery;
exports.CurrentUserProfilesFragment = CurrentUserProfilesFragment;
exports.devMode = devMode;
exports.make = make;
/* use Not a pure module */
