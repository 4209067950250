// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var HopperURL$Thin = require("../context/HopperURL.bs.js");
var CurrentUser$Thin = require("../context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var ClaimPrefilledInvitationPage$Thin = require("../pages/ClaimPrefilledInvitationPage.bs.js");
var ClaimPrefilledInvitationModel$Thin = require("../utils/accounts/ClaimPrefilledInvitationModel.bs.js");
var ClaimPrefilledInvitationRouteQuery_graphql$Thin = require("../__generated__/ClaimPrefilledInvitationRouteQuery_graphql.bs.js");

var convertVariables = ClaimPrefilledInvitationRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = ClaimPrefilledInvitationRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ClaimPrefilledInvitationRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ClaimPrefilledInvitationRouteQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ClaimPrefilledInvitationRouteQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ClaimPrefilledInvitationRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ClaimPrefilledInvitationRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ClaimPrefilledInvitationRouteQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ClaimPrefilledInvitationRouteQuery_graphql$Thin.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ClaimPrefilledInvitationRoute(props) {
  var allegedToken = props.nav;
  var match = use({
        allegedToken: allegedToken
      }, undefined, undefined, undefined);
  var invitation = ClaimPrefilledInvitationModel$Thin.Query.Deref.use(match.fragmentRefs).derefClaimPrefilledAccountInvitation;
  var match$1 = CurrentUser$Thin.Context.use();
  var view = HopperState$Util.Observable.useStore((function () {
          return ClaimPrefilledInvitationModel$Thin.blankModel;
        }), undefined);
  var isSuccess = HopperState$Util.Observable.useComputed(view, (function (param) {
          var tmp = param.claimProgress;
          if (typeof tmp !== "object" || tmp.TAG !== "Success") {
            return false;
          } else {
            return true;
          }
        }), undefined, undefined);
  React.useEffect(function () {
        if (isSuccess) {
          HopperURL$Thin.redirectToPath(SysCtx$Thin.vars.accountLink, (function (urlState) {
                  return {
                          dismissibleAlerts: ["PleaseVerifyYourPrefilledAccount"],
                          loginRedirect: urlState.loginRedirect,
                          token: urlState.token,
                          landing: urlState.landing,
                          expiryMillis: urlState.expiryMillis
                        };
                }), undefined);
        }
        
      });
  var tmp;
  var exit = 0;
  var tmp$1 = match$1.signOnStatus;
  if ((tmp$1 === "VERIFY_EMAIL" || tmp$1 === "INVITED" || tmp$1 === "PENDING_2FA" || tmp$1 === "CLEAR_JWT" || tmp$1 === "ANONYMOUS" || tmp$1 === "SIGNED_ON") && tmp$1 === "ANONYMOUS" && invitation !== undefined) {
    tmp = JsxRuntime.jsx(ClaimPrefilledInvitationPage$Thin.ClaimAnonymously.make, {
          view: view,
          prefill: invitation,
          allegedToken: allegedToken
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = invitation !== undefined ? JsxRuntime.jsx(ClaimPrefilledInvitationPage$Thin.MustBeLoggedOut.make, {
            allegedToken: allegedToken
          }) : JsxRuntime.jsx(ClaimPrefilledInvitationPage$Thin.InvitationNotFound.make, {
            allegedToken: allegedToken
          });
  }
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsx(StandardLayout$Thin.make, {
                        dash: false,
                        children: tmp
                      }))
            });
}

var $$URL;

var make = ClaimPrefilledInvitationRoute;

exports.$$URL = $$URL;
exports.Query = Query;
exports.make = make;
/* use Not a pure module */
