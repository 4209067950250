// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../utils/Common.bs.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Card = require("@mui/joy/Card").default;
var Menu = require("@mui/joy/Menu").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var AppConfig$Thin = require("../../context/AppConfig.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var BookingDiff$Thin = require("../../utils/booking/BookingDiff.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var AddPassDrawer$Thin = require("./add-passenger/AddPassDrawer.bs.js");
var CheckValidity$Thin = require("./drafts/CheckValidity.bs.js");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var BDContentBlock$Thin = require("./molecules/BDContentBlock.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ButtonGroup = require("@mui/joy/ButtonGroup").default;
var BDPatchPaxDrawer$Thin = require("./BDPatchPaxDrawer.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var Add = require("@mui/icons-material/Add").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CopyableWithTooltip$Thin = require("../common/atoms/CopyableWithTooltip.bs.js");
var ResendPaxInvitation$Thin = require("./drafts/mutations/ResendPaxInvitation.bs.js");
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var RemoveSelfOrConnected$Thin = require("./RemoveSelfOrConnected.bs.js");
var Warning = require("@mui/icons-material/Warning").default;
var ModeEdit = require("@mui/icons-material/ModeEdit").default;
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var BedOutlined = require("@mui/icons-material/BedOutlined").default;
var ErrorOutline = require("@mui/icons-material/ErrorOutline").default;
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var ArrowDropDown = require("@mui/icons-material/ArrowDropDown").default;
var ResendInvitationConfirmModal$Thin = require("./drafts/ResendInvitationConfirmModal.bs.js");
var DoNotDisturbAlt = require("@mui/icons-material/DoNotDisturbAlt").default;
var BDPassengerList_detail_graphql$Thin = require("../../__generated__/BDPassengerList_detail_graphql.bs.js");
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var KeyboardArrowDown = require("@mui/icons-material/KeyboardArrowDown").default;
var PersonAddOutlined = require("@mui/icons-material/PersonAddOutlined").default;
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;
var PersonRemoveOutlined = require("@mui/icons-material/PersonRemoveOutlined").default;
var ScheduleSendOutlined = require("@mui/icons-material/ScheduleSendOutlined").default;
var SendAndArchiveOutlined = require("@mui/icons-material/SendAndArchiveOutlined").default;
var BDPassengerList_addberthdetail_graphql$Thin = require("../../__generated__/BDPassengerList_addberthdetail_graphql.bs.js");
var PersonAddDisabledOutlined = require("@mui/icons-material/PersonAddDisabledOutlined").default;

var convertFragment = BDPassengerList_detail_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPassengerList_detail_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPassengerList_detail_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BDPassengerList_detail_graphql$Thin.node, convertFragment, fRef);
}

var DetailFragment_docGender_decode = BDPassengerList_detail_graphql$Thin.Utils.docGender_decode;

var DetailFragment_docGender_fromString = BDPassengerList_detail_graphql$Thin.Utils.docGender_fromString;

var DetailFragment_fareClassPaxManagement_decode = BDPassengerList_detail_graphql$Thin.Utils.fareClassPaxManagement_decode;

var DetailFragment_fareClassPaxManagement_fromString = BDPassengerList_detail_graphql$Thin.Utils.fareClassPaxManagement_fromString;

var DetailFragment_paxDataStatus_decode = BDPassengerList_detail_graphql$Thin.Utils.paxDataStatus_decode;

var DetailFragment_paxDataStatus_fromString = BDPassengerList_detail_graphql$Thin.Utils.paxDataStatus_fromString;

var DetailFragment = {
  docGender_decode: DetailFragment_docGender_decode,
  docGender_fromString: DetailFragment_docGender_fromString,
  fareClassPaxManagement_decode: DetailFragment_fareClassPaxManagement_decode,
  fareClassPaxManagement_fromString: DetailFragment_fareClassPaxManagement_fromString,
  paxDataStatus_decode: DetailFragment_paxDataStatus_decode,
  paxDataStatus_fromString: DetailFragment_paxDataStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function BDPassengerList$PassengerMenu$SplitButton(props) {
  var menu = props.menu;
  if (menu === undefined) {
    return null;
  }
  if (menu.length === 1) {
    var match = Belt_Array.getExn(menu, 0);
    var label = match.label;
    return JsxRuntime.jsx(Button, {
                onClick: match.onClick,
                startDecorator: Caml_option.some(match.icon),
                "aria-label": label,
                disabled: match.disabled,
                children: Caml_option.some(label)
              });
  }
  if (menu.length <= 1) {
    return null;
  }
  var first = Belt_Array.getExn(menu, 0);
  var rest = Belt_Array.sliceToEnd(menu, 1);
  return JsxRuntime.jsxs(Dropdown, {
              children: [
                JsxRuntime.jsxs(ButtonGroup, {
                      sx: {
                        backgroundColor: "white"
                      },
                      children: [
                        JsxRuntime.jsx(Button, {
                              onClick: first.onClick,
                              startDecorator: Caml_option.some(first.icon),
                              "aria-label": first.label,
                              disabled: first.disabled,
                              children: Caml_option.some(first.label)
                            }),
                        JsxRuntime.jsx(MenuButton, {
                              children: Caml_option.some(JsxRuntime.jsx(ArrowDropDown, {})),
                              size: "md",
                              slotProps: {
                                root: {
                                  color: "neutral",
                                  variant: "outlined"
                                }
                              },
                              slots: {
                                root: IconButton
                              }
                            })
                      ],
                      "aria-label": "outlined primary button group"
                    }),
                JsxRuntime.jsx(Menu, {
                      children: Caml_option.some(Belt_Array.mapWithIndex(rest, (function (i, param) {
                                  return JsxRuntime.jsxs(MenuItem, {
                                              children: [
                                                JsxRuntime.jsx(ListItemDecorator, {
                                                      children: Caml_option.some(param.icon)
                                                    }),
                                                param.label
                                              ],
                                              disabled: param.disabled,
                                              onClick: param.onClick
                                            }, String(i));
                                }))),
                      placement: "bottom-end",
                      size: "sm",
                      sx: {
                        zIndex: "modal"
                      }
                    })
              ]
            });
}

var SplitButton = {
  make: BDPassengerList$PassengerMenu$SplitButton
};

function BDPassengerList$PassengerMenu(props) {
  var isLastTableRow = props.isLastTableRow;
  var fareClassManagePaxBlocked = props.fareClassManagePaxBlocked;
  var bookingVersionNumber = props.bookingVersionNumber;
  var bookingId = props.bookingId;
  var voyageSlug = props.voyageSlug;
  var refetchBookingDetails = props.refetchBookingDetails;
  var isCurrentUserConnected = props.isCurrentUserConnected;
  var accountId = props.accountId;
  var getPreviewFromDiff = props.getPreviewFromDiff;
  var idx = props.idx;
  var isBc = props.isBc;
  var currentUserEmail = props.currentUserEmail;
  var pax = props.pax;
  var match = React.useState(function () {
        return false;
      });
  var setPatchPaxDrawerOpen = match[1];
  var match$1 = Belt_Option.map(pax.email, CS_Emails$Util.Email.toPrimitive);
  var isCurrentUser = currentUserEmail !== undefined && match$1 !== undefined ? currentUserEmail === match$1 : false;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match$2 = CheckValidity$Thin.useQuery(getPreviewFromDiff, url, undefined);
  var addOrErr = match$2.addOrErr;
  var setError = match$2.setError;
  var match$3 = React.useState(function () {
        return false;
      });
  var setResendConfirmationOpen = match$3[1];
  var match$4 = RemoveSelfOrConnected$Thin.useQuery();
  var previewRemove = match$4.previewRemove;
  var setPaxRemoveModalOpen = match$4.setPaxRemoveModalOpen;
  var setFee = match$4.setFee;
  var setQueryError = match$4.setError;
  var isManagedAccount = isCurrentUser || isCurrentUserConnected;
  var isEditing = props.draftMode && isBc;
  var isPrimaryPax = pax.idx === 1;
  var url$1 = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url$1.search);
  var onDetachPax = function (e) {
    e.preventDefault();
    var applyDiff = BookingDiff$Thin.Alter.detachSingleBerth(diff, idx);
    addOrErr(applyDiff);
  };
  var onDropBerth = function (e) {
    e.preventDefault();
    var applyDiff = BookingDiff$Thin.Alter.dropSingleBerth(diff, idx);
    addOrErr(applyDiff);
  };
  var onRevokeInvitation = function (e) {
    e.preventDefault();
    var match = pax.paxStatus;
    if (typeof match !== "object") {
      console.log("Invalid status or accountId");
      return ;
    }
    if (match.TAG === "Status") {
      var tmp = match._0;
      if (tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") {
        if (tmp === "INVITED") {
          if (accountId !== undefined) {
            var diff$1 = BookingDiff$Thin.Alter.revokePaxInvitation(diff, idx, Caml_option.valFromOption(accountId));
            return addOrErr(diff$1);
          }
          console.log("Invalid status or accountId");
          return ;
        }
        console.log("Invalid status or accountId");
        return ;
      } else {
        console.log("Invalid status or accountId");
        return ;
      }
    } else {
      console.log("Invalid status or accountId");
      return ;
    }
  };
  var match$5 = React.useState(function () {
        return "Attach";
      });
  var setDraftAction = match$5[1];
  var draftAction = match$5[0];
  var match$6 = React.useState(function () {
        return false;
      });
  var setPaxDetailsOpen = match$6[1];
  var url$2 = RescriptReactRouter.useUrl(undefined, undefined);
  var diff$1 = BookingDiff$Thin.parse(url$2.search);
  React.useEffect((function () {
          if (isLastTableRow && pax.paxStatus === "TBA") {
            var match = diff$1.autoAdd;
            var exit = 0;
            if (match !== undefined && match) {
              setPaxDetailsOpen(function (param) {
                    return true;
                  });
            } else {
              exit = 1;
            }
            if (exit === 1) {
              setPaxDetailsOpen(function (param) {
                    return false;
                  });
            }
            
          }
          
        }), [diff$1.autoAdd]);
  var addPassenger = function (a) {
    setDraftAction(function (param) {
          return a;
        });
    setPaxDetailsOpen(function (param) {
          return true;
        });
  };
  var match$7 = ResendPaxInvitation$Thin.useMutation(setError, (function () {
          setResendConfirmationOpen(function (param) {
                return true;
              });
          refetchBookingDetails();
        }));
  var resendInvitation = match$7.resendInvitation;
  var onResendInvitation = function (e) {
    e.preventDefault();
    var match = pax.paxStatus;
    if (typeof match !== "object") {
      console.log("Invalid status or accountId");
      return ;
    }
    if (match.TAG === "Status") {
      var tmp = match._0;
      if (tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") {
        if (tmp === "INVITED") {
          if (accountId !== undefined) {
            return resendInvitation(Caml_option.valFromOption(accountId));
          } else {
            console.log("Invalid status or accountId");
            return ;
          }
        }
        console.log("Invalid status or accountId");
        return ;
      } else {
        console.log("Invalid status or accountId");
        return ;
      }
    } else {
      console.log("Invalid status or accountId");
      return ;
    }
  };
  var shouldDisplayMenu = props.isActive && !props.paxManagementLocked && (isManagedAccount || isEditing);
  var menu;
  if (shouldDisplayMenu) {
    var match$8 = isManagedAccount && !isEditing && !isPrimaryPax && fareClassManagePaxBlocked === "ENABLE_ALL";
    var match$9 = RemoveSelfOrConnected$Thin.getPreviewVars(pax, bookingId, bookingVersionNumber);
    var items = Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(Belt_Array.concat([], isEditing ? (
                        Caml_obj.equal(pax.paxStatus, {
                              TAG: "Status",
                              _0: "INVITED"
                            }) ? Belt_Array.concat([{
                                  label: Txt$Thin.BookingDetails.resendInvitation.value,
                                  icon: JsxRuntime.jsx(ScheduleSendOutlined, {}),
                                  onClick: onResendInvitation,
                                  disabled: false
                                }], fareClassManagePaxBlocked !== "PATCH_ONLY" ? [{
                                    label: Txt$Thin.BookingDetails.revokeInvitation.value,
                                    icon: JsxRuntime.jsx(PersonAddDisabledOutlined, {}),
                                    onClick: onRevokeInvitation,
                                    disabled: false
                                  }] : []) : (
                            pax.paxStatus === "TBA" && fareClassManagePaxBlocked !== "PATCH_ONLY" ? [{
                                  label: Txt$Thin.BookingDetails.addPassAddPassenger.value,
                                  icon: JsxRuntime.jsx(PersonAddOutlined, {}),
                                  onClick: (function (param) {
                                      addPassenger("Attach");
                                    }),
                                  disabled: false
                                }] : []
                          )
                      ) : []), !isEditing && isManagedAccount || isEditing && pax.paxStatus !== "TBA" && Caml_obj.notequal(pax.paxStatus, {
                      TAG: "Status",
                      _0: "INVITED"
                    }) && pax.paxPatchPerm !== "None" ? [{
                      label: Txt$Thin.BookingDetails.editDetails.value,
                      icon: JsxRuntime.jsx(ModeEdit, {}),
                      onClick: (function (param) {
                          setPatchPaxDrawerOpen(function (param) {
                                return true;
                              });
                        }),
                      disabled: false
                    }] : []), match$8 ? (
                match$9 !== undefined ? [{
                      label: isCurrentUser ? Txt$Thin.BookingDetails.removeMyself.value : "Remove Passenger",
                      icon: JsxRuntime.jsx(DoNotDisturbAlt, {
                            sx: {
                              color: "danger.500"
                            }
                          }),
                      onClick: (function (param) {
                          previewRemove(match$9);
                        }),
                      disabled: false
                    }] : []
              ) : []), Caml_obj.notequal(pax.paxStatus, {
              TAG: "Status",
              _0: "INVITED"
            }) && isEditing && fareClassManagePaxBlocked === "ENABLE_ALL" ? (
            pax.paxStatus !== "TBA" ? Belt_Array.concat([{
                      label: Txt$Thin.BookingDetails.replacePassenger.value,
                      icon: JsxRuntime.jsx(PersonRemoveOutlined, {}),
                      onClick: (function (param) {
                          addPassenger("Replace");
                        }),
                      disabled: false
                    }], isPrimaryPax ? [] : [{
                        label: isCurrentUser ? Txt$Thin.BookingDetails.removeMyself.value : Txt$Thin.BookingDetails.detachPassenger.value,
                        icon: JsxRuntime.jsx(DoNotDisturbAlt, {
                              sx: {
                                color: "danger.500"
                              }
                            }),
                        onClick: onDetachPax,
                        disabled: props.cantDetach
                      }]) : (
                isPrimaryPax ? [] : [{
                      label: Txt$Thin.BookingDetails.dropBerth.value,
                      icon: JsxRuntime.jsx(BedOutlined, {}),
                      onClick: onDropBerth,
                      disabled: props.cantDrop
                    }]
              )
          ) : []);
    menu = items.length === 0 ? undefined : items;
  } else {
    menu = undefined;
  }
  if (menu === undefined) {
    return null;
  }
  var tmp;
  tmp = draftAction === "Attach" ? Txt$Thin.BookingDetails.addPassDrawerTitle.value : Txt$Thin.BookingDetails.replacePassDrawerTitle.value;
  var match$10 = pax.paxPatchPerm;
  var tmp$1;
  var exit = 0;
  switch (match$10) {
    case "Limited" :
    case "All" :
        exit = 1;
        break;
    case "None" :
        tmp$1 = null;
        break;
    
  }
  if (exit === 1) {
    tmp$1 = JsxRuntime.jsx(BDPatchPaxDrawer$Thin.make, {
          open_: match[0],
          onClose: (function () {
              setPatchPaxDrawerOpen(function (param) {
                    return false;
                  });
            }),
          currentVoyage: voyageSlug,
          isBc: isBc,
          isSelf: isCurrentUser,
          isCurrentUserConnected: isCurrentUserConnected,
          pax: pax,
          getPreviewFromDiff: getPreviewFromDiff,
          refetchBookingDetails: refetchBookingDetails,
          bookingId: bookingId,
          bookingVersionNumber: bookingVersionNumber,
          countryFrag: props.countryFrag
        });
  }
  var applyInput = RemoveSelfOrConnected$Thin.getApplyInput(pax, bookingId, bookingVersionNumber);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      spacing: 1,
                      sx: {
                        display: {
                          xs: "none",
                          lg: "flex"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Dropdown, {
                                children: [
                                  JsxRuntime.jsx(MenuButton, {
                                        children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                                        size: "md",
                                        slotProps: {
                                          root: {
                                            color: "neutral"
                                          }
                                        },
                                        slots: {
                                          root: IconButton
                                        }
                                      }),
                                  JsxRuntime.jsx(Menu, {
                                        children: Caml_option.some(Belt_Array.mapWithIndex(menu, (function (i, param) {
                                                    return JsxRuntime.jsxs(MenuItem, {
                                                                children: [
                                                                  JsxRuntime.jsx(ListItemDecorator, {
                                                                        children: Caml_option.some(param.icon)
                                                                      }),
                                                                  param.label
                                                                ],
                                                                disabled: param.disabled,
                                                                onClick: param.onClick
                                                              }, String(i));
                                                  }))),
                                        placement: "bottom-start"
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      spacing: 1,
                      sx: {
                        display: {
                          xs: "flex",
                          lg: "none"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$PassengerMenu$SplitButton, {
                                menu: menu
                              }))
                    }),
                JsxRuntime.jsx(AddPassDrawer$Thin.make, {
                      open_: match$6[0],
                      title: tmp,
                      onClose: (function () {
                          setPaxDetailsOpen(function (param) {
                                return false;
                              });
                        }),
                      areConnectedAccts: props.areConnectedAccts,
                      action: draftAction,
                      idx: idx,
                      custodianAccountId: props.custodianAccountId,
                      getPreviewFromDiff: getPreviewFromDiff,
                      voyageSlug: voyageSlug,
                      isPrimaryPax: isPrimaryPax,
                      pax1MustBeBornBy: props.pax1MustBeBornBy,
                      showFillInLater: isLastTableRow && pax.paxStatus === "TBA" && Caml_obj.equal(diff$1.autoAdd, true),
                      queryFrag: props.queryFrag
                    }),
                tmp$1,
                JsxRuntime.jsx(CheckValidity$Thin.ErrorModal.make, {
                      error: match$2.error,
                      setError: setError
                    }),
                JsxRuntime.jsx(ResendInvitationConfirmModal$Thin.make, {
                      open_: match$3[0],
                      onClose: (function () {
                          setResendConfirmationOpen(function (param) {
                                return false;
                              });
                        })
                    }),
                applyInput !== undefined ? JsxRuntime.jsx(RemoveSelfOrConnected$Thin.Modal.make, {
                        open_: match$4.paxRemoveModalOpen,
                        onClose: (function () {
                            setFee(function (param) {
                                  
                                });
                            setQueryError(function (param) {
                                  
                                });
                            setPaxRemoveModalOpen(function (param) {
                                  return false;
                                });
                          }),
                        applyInput: applyInput,
                        onSuccess: (function () {
                            refetchBookingDetails();
                          }),
                        fee: match$4.fee,
                        queryError: match$4.error,
                        name: pax.preferredFullName,
                        isSelf: isCurrentUser
                      }) : null
              ]
            });
}

var PassengerMenu = {
  SplitButton: SplitButton,
  make: BDPassengerList$PassengerMenu
};

function BDPassengerList$PassengerCard$Header(props) {
  var pax = props.pax;
  var match = pax.preferredFullName;
  var match$1 = pax.paxStatus;
  var tmp;
  var exit = 0;
  if (match !== undefined) {
    var exit$1 = 0;
    if (typeof match$1 !== "object") {
      exit = 1;
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      tmp = JsxRuntime.jsxs(Stack, {
            direction: "row",
            alignItems: "center",
            sx: {
              mr: 2
            },
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "body-lg",
                    noWrap: true,
                    children: Caml_option.some(match)
                  }),
              JsxRuntime.jsxs(Stack, {
                    direction: "row",
                    children: [
                      props.travelingAs ? JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-md",
                                    children: Caml_option.some(JsxRuntime.jsx("sup", {
                                              children: JsxRuntime.jsx(InfoOutlined, {
                                                    color: "info",
                                                    fontSize: "inherit"
                                                  })
                                            }))
                                  }),
                              arrow: true,
                              placement: "top",
                              size: "sm",
                              sx: {
                                position: "relative",
                                left: 2,
                                justifySelf: "flex-start"
                              },
                              title: Caml_option.some("Traveling as " + props.legalName),
                              onClick: (function (e) {
                                  e.stopPropagation();
                                })
                            }) : null,
                      props.isPrimary ? JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(Typography, {
                                    color: "danger",
                                    level: "body-lg",
                                    children: Caml_option.some(JsxRuntime.jsx("sup", {
                                              children: "*"
                                            }))
                                  }),
                              arrow: true,
                              placement: "top",
                              size: "sm",
                              sx: {
                                position: "relative",
                                left: 4,
                                bottom: 2,
                                justifySelf: "flex-start"
                              },
                              title: "Primary Passenger",
                              onClick: (function (e) {
                                  e.stopPropagation();
                                })
                            }) : null
                    ]
                  })
            ]
          });
    }
    
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Typography, {
          level: "body-lg",
          textColor: "primary.solidDisabledColor",
          fontWeight: "inherit",
          children: "TBA"
        });
  }
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              alignItems: "center",
              onClick: props.onClick,
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      sx: {
                        flex: 1
                      },
                      children: [
                        tmp,
                        Common$Thin.PaxDataStatus.makeChip(undefined, pax.paxStatus)
                      ]
                    }),
                JsxRuntime.jsx(KeyboardArrowDown, {
                      sx: {
                        color: "text.secondary",
                        transform: props.cardOpen ? "rotate(-180deg)" : "rotate(0deg)"
                      }
                    })
              ]
            });
}

var Header = {
  make: BDPassengerList$PassengerCard$Header
};

function BDPassengerList$PassengerCard$PassengerDetail(props) {
  var minWidth = props.minWidth;
  var __copyable = props.copyable;
  var value = props.value;
  var label = props.label;
  var copyable = __copyable !== undefined ? __copyable : false;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              sx: {
                py: 1,
                mr: 4,
                minWidth: minWidth !== undefined ? minWidth : "auto"
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: Caml_option.some(JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                value: label,
                                level: "body-sm",
                                fontWeight: "bold",
                                nonVariantColor: "neutral.500"
                              }))
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                              value: value,
                              level: "body-sm"
                            }),
                        copyable ? JsxRuntime.jsx(CopyableWithTooltip$Thin.make, {
                                value: value,
                                label: label
                              }) : null
                      ]
                    })
              ]
            });
}

var PassengerDetail = {
  make: BDPassengerList$PassengerCard$PassengerDetail
};

function BDPassengerList$PassengerCard(props) {
  var defaultOpen = props.defaultOpen;
  var pax = props.pax;
  var match = React.useState(function () {
        return defaultOpen;
      });
  var setCardOpen = match[1];
  var cardOpen = match[0];
  var match$1 = Belt_Option.map(pax.docFName, CS_NonemptyStrings$Util.NonEmptyString.toString);
  var match$2 = Belt_Option.map(pax.docLName, CS_NonemptyStrings$Util.NonEmptyString.toString);
  var legalName = match$1 !== undefined ? (
      match$2 !== undefined ? match$1 + " " + match$2 : match$1
    ) : (
      match$2 !== undefined ? match$2 : "No name provided"
    );
  var match$3 = AppConfig$Thin.Context.use();
  var brandFamilyName = match$3.brandFamily.brandFamilyName;
  var email = pax.email;
  var match$4 = pax.cruiselineBookingNumber;
  var date = Belt_Option.map(pax.birthdate, (function (v) {
          return DateOnly$Util.format(v, SysCtx$Thin.vars.dateFormat);
        }));
  var tier = pax.loyaltyTier;
  var tmp;
  if (tier !== undefined) {
    var tierText = CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(tier)) + " (" + String(pax.numLoyaltyVoyages) + ")";
    tmp = JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
          label: brandFamilyName + " Status",
          value: tierText,
          minWidth: "200px"
        });
  } else {
    tmp = JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
          label: brandFamilyName + " Status",
          value: "None"
        });
  }
  var num = pax.halMarinerNumber;
  return JsxRuntime.jsxs(Card, {
              children: [
                JsxRuntime.jsx(BDPassengerList$PassengerCard$Header, {
                      pax: pax,
                      travelingAs: props.travelingAs,
                      legalName: legalName,
                      isPrimary: props.isPrimary,
                      cardOpen: cardOpen,
                      onClick: (function (param) {
                          setCardOpen(function (param) {
                                return !cardOpen;
                              });
                        })
                    }),
                JsxRuntime.jsx(Divider, {
                      sx: {
                        display: cardOpen ? "flex" : "none"
                      }
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      spacing: 1,
                      sx: {
                        maxHeight: cardOpen ? 1500 : 0,
                        overflow: "hidden"
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 1,
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        flexWrap: "wrap",
                                        children: [
                                          email !== undefined ? JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
                                                  label: "Email",
                                                  value: CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)),
                                                  copyable: true,
                                                  minWidth: "200px"
                                                }) : null,
                                          props.showClBN ? (
                                              match$4 !== undefined ? JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
                                                      label: "HAL Booking #",
                                                      value: match$4,
                                                      copyable: true
                                                    }) : JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
                                                      label: "HAL Booking #",
                                                      value: "Not assigned"
                                                    })
                                            ) : null,
                                          JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
                                                label: "Traveling as",
                                                value: legalName,
                                                minWidth: "200px"
                                              }),
                                          date !== undefined ? JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
                                                  label: "Birthdate",
                                                  value: date
                                                }) : JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
                                                  label: "Birthdate",
                                                  value: "Not provided"
                                                }),
                                          tmp,
                                          num !== undefined ? JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
                                                  label: "HAL Mariner #",
                                                  value: CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toString(Caml_option.valFromOption(num)),
                                                  copyable: true
                                                }) : JsxRuntime.jsx(BDPassengerList$PassengerCard$PassengerDetail, {
                                                  label: "HAL Mariner #",
                                                  value: "None"
                                                })
                                        ]
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        direction: "row",
                                        justifyContent: "flex-end",
                                        children: Caml_option.some(props.menu)
                                      })
                                ]
                              }))
                    })
              ],
              color: "neutral",
              variant: "soft",
              sx: {
                gap: cardOpen ? "1rem" : "0"
              }
            });
}

var PassengerCard = {
  Header: Header,
  PassengerDetail: PassengerDetail,
  make: BDPassengerList$PassengerCard
};

function BDPassengerList$PassengerInformation(props) {
  var __defaultOpen = props.defaultOpen;
  var showClBN = props.showClBN;
  var __isLastTableRow = props.isLastTableRow;
  var fareClassManagePaxBlocked = props.fareClassManagePaxBlocked;
  var paxManagementLocked = props.paxManagementLocked;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var bookingVersionNumber = props.bookingVersionNumber;
  var bookingId = props.bookingId;
  var voyageSlug = props.voyageSlug;
  var refetchBookingDetails = props.refetchBookingDetails;
  var countryFrag = props.countryFrag;
  var isActive = props.isActive;
  var isBc = props.isBc;
  var draftMode = props.draftMode;
  var cantDrop = props.cantDrop;
  var cantDetach = props.cantDetach;
  var getPreviewFromDiff = props.getPreviewFromDiff;
  var custodianAccountId = props.custodianAccountId;
  var queryFrag = props.queryFrag;
  var areConnectedAccts = props.areConnectedAccts;
  var currentUserEmail = props.currentUserEmail;
  var pax = props.pax;
  var isLastTableRow = __isLastTableRow !== undefined ? __isLastTableRow : false;
  var defaultOpen = __defaultOpen !== undefined ? __defaultOpen : false;
  var paxStatus = pax.paxStatus;
  var match;
  var exit = 0;
  if (typeof paxStatus !== "object") {
    match = [
      Txt$Thin.BookingDetails.plTBA.value,
      "warningSecondary",
      JsxRuntime.jsx(Warning, {
            color: "warningSecondary"
          })
    ];
  } else if (paxStatus.TAG === "Status") {
    var tmp = paxStatus._0;
    if (tmp === "INCOMPLETE" || tmp === "CONFIRMED" || tmp === "INVITED") {
      switch (tmp) {
        case "CONFIRMED" :
            match = [
              Txt$Thin.BookingDetails.plConfirmed.value,
              "success",
              JsxRuntime.jsx(CheckCircleOutline, {
                    color: "success"
                  })
            ];
            break;
        case "INCOMPLETE" :
            exit = 1;
            break;
        case "INVITED" :
            match = [
              Txt$Thin.BookingDetails.plInvited.value,
              "secondary",
              JsxRuntime.jsx(SendAndArchiveOutlined, {
                    color: "secondary"
                  })
            ];
            break;
        
      }
    } else {
      exit = 1;
    }
  } else {
    switch (paxStatus._0) {
      case "Invite" :
          match = [
            Txt$Thin.BookingDetails.plInviteOnApply.value,
            "secondary",
            JsxRuntime.jsx(ModeEditOutlined, {
                  color: "secondary"
                })
          ];
          break;
      case "Replace" :
          match = [
            Txt$Thin.BookingDetails.plReplaceOnApply.value,
            "secondary",
            JsxRuntime.jsx(ModeEditOutlined, {
                  color: "secondary"
                })
          ];
          break;
      case "Attach" :
          match = [
            Txt$Thin.BookingDetails.plAttachOnApply.value,
            "secondary",
            JsxRuntime.jsx(ModeEditOutlined, {
                  color: "secondary"
                })
          ];
          break;
      
    }
  }
  if (exit === 1) {
    match = [
      Txt$Thin.BookingDetails.plUnknown.value,
      "danger",
      JsxRuntime.jsx(ErrorOutline, {
            color: "danger"
          })
    ];
  }
  var match$1 = Belt_Option.map(pax.docFName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
  var match$2 = Belt_Option.map(pax.docLName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
  var match$3 = Belt_Option.map(pax.preferredFName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
  var match$4 = Belt_Option.map(pax.preferredLName, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
  var match$5;
  if (match$1 !== undefined && match$2 !== undefined && match$3 !== undefined && match$4 !== undefined && (match$1 !== match$3 || match$2 !== match$4)) {
    var legalName = match$1 + " " + match$2;
    match$5 = [
      true,
      legalName
    ];
  } else {
    match$5 = [
      false,
      ""
    ];
  }
  var legalName$1 = match$5[1];
  var travelingAs = match$5[0];
  var idx = pax.shouldAttachToIdx;
  var idx$1 = idx !== undefined ? idx : pax.idx;
  var accountId = pax.accountId;
  var isCurrentUserConnected = accountId !== undefined ? Belt_Set.has(props.userConnected, Caml_option.valFromOption(accountId)) : false;
  var match$6 = pax.preferredFullName;
  var tmp$1;
  var exit$1 = 0;
  if (match$6 !== undefined) {
    var exit$2 = 0;
    if (typeof paxStatus !== "object") {
      exit$1 = 1;
    } else {
      exit$2 = 2;
    }
    if (exit$2 === 2) {
      tmp$1 = JsxRuntime.jsxs(Stack, {
            direction: "row",
            alignItems: "center",
            sx: {
              display: "flex",
              width: "100%"
            },
            children: [
              JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                    value: match$6,
                    endDecorator: Caml_option.some(travelingAs ? JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(IconButton, {
                                      sx: {
                                        minHeight: "0",
                                        minWidth: "0",
                                        "&:hover": {
                                          background: "none"
                                        }
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                level: "body-md",
                                                children: Caml_option.some(JsxRuntime.jsx("sup", {
                                                          children: JsxRuntime.jsx(InfoOutlined, {
                                                                color: "info",
                                                                fontSize: "inherit"
                                                              })
                                                        }))
                                              }))
                                    }),
                                arrow: true,
                                placement: "top",
                                size: "sm",
                                sx: {
                                  position: "relative",
                                  left: 2,
                                  justifySelf: "flex-start"
                                },
                                title: Caml_option.some("Traveling as " + legalName$1)
                              }) : null)
                  }),
              JsxRuntime.jsx(Stack, {
                    direction: "row",
                    sx: {
                      flex: 1
                    },
                    children: Caml_option.some(pax.idx === 1 ? JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(IconButton, {
                                      sx: {
                                        minHeight: "0",
                                        minWidth: "0",
                                        "&:hover": {
                                          background: "none"
                                        }
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                color: "danger",
                                                level: "body-lg",
                                                children: Caml_option.some(JsxRuntime.jsx("sup", {
                                                          children: "*"
                                                        }))
                                              }))
                                    }),
                                arrow: true,
                                placement: "top",
                                size: "sm",
                                sx: {
                                  position: "relative",
                                  left: 4,
                                  bottom: 2,
                                  justifySelf: "flex-start"
                                },
                                title: "Primary Passenger"
                              }) : null)
                  })
            ]
          });
    }
    
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    tmp$1 = JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
          value: Txt$Thin.BookingDetails.plName.value,
          nonVariantColor: "primary.solidDisabledColor"
        });
  }
  var tmp$2;
  if (showClBN) {
    var clBN = pax.cruiselineBookingNumber;
    tmp$2 = JsxRuntime.jsx("td", {
          children: clBN !== undefined ? JsxRuntime.jsxs(Stack, {
                  direction: "row",
                  alignItems: "center",
                  spacing: 1,
                  sx: {
                    overflow: "hidden"
                  },
                  children: [
                    JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                          value: clBN
                        }),
                    JsxRuntime.jsx(CopyableWithTooltip$Thin.make, {
                          value: clBN,
                          label: "Holland America Booking Number"
                        })
                  ]
                }) : JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                  value: "Not assigned",
                  nonVariantColor: "primary.solidDisabledColor"
                })
        });
  } else {
    tmp$2 = null;
  }
  var halMarinerNumber = pax.halMarinerNumber;
  var tmp$3;
  if (halMarinerNumber !== undefined) {
    var halMarinerNumber$1 = Caml_option.valFromOption(halMarinerNumber);
    tmp$3 = JsxRuntime.jsxs(Stack, {
          direction: "row",
          alignItems: "center",
          spacing: 1,
          sx: {
            overflow: "hidden"
          },
          children: [
            JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                  value: CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toString(halMarinerNumber$1)
                }),
            JsxRuntime.jsx(CopyableWithTooltip$Thin.make, {
                  value: CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toString(halMarinerNumber$1),
                  label: "HAL Mariner #"
                })
          ]
        });
  } else {
    tmp$3 = JsxRuntime.jsx(Typography, {
          level: "inherit",
          textColor: "primary.solidDisabledColor",
          fontWeight: "inherit",
          children: "None"
        });
  }
  var tier = pax.loyaltyTier;
  var tmp$4;
  if (tier !== undefined) {
    var tierText = CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(tier)) + " (" + String(pax.numLoyaltyVoyages) + ")";
    tmp$4 = JsxRuntime.jsx(Tooltip, {
          children: JsxRuntime.jsx(Typography, {
                level: "inherit",
                noWrap: true,
                sx: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "fit-content"
                },
                textColor: "inherit",
                fontWeight: "inherit",
                children: Caml_option.some(tierText)
              }),
          arrow: true,
          size: "sm",
          sx: {
            width: "fit-content"
          },
          title: Caml_option.some(Belt_Array.joinWith(pax.eligibleVoyageSlugs, ", ", CS_Slugs$Util.VoyageSlug.toString))
        });
  } else {
    tmp$4 = JsxRuntime.jsx(Typography, {
          level: "inherit",
          textColor: "primary.solidDisabledColor",
          fontWeight: "inherit",
          children: "Loyalty Tier"
        });
  }
  var date = Belt_Option.map(pax.birthdate, (function (v) {
          return DateOnly$Util.format(v, SysCtx$Thin.vars.dateFormat);
        }));
  var match$7 = Belt_Option.map(pax.email, CS_Emails$Util.Email.toPrimitive);
  var tmp$5;
  var exit$3 = 0;
  if (match$7 !== undefined && !(typeof paxStatus !== "object" || paxStatus.TAG !== "Status")) {
    tmp$5 = JsxRuntime.jsxs(Stack, {
          direction: "row",
          alignItems: "center",
          spacing: 1,
          sx: {
            overflow: "hidden"
          },
          children: [
            JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                  value: match$7
                }),
            JsxRuntime.jsx(CopyableWithTooltip$Thin.make, {
                  value: match$7,
                  label: "Email"
                })
          ]
        });
  } else {
    exit$3 = 1;
  }
  if (exit$3 === 1) {
    tmp$5 = JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
          value: Txt$Thin.BookingDetails.plEmail.value
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      component: "tr",
                      sx: {
                        display: {
                          xs: "none",
                          lg: "table-row"
                        }
                      },
                      children: [
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(BDPassengerList$PassengerMenu, {
                                    pax: pax,
                                    currentUserEmail: currentUserEmail,
                                    isBc: isBc,
                                    isActive: isActive,
                                    areConnectedAccts: areConnectedAccts,
                                    idx: idx$1,
                                    custodianAccountId: custodianAccountId,
                                    getPreviewFromDiff: getPreviewFromDiff,
                                    cantDetach: cantDetach,
                                    cantDrop: cantDrop,
                                    accountId: pax.accountId,
                                    draftMode: draftMode,
                                    isCurrentUserConnected: isCurrentUserConnected,
                                    queryFrag: queryFrag,
                                    countryFrag: countryFrag,
                                    refetchBookingDetails: refetchBookingDetails,
                                    voyageSlug: voyageSlug,
                                    bookingId: bookingId,
                                    bookingVersionNumber: bookingVersionNumber,
                                    pax1MustBeBornBy: pax1MustBeBornBy,
                                    paxManagementLocked: paxManagementLocked,
                                    fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                                    isLastTableRow: isLastTableRow
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsxs(Stack, {
                                    direction: "row",
                                    alignItems: "center",
                                    spacing: 1,
                                    children: [
                                      match[2],
                                      JsxRuntime.jsx(Typography, {
                                            color: match[1],
                                            level: "inherit",
                                            fontWeight: "inherit",
                                            children: Caml_option.some(match[0])
                                          })
                                    ]
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: tmp$1
                            }),
                        tmp$2,
                        JsxRuntime.jsx("td", {
                              children: tmp$3
                            }),
                        JsxRuntime.jsx("td", {
                              children: tmp$4
                            }),
                        JsxRuntime.jsx("td", {
                              children: date !== undefined ? JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                      value: date
                                    }) : JsxRuntime.jsx(Joy$Util.TextOverflow.make, {
                                      value: Txt$Thin.BookingDetails.plBirthdate.value
                                    })
                            }),
                        JsxRuntime.jsx("td", {
                              children: tmp$5
                            })
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      spacing: 1,
                      sx: {
                        display: {
                          xs: "flex",
                          lg: "none"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$PassengerCard, {
                                pax: pax,
                                travelingAs: travelingAs,
                                legalName: legalName$1,
                                isPrimary: pax.idx === 1,
                                showClBN: showClBN,
                                menu: JsxRuntime.jsx(BDPassengerList$PassengerMenu, {
                                      pax: pax,
                                      currentUserEmail: currentUserEmail,
                                      isBc: isBc,
                                      isActive: isActive,
                                      areConnectedAccts: areConnectedAccts,
                                      idx: idx$1,
                                      custodianAccountId: custodianAccountId,
                                      getPreviewFromDiff: getPreviewFromDiff,
                                      cantDetach: cantDetach,
                                      cantDrop: cantDrop,
                                      accountId: pax.accountId,
                                      draftMode: draftMode,
                                      isCurrentUserConnected: isCurrentUserConnected,
                                      queryFrag: queryFrag,
                                      countryFrag: countryFrag,
                                      refetchBookingDetails: refetchBookingDetails,
                                      voyageSlug: voyageSlug,
                                      bookingId: bookingId,
                                      bookingVersionNumber: bookingVersionNumber,
                                      pax1MustBeBornBy: pax1MustBeBornBy,
                                      paxManagementLocked: paxManagementLocked,
                                      fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                                      isLastTableRow: isLastTableRow
                                    }),
                                defaultOpen: defaultOpen
                              }))
                    })
              ]
            });
}

var PassengerInformation = {
  make: BDPassengerList$PassengerInformation
};

var convertFragment$1 = BDPassengerList_addberthdetail_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPassengerList_addberthdetail_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPassengerList_addberthdetail_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(BDPassengerList_addberthdetail_graphql$Thin.node, convertFragment$1, fRef);
}

var AddBerthFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function BDPassengerList$AddBerth(props) {
  use$1(props.fragmentRefs);
  var match = CheckValidity$Thin.useQuery(props.getPreviewFromDiff, props.url, undefined);
  var tmp;
  var exit = 0;
  if (props.paxManagementLocked) {
    tmp = JsxRuntime.jsx(JsxRuntime.Fragment, {});
  } else {
    var tmp$1 = props.fareClassManagePaxBlocked;
    if (tmp$1 === "TBA_AND_PATCH_ONLY" || tmp$1 === "ENABLE_ALL" || tmp$1 === "PATCH_ONLY") {
      switch (tmp$1) {
        case "ENABLE_ALL" :
            exit = 1;
            break;
        case "TBA_AND_PATCH_ONLY" :
        case "PATCH_ONLY" :
            tmp = JsxRuntime.jsx(JsxRuntime.Fragment, {});
            break;
        
      }
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button, {
                            loading: match.loading,
                            onClick: (function () {
                                
                              }),
                            endDecorator: Caml_option.some(JsxRuntime.jsx(Add, {
                                      fontSize: "lg"
                                    })),
                            variant: "outlined",
                            color: "neutral",
                            disabled: true,
                            children: Caml_option.some(Txt$Thin.BookingDetails.addBerth.value)
                          }),
                      style: {
                        width: "fit-content"
                      }
                    }),
                arrow: true,
                size: "sm",
                sx: {
                  maxWidth: "12rem",
                  width: "fit-content"
                },
                title: "Cannot add another berth at this time."
              })
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                tmp,
                JsxRuntime.jsx(CheckValidity$Thin.ErrorModal.make, {
                      error: match.error,
                      setError: match.setError
                    })
              ]
            });
}

var AddBerth = {
  make: BDPassengerList$AddBerth
};

function BDPassengerList$PaxDisplay(props) {
  var children = props.children;
  var title = props.title;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                title !== undefined ? JsxRuntime.jsx(Typography, {
                        level: "h4",
                        children: Caml_option.some(title)
                      }) : null,
                JsxRuntime.jsxs(Table, {
                      sx: {
                        width: {
                          xs: "100%"
                        },
                        display: {
                          xs: "none",
                          lg: "table"
                        },
                        "--Table-headerUnderlineThickness": "1px",
                        "& th": {
                          py: 1
                        },
                        "& td": {
                          py: 1
                        }
                      },
                      children: [
                        JsxRuntime.jsx("thead", {
                              children: JsxRuntime.jsxs("tr", {
                                    children: [
                                      JsxRuntime.jsx("th", {
                                            style: {
                                              width: "2rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: Caml_option.some(Txt$Thin.BookingDetails.plStatus.value)
                                                }),
                                            style: {
                                              width: "10rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: Caml_option.some(Txt$Thin.BookingDetails.plName.value)
                                                }),
                                            style: {
                                              width: "12rem"
                                            }
                                          }),
                                      props.showClBN ? JsxRuntime.jsx("th", {
                                              children: JsxRuntime.jsx(Typography, {
                                                    level: "body-xs",
                                                    textColor: "neutral.500",
                                                    textTransform: "uppercase",
                                                    children: "HAL Booking #"
                                                  }),
                                              style: {
                                                width: "10rem"
                                              }
                                            }) : null,
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: "HAL Mariner #"
                                                }),
                                            style: {
                                              width: "10rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: "Tier"
                                                }),
                                            style: {
                                              width: "10rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: Caml_option.some(Txt$Thin.BookingDetails.plBirthdate.value)
                                                }),
                                            style: {
                                              width: "10rem"
                                            }
                                          }),
                                      JsxRuntime.jsx("th", {
                                            children: JsxRuntime.jsx(Typography, {
                                                  level: "body-xs",
                                                  textColor: "neutral.500",
                                                  textTransform: "uppercase",
                                                  children: Caml_option.some(Txt$Thin.BookingDetails.plEmail.value)
                                                }),
                                            style: {
                                              width: "12rem"
                                            }
                                          })
                                    ]
                                  })
                            }),
                        JsxRuntime.jsx("tbody", {
                              children: children
                            })
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      spacing: 1,
                      sx: {
                        display: {
                          xs: "flex",
                          lg: "none"
                        }
                      },
                      children: Caml_option.some(children)
                    })
              ]
            });
}

var PaxDisplay = {
  make: BDPassengerList$PaxDisplay
};

function BDPassengerList(props) {
  var fareClassManagePaxBlocked = props.fareClassManagePaxBlocked;
  var paxManagementLocked = props.paxManagementLocked;
  var numBerths = props.numBerths;
  var passengers = props.passengers;
  var bookingVersionNumber = props.bookingVersionNumber;
  var bookingId = props.bookingId;
  var voyageSlug = props.voyageSlug;
  var countryFrag = props.countryFrag;
  var draftMode = props.draftMode;
  var canEdit = props.canEdit;
  var getPreviewFromDiff = props.getPreviewFromDiff;
  var custodianAccountId = props.custodianAccountId;
  var allConnected = props.allConnected;
  var areConnectedAccts = props.areConnectedAccts;
  var queryFrag = props.queryFrag;
  var refetchBookingDetails = props.refetch;
  var isActive = props.isActive;
  var isBc = props.isBc;
  var currentUserEmail = props.currentUserEmail;
  var fragmentRefs = props.fragmentRefs;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diff = BookingDiff$Thin.parse(url.search);
  var cantDetach = Belt_Array.keep(passengers, (function (pax) {
          return Caml_obj.equal(pax.paxStatus, {
                      TAG: "Status",
                      _0: "CONFIRMED"
                    });
        })).length === 1;
  var cantDrop = props.salesMinBerth === numBerths;
  var addBerthComponent = function (mobileOpt) {
    var mobile = mobileOpt !== undefined ? mobileOpt : false;
    if (canEdit && draftMode) {
      return JsxRuntime.jsx(Stack, {
                  sx: {
                    display: {
                      xs: mobile ? "flex" : "none",
                      md: mobile ? "none" : "flex"
                    },
                    pb: {
                      xs: mobile ? 2 : 0,
                      md: 0
                    }
                  },
                  children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$AddBerth, {
                            fragmentRefs: fragmentRefs,
                            numBerths: numBerths,
                            diff: diff,
                            url: url,
                            paxManagementLocked: paxManagementLocked,
                            fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                            getPreviewFromDiff: getPreviewFromDiff
                          }))
                });
    } else {
      return null;
    }
  };
  var userConnected = allConnected !== undefined ? Belt_Set.fromArray(Belt_Array.map(allConnected.nodes, (function (node) {
                return node.accountId;
              })), CS_NonemptyStrings$Util.AccountId.Comparable) : Belt_Set.make(CS_NonemptyStrings$Util.AccountId.Comparable);
  var match = Belt_Array.partition(passengers, (function (pax) {
          if (Caml_obj.equal(Belt_Option.map(pax.email, CS_Emails$Util.Email.toPrimitive), currentUserEmail)) {
            return true;
          }
          var accountId = pax.accountId;
          if (accountId !== undefined) {
            return Belt_Set.has(userConnected, Caml_option.valFromOption(accountId));
          } else {
            return false;
          }
        }));
  var paxesIManage = match[0];
  var pax1MustBeBornBy = Belt_Option.flatMap(props.bookingListitem, (function (item) {
          return Belt_Option.map(item.voyage, (function (voyage) {
                        return voyage.pax1MustBeBornBy;
                      }));
        }));
  var tempContext_bookingId = CS_NonemptyStrings$Util.BookingId.toString(bookingId);
  var tempContext_mailtoEmail = SysCtx$Thin.vars.mailtoEmail;
  var tempContext = {
    bookingId: tempContext_bookingId,
    mailtoEmail: tempContext_mailtoEmail
  };
  var anyClBNAvailable = Belt_Array.reduce(passengers, false, (function (a, b) {
          if (a) {
            return true;
          } else {
            return b.cruiselineBookingNumber !== undefined;
          }
        }));
  var tmp;
  var exit = 0;
  if (isBc) {
    if (draftMode) {
      exit = 1;
    } else {
      tmp = paxManagementLocked ? null : JsxRuntime.jsx(Typography, {
              level: "body-sm",
              textColor: "neutral.500",
              children: "Press the \"Manage Booking\" button to enable changes to this list"
            });
    }
  } else if (draftMode) {
    exit = 1;
  } else {
    tmp = paxManagementLocked ? JsxRuntime.jsx(Typography, {
            level: "body-sm",
            textColor: "neutral.500",
            children: Caml_option.some(Merge$Util.render(Txt$Thin.BDTemp.tempManagePassengersLockedNonBCText, tempContext))
          }) : null;
  }
  if (exit === 1) {
    tmp = paxManagementLocked ? JsxRuntime.jsx(Typography, {
            level: "body-sm",
            textColor: "neutral.500",
            children: Caml_option.some(Merge$Util.render(Txt$Thin.BDTemp.tempManagePassengersLockedText, tempContext))
          }) : null;
  }
  return JsxRuntime.jsxs(BDContentBlock$Thin.make, {
              title: Txt$Thin.BookingDetails.passengerListHeading.value + " (" + String(numBerths) + ")",
              titleComponent: Caml_option.some(addBerthComponent(undefined)),
              anchor: "paxList",
              children: [
                tmp,
                JsxRuntime.jsxs(Sheet, {
                      sx: {
                        overflow: "auto"
                      },
                      children: [
                        addBerthComponent(true),
                        isBc || paxesIManage.length === 0 ? JsxRuntime.jsx(BDPassengerList$PaxDisplay, {
                                showClBN: anyClBNAvailable,
                                children: Belt_Array.mapWithIndex(passengers, (function (i, pax) {
                                        return JsxRuntime.jsx(BDPassengerList$PassengerInformation, {
                                                    pax: pax,
                                                    currentUserEmail: currentUserEmail,
                                                    areConnectedAccts: areConnectedAccts,
                                                    queryFrag: queryFrag,
                                                    custodianAccountId: custodianAccountId,
                                                    getPreviewFromDiff: getPreviewFromDiff,
                                                    cantDetach: cantDetach,
                                                    cantDrop: cantDrop,
                                                    draftMode: draftMode,
                                                    isBc: isBc,
                                                    isActive: isActive,
                                                    userConnected: userConnected,
                                                    countryFrag: countryFrag,
                                                    refetchBookingDetails: refetchBookingDetails,
                                                    voyageSlug: voyageSlug,
                                                    bookingId: bookingId,
                                                    bookingVersionNumber: bookingVersionNumber,
                                                    pax1MustBeBornBy: pax1MustBeBornBy,
                                                    paxManagementLocked: paxManagementLocked,
                                                    fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                                                    isLastTableRow: i === (passengers.length - 1 | 0),
                                                    showClBN: anyClBNAvailable
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$PaxDisplay, {
                                                  title: "Passengers I manage",
                                                  showClBN: anyClBNAvailable,
                                                  children: Belt_Array.mapWithIndex(paxesIManage, (function (i, pax) {
                                                          return JsxRuntime.jsx(BDPassengerList$PassengerInformation, {
                                                                      pax: pax,
                                                                      currentUserEmail: currentUserEmail,
                                                                      areConnectedAccts: areConnectedAccts,
                                                                      queryFrag: queryFrag,
                                                                      custodianAccountId: custodianAccountId,
                                                                      getPreviewFromDiff: getPreviewFromDiff,
                                                                      cantDetach: cantDetach,
                                                                      cantDrop: cantDrop,
                                                                      draftMode: draftMode,
                                                                      isBc: isBc,
                                                                      isActive: isActive,
                                                                      userConnected: userConnected,
                                                                      countryFrag: countryFrag,
                                                                      refetchBookingDetails: refetchBookingDetails,
                                                                      voyageSlug: voyageSlug,
                                                                      bookingId: bookingId,
                                                                      bookingVersionNumber: bookingVersionNumber,
                                                                      pax1MustBeBornBy: pax1MustBeBornBy,
                                                                      paxManagementLocked: paxManagementLocked,
                                                                      fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                                                                      isLastTableRow: i === (paxesIManage.length - 1 | 0),
                                                                      showClBN: anyClBNAvailable,
                                                                      defaultOpen: true
                                                                    }, String(i));
                                                        }))
                                                }))
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$PaxDisplay, {
                                                  title: "Other Passengers",
                                                  showClBN: anyClBNAvailable,
                                                  children: Belt_Array.mapWithIndex(match[1], (function (i, pax) {
                                                          return JsxRuntime.jsx(BDPassengerList$PassengerInformation, {
                                                                      pax: pax,
                                                                      currentUserEmail: currentUserEmail,
                                                                      areConnectedAccts: areConnectedAccts,
                                                                      queryFrag: queryFrag,
                                                                      custodianAccountId: custodianAccountId,
                                                                      getPreviewFromDiff: getPreviewFromDiff,
                                                                      cantDetach: cantDetach,
                                                                      cantDrop: cantDrop,
                                                                      draftMode: draftMode,
                                                                      isBc: isBc,
                                                                      isActive: isActive,
                                                                      userConnected: userConnected,
                                                                      countryFrag: countryFrag,
                                                                      refetchBookingDetails: refetchBookingDetails,
                                                                      voyageSlug: voyageSlug,
                                                                      bookingId: bookingId,
                                                                      bookingVersionNumber: bookingVersionNumber,
                                                                      pax1MustBeBornBy: pax1MustBeBornBy,
                                                                      paxManagementLocked: paxManagementLocked,
                                                                      fareClassManagePaxBlocked: fareClassManagePaxBlocked,
                                                                      showClBN: anyClBNAvailable
                                                                    }, String(i));
                                                        }))
                                                }))
                                      })
                                ]
                              })
                      ]
                    })
              ],
              initiallyOpen: true
            });
}

var make = BDPassengerList;

exports.DetailFragment = DetailFragment;
exports.PassengerMenu = PassengerMenu;
exports.PassengerCard = PassengerCard;
exports.PassengerInformation = PassengerInformation;
exports.AddBerthFragment = AddBerthFragment;
exports.AddBerth = AddBerth;
exports.PaxDisplay = PaxDisplay;
exports.make = make;
/* react Not a pure module */
