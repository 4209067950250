// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"salesMinBerth":{"b":""},"passengers_nodes_preferredLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passengers_nodes_preferredFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passengers_nodes_numPendingVoyages":{"b":""},"passengers_nodes_numEligibleVoyages":{"b":""},"passengers_nodes_invitationEmail":{"c":"Util.CustomScalars.Email.Exn"},"passengers_nodes_idx":{"b":""},"passengers_nodes_halMarinerNumber":{"c":"Util.CustomScalars.HalMarinerNumber.Exn"},"passengers_nodes_email":{"c":"Util.CustomScalars.Email.Exn"},"passengers_nodes_eligibleVoyageSlugs":{"ca":"Util.CustomScalars.VoyageSlug.Exn"},"passengers_nodes_docLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passengers_nodes_docFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passengers_nodes_docCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"passengers_nodes_docBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"passengers_nodes_currentTierTitle":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passengers_nodes_cruiselineBookingNumber":{"c":"Util.CustomScalars.CruiselineBookingNumber.Exn"},"passengers_nodes_bu_loyaltyNumber":{"c":"Util.CustomScalars.LoyaltyNumber.Exn"},"passengers_nodes_accountId":{"c":"Util.CustomScalars.AccountId.Exn"},"numBerths":{"b":""},"bookingListitem_voyage_pax1MustBeBornBy":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"bookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"":{"f":""}}};

var fragmentConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse,
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse,
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.parse,
  "Util.CustomScalars.LoyaltyNumber.Exn": CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.Exn.parse,
  "Util.CustomScalars.CruiselineBookingNumber.Exn": CS_NonemptyStrings$Util.CruiselineBookingNumber.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse,
  "Util.CustomScalars.HalMarinerNumber.Exn": CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function docGender_decode($$enum) {
  if ($$enum === "F" || $$enum === "M" || $$enum === "X") {
    return $$enum;
  }
  
}

function docGender_fromString(str) {
  return docGender_decode(str);
}

function fareClassPaxManagement_decode($$enum) {
  if ($$enum === "TBA_AND_PATCH_ONLY" || $$enum === "ENABLE_ALL" || $$enum === "PATCH_ONLY") {
    return $$enum;
  }
  
}

function fareClassPaxManagement_fromString(str) {
  return fareClassPaxManagement_decode(str);
}

function paxDataStatus_decode($$enum) {
  if ($$enum === "INCOMPLETE" || $$enum === "CONFIRMED" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

function paxDataStatus_fromString(str) {
  return paxDataStatus_decode(str);
}

var Utils = {
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString,
  fareClassPaxManagement_decode: fareClassPaxManagement_decode,
  fareClassPaxManagement_fromString: fareClassPaxManagement_fromString,
  paxDataStatus_decode: paxDataStatus_decode,
  paxDataStatus_fromString: paxDataStatus_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookingDetailsPage_passengers",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "BOOKING_ID_ASC",
            "IDX_ASC"
          ]
        }
      ],
      "concreteType": "BookingPassengersConnection",
      "kind": "LinkedField",
      "name": "passengers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingPassenger",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docFirstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docLastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docBirthdate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docGender",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docCitizenship",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferredFirstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferredLastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "preferredFullName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paxDataStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invitationEmail",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "idx",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentTierTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numEligibleVoyages",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numPendingVoyages",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "eligibleVoyageSlugs",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cruiselineBookingNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "halMarinerNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "bu",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "loyaltyNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "passengers(orderBy:[\"BOOKING_ID_ASC\",\"IDX_ASC\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingListitem",
      "kind": "LinkedField",
      "name": "bookingListitem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandFamilyVoyage",
          "kind": "LinkedField",
          "name": "voyage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pax1MustBeBornBy",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBerths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salesMinBerth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paxManagementLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefManagementLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dinnerSeatingLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fareClassManagePaxBlocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cabinPlacementReleased",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BDPassengerList_addberthdetail"
    }
  ],
  "type": "BookingDetail",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Make-Util Not a pure module */
