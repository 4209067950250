// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"upcoming_nodes_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"},"upcoming_nodes":{"f":""},"brandFamilyVoyages_nodes_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"brandFamilyVoyages_nodes_voyageName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"brandFamilyVoyages_nodes_voyStartDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"brandFamilyVoyages_nodes_voyEndDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"brandFamilyVoyages_nodes_imageSetSlug":{"c":"Util.CustomScalars.ImageSetSlug.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.ImageSetSlug.Exn": CS_NonemptyStrings$Util.ImageSetSlug.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse,
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpcomingBookingsSection_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandFamilyVoyagesConnection",
      "kind": "LinkedField",
      "name": "brandFamilyVoyages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandFamilyVoyage",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyageSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyageName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyStartDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "voyEndDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "imageSetSlug",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "upcoming",
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "lifecycleStatus": "ACTIVE"
          }
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "greaterThanOrEqualTo",
                  "variableName": "currentDate"
                }
              ],
              "kind": "ObjectValue",
              "name": "voyStartDate"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "VOY_START_DATE_ASC"
        }
      ],
      "concreteType": "BookingListitemsConnection",
      "kind": "LinkedField",
      "name": "bookingListitems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingListitem",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bookingId",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UpcomingBookingsSection_listitem"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Slugs-Util Not a pure module */
