// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CS_Make$Util = require("./CS_Make.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");

function toPrimitive(a) {
  return a;
}

function primitiveToEl(prim) {
  return prim;
}

function primitiveToJson(prim) {
  return prim;
}

var regex = /^[0-9]{6,}[A-Z]?$/;

var all0 = /^0{6,}/;

var struct = S$RescriptSchema.refine(S$RescriptSchema.refine(S$RescriptSchema.stringMinLength(S$RescriptSchema.string, 6, undefined), (function (s) {
            return function (v) {
              if (regex.test(v)) {
                return ;
              } else {
                return s.fail("Mariner start with six digits", undefined);
              }
            };
          })), (function (s) {
        return function (v) {
          if (all0.test(v)) {
            return s.fail("Mariner numbers may not start with 6 0s", undefined);
          }
          
        };
      }));

var HalMarinerNumber = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive,
      primitiveToEl: primitiveToEl,
      primitiveToJson: primitiveToJson,
      struct: struct,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

function toPrimitive$1(a) {
  return a;
}

function primitiveToEl$1(prim) {
  return prim;
}

function primitiveToJson$1(prim) {
  return prim;
}

var regex$1 = /^[bcdfghjkmpqrtvwxy346789]+$/;

var struct$1 = S$RescriptSchema.refine(S$RescriptSchema.stringMaxLength(S$RescriptSchema.stringMinLength(S$RescriptSchema.string, 8, undefined), 8, undefined), (function (s) {
        return function (v) {
          if (regex$1.test(v)) {
            return ;
          } else {
            return s.fail("Does not appear to be a valid loyalty number", undefined);
          }
        };
      }));

var LoyaltyNumber = CS_Make$Util.Stringable.MakeFromStruct({
      toPrimitive: toPrimitive$1,
      primitiveToEl: primitiveToEl$1,
      primitiveToJson: primitiveToJson$1,
      struct: struct$1,
      QueryVar: CS_Make$Util.QueryVar.$$String
    });

exports.HalMarinerNumber = HalMarinerNumber;
exports.LoyaltyNumber = LoyaltyNumber;
/* HalMarinerNumber Not a pure module */
