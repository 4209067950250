// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../Dialog.bs.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../../../../context/AppConfig.bs.js");
var HopperURL$Thin = require("../../../../context/HopperURL.bs.js");
var Button = require("@mui/joy/Button").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function PlaidTermsModal(props) {
  var onClose = props.onClose;
  var match = AppConfig$Thin.Context.use();
  var brandFamilyName = match.brandFamily.brandFamilyName;
  var urlState = HopperURL$Thin.useUrl();
  var path = HopperState$Util.Observable.useComputed(urlState.url, (function (param) {
          return param.path;
        }), undefined, undefined);
  var voyageSlug;
  if (path && !path.tl) {
    var voyageSlug$1 = path.hd;
    voyageSlug = Belt_Result.isOk(match.voyages.checkVoyageSlug(voyageSlug$1)) ? voyageSlug$1 : "2025";
  } else {
    voyageSlug = "2025";
  }
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: "Plaid Terms & Conditions",
              onClose: onClose,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                spacing: 1,
                                children: Caml_option.some(JsxRuntime.jsxs(List, {
                                          children: [
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              brandFamilyName + " uses ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: "Plaid",
                                                                    href: "https://plaid.com/",
                                                                    target: "_blank"
                                                                  }),
                                                              " to connect to your bank. Read more in our ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: "FAQ",
                                                                    href: "https://jococruise.com/" + voyageSlug + "faq/#how-does-the-ach-transfer-process",
                                                                    target: "_blank"
                                                                  }),
                                                              "."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              brandFamilyName + "'s privacy policy can be found ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: "here",
                                                                    href: "https://jococruise.com/" + voyageSlug + "faq/#what-is-your-privacy-policy",
                                                                    target: "_blank"
                                                                  }),
                                                              ". Plaid's can be found  ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: "here",
                                                                    href: "https://plaid.com/legal/#end-user-privacy-policy",
                                                                    target: "_blank"
                                                                  }),
                                                              "."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "If you do not have a U.S.-based bank account or your bank is not listed, please ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: "email us",
                                                                    href: "mailto:booking@jococruise.com?subject=My%20Bank%20is%20Not%20Listed",
                                                                    target: "_blank"
                                                                  }),
                                                              "."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "By using the Plaid service, you are consenting to  ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: brandFamilyName + "'s privacy policy",
                                                                    href: "https://jococruise.com/" + voyageSlug + "faq/#what-is-your-privacy-policy",
                                                                    target: "_blank"
                                                                  }),
                                                              "  and to ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: "Plaid's",
                                                                    href: "https://plaid.com/legal/#end-user-privacy-policy",
                                                                    target: "_blank"
                                                                  }),
                                                              "."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "If you live in the U.S. but your system won't let you send an ACH transfer, please  ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: "see our FAQ",
                                                                    href: "https://jococruise.com/" + voyageSlug + "faq/#i-live-in-the-us-but-your-system",
                                                                    target: "_blank"
                                                                  }),
                                                              "  and email us at ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: "booking@jococruise.com",
                                                                    href: "mailto:booking@jococruise.com?",
                                                                    target: "_blank"
                                                                  }),
                                                              " so that we can work directly with you to complete your payment."
                                                            ]
                                                          }))
                                                }),
                                            JsxRuntime.jsx(ListItem, {
                                                  children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                            level: "body-sm",
                                                            children: [
                                                              "If you don't live in the U.S. and/or otherwise cannot send an ACH bank transfer payment, please email us at  ",
                                                              JsxRuntime.jsx(Joy$Util.Link.make, {
                                                                    children: "booking@jococruise.com",
                                                                    href: "mailto:booking@jococruise.com?",
                                                                    target: "_blank"
                                                                  }),
                                                              " so that we can work directly with you to complete your payment."
                                                            ]
                                                          }))
                                                })
                                          ],
                                          component: "ul"
                                        }))
                              })),
                      sx: {
                        px: 2
                      }
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                onClick: onClose,
                                children: "Close"
                              }))
                    })
              ]
            });
}

var make = PlaidTermsModal;

exports.make = make;
/* Joy-Util Not a pure module */
