// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var AppAlert$Thin = require("../../common/atoms/AppAlert.bs.js");
var PaxFrame$Thin = require("./PaxFrame.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AppConfig$Thin = require("../../../context/AppConfig.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var PassengerForms$Thin = require("../../common/molecules/PassengerForms.bs.js");
var Add = require("@mui/icons-material/Add").default;
var CircularProgress = require("@mui/joy/CircularProgress").default;
var CheckCircle = require("@mui/icons-material/CheckCircle").default;
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");

function ReferralInput(props) {
  var sessionData = props.sessionData;
  var match = AppConfig$Thin.Context.use();
  var brandFamilyName = match.brandFamily.brandFamilyName;
  var match$1 = React.useState(function () {
        return false;
      });
  var match$2 = React.useState(function () {
        
      });
  var setCantSaveError = match$2[1];
  var cantSaveError = match$2[0];
  var match$3 = HopperState$Util.Observable.useComputed(sessionData, (function (m) {
          return [
                  m.referral.value,
                  m.referralSuccess.value,
                  m.referralError.value
                ];
        }), undefined, undefined);
  var referralError = match$3[2];
  var referralSuccess = match$3[1];
  var referralNum = match$3[0];
  var sessionSaveHandler = props.useSessionSaveHandler(sessionData, undefined, undefined, match$1[1]);
  var onSubmit = function (e) {
    e.preventDefault();
    setCantSaveError(function (param) {
          
        });
    if (sessionSaveHandler.TAG === "Ok") {
      return sessionSaveHandler._0();
    }
    
  };
  React.useEffect((function () {
          if (Belt_Result.isError(sessionSaveHandler) && referralSuccess === undefined) {
            setCantSaveError(function (param) {
                  return "Please complete passenger details before adding a referrer!";
                });
          } else {
            setCantSaveError(function (param) {
                  
                });
          }
        }), [
        Belt_Result.isError(sessionSaveHandler),
        referralSuccess === undefined
      ]);
  var match$4 = Belt_Option.map(referralNum, CS_LoyaltyAndCruiselineNumbers$Util.LoyaltyNumber.fromPrimitive);
  var referrerIsInvalidLoyaltyNum = match$4 !== undefined && match$4.TAG === "Ok" ? false : true;
  var endDecorator;
  var exit = 0;
  if (referralSuccess !== undefined && referralNum !== undefined && referralSuccess === referralNum) {
    endDecorator = JsxRuntime.jsx(CheckCircle, {
          color: "success"
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    endDecorator = JsxRuntime.jsx(IconButton, {
          type: "submit",
          "aria-label": "search",
          disabled: referrerIsInvalidLoyaltyNum || Belt_Option.isSome(cantSaveError),
          children: Caml_option.some(match$1[0] ? JsxRuntime.jsx(CircularProgress, {}) : JsxRuntime.jsx(Add, {}))
        });
  }
  return JsxRuntime.jsx(PaxFrame$Thin.make, {
              title: "Referral",
              children: JsxRuntime.jsxs(Stack, {
                    direction: "column",
                    spacing: 2,
                    sx: {
                      pt: 1
                    },
                    children: [
                      JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
                            view: {
                              TAG: "Direct",
                              _0: sessionData
                            },
                            input: SFState$Thin.referral,
                            map: [
                              (function (o) {
                                  return Belt_Option.getWithDefault(o, "");
                                }),
                              (function (s) {
                                  if (s === "") {
                                    return ;
                                  } else {
                                    return s;
                                  }
                                })
                            ],
                            children: (function (param) {
                                var set = param.set;
                                return JsxRuntime.jsx(Stack, {
                                            component: "form",
                                            onSubmit: onSubmit,
                                            children: Caml_option.some(JsxRuntime.jsx(PassengerForms$Thin.FormControl.make, {
                                                      label: "Add referral by " + brandFamilyName + " loyalty number.",
                                                      errorText: param.errorText,
                                                      error: param.error,
                                                      disabled: Belt_Option.isSome(cantSaveError) && referralSuccess === undefined,
                                                      children: JsxRuntime.jsx(Joy$Util.Input.make, {
                                                            type_: "text",
                                                            placeholder: "Loyalty number",
                                                            disabled: Belt_Option.isSome(cantSaveError) && referralSuccess === undefined,
                                                            variant: "outlined",
                                                            value: param.value,
                                                            onChange: (function (e) {
                                                                set(function (param) {
                                                                      return e.target.value;
                                                                    });
                                                              }),
                                                            endDecorator: Caml_option.some(endDecorator)
                                                          }),
                                                      wasTouched: param.wasTouched
                                                    }))
                                          });
                              })
                          }),
                      cantSaveError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: cantSaveError,
                              type_: "warning",
                              size: "sm"
                            }) : null,
                      referralError !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: referralError,
                              type_: "error",
                              size: "sm"
                            }) : null,
                      referralSuccess !== undefined ? JsxRuntime.jsx(AppAlert$Thin.make, {
                              message: "Referrer " + referralSuccess + " has been logged for this booking!",
                              type_: "success",
                              size: "sm"
                            }) : null
                    ]
                  })
            });
}

var make = ReferralInput;

exports.make = make;
/* react Not a pure module */
