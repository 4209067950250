// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var Stack = require("@mui/joy/Stack").default;
var SalesStep$Thin = require("./SalesStep.bs.js");
var Drawer = require("@mui/joy/Drawer").default;
var Divider = require("@mui/joy/Divider").default;
var ProgressBar$Thin = require("./ProgressBar.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ModalClose = require("@mui/joy/ModalClose").default;
var DialogTitle = require("@mui/joy/DialogTitle").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function SalesNav(props) {
  var url = props.url;
  var urlState = props.urlState;
  var cabinTargetingCriteria = props.cabinTargetingCriteria;
  var showSteppers = props.showSteppers;
  var flow = props.flow;
  var validNavStates = props.validNavStates;
  var match = React.useState(function () {
        return false;
      });
  var setMobileDrawerOpen = match[1];
  var validNavTargets;
  switch (flow) {
    case "ByCategory" :
        validNavTargets = SFState$Thin.ByCategory.validNavTargets;
        break;
    case "AllNoCabin" :
        validNavTargets = SFState$Thin.ByAllNoCabin.validNavTargets;
        break;
    case "All" :
        validNavTargets = SFState$Thin.ByAll.validNavTargets;
        break;
    
  }
  var currentStep = Belt_Array.reduce(Belt_Array.map(Belt_Set.toArray(validNavTargets(validNavStates)), (function (target) {
              switch (target) {
                case "EnterPassengerDetails" :
                    return 2;
                case "Checkout" :
                    return 3;
                default:
                  return 1;
              }
            })), 0, (function (acc, x) {
          return Math.max(acc, x);
        }));
  var calculateStepStatus = function (step, onStep) {
    var $$int = onStep - step | 0;
    if ($$int !== 0) {
      if ($$int < 0) {
        return "NotStarted";
      } else {
        return "Completed";
      }
    } else {
      return "InProgress";
    }
  };
  var steps = function (isMobileOpt, shouldOpenDrawerOnClickOpt, currentStep, validNavStates) {
    var isMobile = isMobileOpt !== undefined ? isMobileOpt : false;
    var shouldOpenDrawerOnClick = shouldOpenDrawerOnClickOpt !== undefined ? shouldOpenDrawerOnClickOpt : false;
    return [
            [
              1,
              JsxRuntime.jsx(SalesStep$Thin.make, {
                    step: 1,
                    title: "Select a stateroom",
                    stepStatus: calculateStepStatus(1, currentStep),
                    showSteppers: showSteppers,
                    targetingCriteria: cabinTargetingCriteria,
                    urlState: urlState,
                    url: url,
                    validNavStates: validNavStates,
                    validNavTargets: validNavTargets,
                    flow: flow,
                    onClose: (function () {
                        setMobileDrawerOpen(function (param) {
                              return false;
                            });
                      }),
                    isMobile: isMobile,
                    shouldOpenDrawerOnClick: shouldOpenDrawerOnClick,
                    totalStepNumber: 4
                  }),
              cabinTargetingCriteria
            ],
            [
              2,
              JsxRuntime.jsx(SalesStep$Thin.make, {
                    step: 2,
                    title: "Enter passenger details",
                    stepStatus: calculateStepStatus(2, currentStep),
                    targetingCriteria: ["EnterPassengerDetails"],
                    urlState: urlState,
                    url: url,
                    validNavStates: validNavStates,
                    validNavTargets: validNavTargets,
                    flow: flow,
                    onClose: (function () {
                        setMobileDrawerOpen(function (param) {
                              return false;
                            });
                      }),
                    isMobile: isMobile,
                    shouldOpenDrawerOnClick: shouldOpenDrawerOnClick,
                    totalStepNumber: 4
                  }),
              ["EnterPassengerDetails"]
            ],
            [
              3,
              JsxRuntime.jsx(SalesStep$Thin.make, {
                    step: 3,
                    title: "Checkout",
                    stepStatus: calculateStepStatus(3, currentStep),
                    targetingCriteria: ["Checkout"],
                    urlState: urlState,
                    url: url,
                    validNavStates: validNavStates,
                    validNavTargets: validNavTargets,
                    flow: flow,
                    onClose: (function () {
                        setMobileDrawerOpen(function (param) {
                              return false;
                            });
                      }),
                    isMobile: isMobile,
                    shouldOpenDrawerOnClick: shouldOpenDrawerOnClick,
                    totalStepNumber: 4
                  }),
              ["Checkout"]
            ],
            [
              4,
              JsxRuntime.jsx(SalesStep$Thin.make, {
                    step: 4,
                    title: "Preferences",
                    stepStatus: calculateStepStatus(4, currentStep),
                    urlState: urlState,
                    url: url,
                    validNavStates: validNavStates,
                    validNavTargets: validNavTargets,
                    flow: flow,
                    onClose: (function () {
                        setMobileDrawerOpen(function (param) {
                              return false;
                            });
                      }),
                    isMobile: isMobile,
                    shouldOpenDrawerOnClick: shouldOpenDrawerOnClick,
                    totalStepNumber: 4
                  }),
              []
            ]
          ];
  };
  var match$1 = Belt_Array.get(Belt_Array.keep(steps(false, true, currentStep, validNavStates), (function (param) {
              return Belt_Array.some(param[2], (function (v) {
                            return v === urlState.navTarget;
                          }));
            })), 0);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      sx: {
                        backgroundColor: "background.surface",
                        zIndex: 4
                      },
                      children: [
                        JsxRuntime.jsx(ProgressBar$Thin.make, {
                              currentStep: currentStep
                            }),
                        JsxRuntime.jsxs(Stack, {
                              sx: {
                                py: 2,
                                border: "1px solid",
                                borderColor: "neutral.300"
                              },
                              children: [
                                JsxRuntime.jsx(Joy$Util.Grid.make, {
                                      children: Caml_option.some(Belt_Array.map(steps(false, false, currentStep, validNavStates), (function (param) {
                                                  return JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                              children: Caml_option.some(param[1]),
                                                              xs: 3
                                                            }, String(param[0]));
                                                }))),
                                      container: true,
                                      sx: {
                                        display: {
                                          md: "flex",
                                          xs: "none"
                                        }
                                      }
                                    }),
                                JsxRuntime.jsx(Stack, {
                                      direction: "row",
                                      sx: {
                                        display: {
                                          md: "none",
                                          xs: "flex"
                                        },
                                        width: "fit-content",
                                        pl: 2
                                      },
                                      children: Caml_option.some(match$1 !== undefined ? JsxRuntime.jsx(Stack, {
                                                  sx: {
                                                    width: "fit-content"
                                                  },
                                                  onClick: (function (param) {
                                                      setMobileDrawerOpen(function (param) {
                                                            return true;
                                                          });
                                                    }),
                                                  children: Caml_option.some(match$1[1])
                                                }) : JsxRuntime.jsx(JsxRuntime.Fragment, {}))
                                    })
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "flex",
                          md: "none"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Drawer, {
                                children: [
                                  JsxRuntime.jsx(ModalClose, {}),
                                  JsxRuntime.jsx(DialogTitle, {
                                        children: "Steps"
                                      }),
                                  JsxRuntime.jsx(Divider, {}),
                                  JsxRuntime.jsx(DialogContent, {
                                        children: Caml_option.some(Belt_Array.map(steps(true, false, currentStep, validNavStates), (function (param) {
                                                    var step = param[0];
                                                    var stepStatus = calculateStepStatus(step, currentStep);
                                                    return JsxRuntime.jsxs(React.Fragment, {
                                                                children: [
                                                                  JsxRuntime.jsx(Stack, {
                                                                        sx: {
                                                                          px: 2,
                                                                          py: 3,
                                                                          position: "relative",
                                                                          backgroundColor: stepStatus === "InProgress" ? "neutral.100" : "background.surface"
                                                                        },
                                                                        children: Caml_option.some(param[1])
                                                                      }, String(step)),
                                                                  step !== steps(true, false, currentStep, validNavStates).length ? JsxRuntime.jsx(Divider, {}) : null
                                                                ]
                                                              }, String(step));
                                                  })))
                                      })
                                ],
                                open: match[0],
                                anchor: "bottom",
                                disablePortal: true,
                                onClose: (function (param) {
                                    setMobileDrawerOpen(function (param) {
                                          return false;
                                        });
                                  }),
                                size: "md",
                                slotProps: {
                                  backdrop: {
                                    sx: {
                                      backdropFilter: "none",
                                      backgroundColor: "rgba(16, 13, 12, 0.7)"
                                    }
                                  },
                                  content: {
                                    sx: {
                                      "--Drawer-verticalSize": "clamp(500px, fit-content, 75%)",
                                      borderTopRightRadius: "4px",
                                      borderTopLeftRadius: "4px",
                                      ml: "8px",
                                      mr: "8px",
                                      width: "calc(100% - 16px)"
                                    }
                                  }
                                }
                              }))
                    })
              ]
            });
}

var make = SalesNav;

exports.make = make;
/* react Not a pure module */
