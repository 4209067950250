// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var BCForm$Thin = require("./BCForm.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var PaxForm$Thin = require("./PaxForm.bs.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CurrentUser$Thin = require("../../../context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var SalesHeading$Thin = require("../shared/SalesHeading.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ReferralInput$Thin = require("./ReferralInput.bs.js");
var SelectedCabinSummaryCard$Thin = require("../shared/SelectedCabinSummaryCard.bs.js");

function PaxDetails(props) {
  var countryFrag = props.countryFrag;
  var sessionSaveLoading = props.sessionSaveLoading;
  var useSessionSaveHandler = props.useSessionSaveHandler;
  var pax1MustBeBornBy = props.pax1MustBeBornBy;
  var voyageSlug = props.voyageSlug;
  var urlState = props.urlState;
  var sessionData = props.sessionData;
  var occupancy = props.occupancy;
  var url = props.url;
  var currentUserConnectedInfo = props.currentUserConnectedInfo;
  var currentUserInfo = props.currentUserInfo;
  var numPaxes = Belt_Array.range(1, occupancy);
  var bc = SFState$Thin.billingContactView(sessionData);
  var match = CurrentUser$Thin.Context.use();
  var signOnStatus = match.signOnStatus;
  var completableStatuses = props.getBookableStatuses(voyageSlug);
  var canCompleteBooking = function (s) {
    return Belt_Array.some(completableStatuses, (function (status) {
                  return Caml_obj.equal(status, s);
                }));
  };
  var pageLocked = !Belt_Array.some(completableStatuses, (function (s) {
          return Caml_obj.equal(s, signOnStatus);
        }));
  var highestContiguousValidPaxIdx = HopperState$Util.Observable.useComputed(sessionData, (function (model) {
          return SFState$Thin.highestContiguousValidPaxIdx(model, occupancy);
        }), undefined, undefined);
  React.useEffect((function () {
          HopperState$Util.Observable.notify(sessionData, (function (model) {
                  if (currentUserInfo !== undefined && canCompleteBooking(signOnStatus)) {
                    return SFState$Thin.prefillFromCurrentUserProfile(model, currentUserInfo, voyageSlug);
                  }
                  var newrecord = Caml_obj.obj_dup(SFState$Thin.blankPax(1));
                  return {
                          paxes: model.paxes,
                          bc: (newrecord.paxType = "SignupBC", newrecord),
                          pax1: model.pax1,
                          paymentOption: model.paymentOption,
                          sessionSaveIsMutating: model.sessionSaveIsMutating,
                          signup: model.signup,
                          url: model.url,
                          paymentSuggestions: model.paymentSuggestions,
                          quote: model.quote,
                          referral: model.referral,
                          referralSuccess: model.referralSuccess,
                          referralError: model.referralError
                        };
                }));
        }), [signOnStatus]);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      spacing: 1,
                      sx: {
                        px: {
                          xs: 2,
                          md: 4
                        },
                        py: 2,
                        flex: 1,
                        height: "100%"
                      },
                      children: [
                        JsxRuntime.jsx(SalesHeading$Thin.make, {
                              title: "Passenger Details",
                              description: "Enter passenger details below for each guest.",
                              voyage: props.voyage
                            }),
                        JsxRuntime.jsx(Stack, {
                              direction: "column",
                              sx: {
                                pt: 1,
                                pb: 2
                              },
                              children: Caml_option.some(JsxRuntime.jsxs(Joy$Util.Grid.make, {
                                        children: [
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                          direction: "column",
                                                          spacing: 2,
                                                          children: [
                                                            JsxRuntime.jsx(BCForm$Thin.make, {
                                                                  bc: bc,
                                                                  url: url,
                                                                  urlState: urlState,
                                                                  flow: props.flow,
                                                                  voyageSlug: voyageSlug,
                                                                  refetchCurrentUserInfo: props.refetchCurrentUserInfo,
                                                                  setPageLevelError: props.setPageLevelError,
                                                                  countryFrag: countryFrag,
                                                                  canCompleteBooking: canCompleteBooking
                                                                }),
                                                            Belt_Array.map(numPaxes, (function (paxNum) {
                                                                    return JsxRuntime.jsx(PaxForm$Thin.make, {
                                                                                sessionData: sessionData,
                                                                                paxNum: paxNum,
                                                                                currentUserConnectedInfo: currentUserConnectedInfo,
                                                                                pageLocked: pageLocked || paxNum !== 1 && paxNum > (highestContiguousValidPaxIdx + 1 | 0),
                                                                                voyageSlug: voyageSlug,
                                                                                pax1MustBeBornBy: pax1MustBeBornBy,
                                                                                sessionSaveLoading: sessionSaveLoading,
                                                                                useSessionSaveHandler: useSessionSaveHandler,
                                                                                countryFrag: countryFrag
                                                                              }, String(paxNum));
                                                                  })),
                                                            JsxRuntime.jsx(Stack, {
                                                                  sx: {
                                                                    display: {
                                                                      xs: "flex",
                                                                      md: "none"
                                                                    }
                                                                  },
                                                                  children: Caml_option.some(JsxRuntime.jsx(ReferralInput$Thin.make, {
                                                                            sessionData: sessionData,
                                                                            useSessionSaveHandler: useSessionSaveHandler
                                                                          }))
                                                                })
                                                          ]
                                                        })),
                                                xs: 12,
                                                md: 7
                                              }),
                                          JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                children: Caml_option.some(JsxRuntime.jsx(SelectedCabinSummaryCard$Thin.make, {
                                                          category: props.category,
                                                          cabinHoldExpiry: urlState.cabinHold,
                                                          canAdvance: props.canCheckout,
                                                          onAdvance: (function () {
                                                              SFState$Thin.goTo(urlState, "Checkout", url);
                                                            }),
                                                          advanceText: "Proceed to checkout",
                                                          pageLocked: pageLocked,
                                                          quote: props.quote,
                                                          showReferral: true,
                                                          fareClass: props.fareClass,
                                                          occupancy: occupancy,
                                                          sessionData: sessionData,
                                                          useSessionSaveHandler: useSessionSaveHandler
                                                        })),
                                                xs: 0,
                                                md: 5
                                              })
                                        ],
                                        container: true,
                                        columnSpacing: 2
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        height: "5rem",
                        display: {
                          xs: "flex",
                          md: "none"
                        }
                      }
                    })
              ]
            });
}

var make = PaxDetails;

exports.make = make;
/* react Not a pure module */
