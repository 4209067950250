// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Stack = require("@mui/joy/Stack").default;
var BillPayData$Thin = require("../drafts/BillPayData.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

var dummyData = [
  {
    periodStatus: "PAID_UP",
    dueDate: undefined,
    newAmountDue: "0.00",
    numericAmount: "",
    paymentAmtType: "PERCENTAGE_OF_APPLICABLE_BALANCE"
  },
  {
    periodStatus: "BALANCE_OVERDUE",
    dueDate: Caml_option.some(CS_Make$Util.Dateable.Plain.Exn.parse("2021-01-01T01:00:00Z")),
    newAmountDue: 1000.0,
    numericAmount: 10.0,
    paymentAmtType: "PERCENTAGE_OF_APPLICABLE_BALANCE"
  },
  {
    periodStatus: "BALANCE_DUE",
    dueDate: Caml_option.some(CS_Make$Util.Dateable.Plain.Exn.parse("2021-01-01T01:00:00Z")),
    newAmountDue: 1000.0,
    numericAmount: 20.0,
    paymentAmtType: "PERCENTAGE_OF_APPLICABLE_BALANCE"
  },
  {
    periodStatus: "UPCOMING_BALANCE",
    dueDate: Caml_option.some(CS_Make$Util.Dateable.Plain.Exn.parse("2021-01-01T01:00:00Z")),
    newAmountDue: 1000.0,
    numericAmount: 200.0,
    paymentAmtType: "PER_GUEST_AMOUNT"
  }
];

function BDDepositSchedule(props) {
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              justifyContent: "space-between",
              spacing: 2,
              sx: {
                height: {
                  xs: "auto",
                  md: "100%"
                }
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        spacing: 2,
                        children: [
                          JsxRuntime.jsx(Stack, {
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "h4",
                                          component: "h4",
                                          children: Caml_option.some(Txt$Thin.BookingDetails.depositScheduleTitle.value)
                                        }))
                              }),
                          JsxRuntime.jsx(BillPayData$Thin.DepositScheduleList.make, {
                                content: props.schedule
                              })
                        ]
                      }))
            });
}

var make = BDDepositSchedule;

exports.dummyData = dummyData;
exports.make = make;
/* dummyData Not a pure module */
