// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_UUID$Util = require("util/src/CustomScalars/CS_UUID.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var HopperURL$Thin = require("../../context/HopperURL.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var NewPasswordModel$Thin = require("./NewPasswordModel.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var CS_LoyaltyAndCruiselineNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyAndCruiselineNumbers.bs.js");
var ClaimNewPaxInvitationModelDeref_frag_graphql$Thin = require("../../__generated__/ClaimNewPaxInvitationModelDeref_frag_graphql.bs.js");
var ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin = require("../../__generated__/ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql.bs.js");
var ClaimNewPaxInvitationModelCurrentAccount_frag_graphql$Thin = require("../../__generated__/ClaimNewPaxInvitationModelCurrentAccount_frag_graphql.bs.js");
var ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin = require("../../__generated__/ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql.bs.js");

var baseSlug = "claim-new-pax";

var claimAnonymously = "anon";

var claimLoggedIn = "account";

function toList(param) {
  var uuid = param[0];
  switch (param[1]) {
    case "SelectClaimMethod" :
        return {
                hd: baseSlug,
                tl: {
                  hd: CS_UUID$Util.PlainUUID.toString(uuid),
                  tl: /* [] */0
                }
              };
    case "ClaimAnonymously" :
        return {
                hd: baseSlug,
                tl: {
                  hd: CS_UUID$Util.PlainUUID.toString(uuid),
                  tl: {
                    hd: claimAnonymously,
                    tl: /* [] */0
                  }
                }
              };
    case "ClaimLoggedIn" :
        return {
                hd: baseSlug,
                tl: {
                  hd: CS_UUID$Util.PlainUUID.toString(uuid),
                  tl: {
                    hd: claimLoggedIn,
                    tl: /* [] */0
                  }
                }
              };
    
  }
}

function toString(nav) {
  return "/" + Belt_List.toArray(toList(nav)).join("/");
}

function match(pathList) {
  if (pathList && pathList.hd === baseSlug) {
    var lst = pathList.tl;
    if (!lst) {
      return ;
    }
    var tail = lst.tl;
    return Belt_Option.flatMap(Belt_Result.mapWithDefault(CS_UUID$Util.PlainUUID.fromPrimitive(lst.hd), undefined, (function (v) {
                      return Caml_option.some(v);
                    })), (function (uuid) {
                  if (!tail) {
                    return [
                            uuid,
                            "SelectClaimMethod"
                          ];
                  }
                  if (tail.tl) {
                    return ;
                  }
                  var sel = tail.hd;
                  if (sel === claimAnonymously) {
                    return [
                            uuid,
                            "ClaimAnonymously"
                          ];
                  } else if (sel === claimLoggedIn) {
                    return [
                            uuid,
                            "ClaimLoggedIn"
                          ];
                  } else {
                    return ;
                  }
                }));
  }
  
}

function redirect(state) {
  HopperURL$Thin.redirectToPath(toString(state), (function (param) {
          return HopperURL$Thin.blankGlobalQueryState();
        }), undefined);
}

var $$URL = {
  baseSlug: baseSlug,
  claimAnonymously: claimAnonymously,
  claimLoggedIn: claimLoggedIn,
  toList: toList,
  toString: toString,
  match: match,
  redirect: redirect
};

var convertFragment = ClaimNewPaxInvitationModelDeref_frag_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(ClaimNewPaxInvitationModelDeref_frag_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClaimNewPaxInvitationModelDeref_frag_graphql$Thin.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(ClaimNewPaxInvitationModelDeref_frag_graphql$Thin.node, convertFragment, fRef);
}

var Deref = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertFragment$1 = ClaimNewPaxInvitationModelCurrentAccount_frag_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(ClaimNewPaxInvitationModelCurrentAccount_frag_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, ClaimNewPaxInvitationModelCurrentAccount_frag_graphql$Thin.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(ClaimNewPaxInvitationModelCurrentAccount_frag_graphql$Thin.node, convertFragment$1, fRef);
}

var CurrentAccount_docGender_decode = ClaimNewPaxInvitationModelCurrentAccount_frag_graphql$Thin.Utils.docGender_decode;

var CurrentAccount_docGender_fromString = ClaimNewPaxInvitationModelCurrentAccount_frag_graphql$Thin.Utils.docGender_fromString;

var CurrentAccount = {
  docGender_decode: CurrentAccount_docGender_decode,
  docGender_fromString: CurrentAccount_docGender_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

var Query = {
  Deref: Deref,
  CurrentAccount: CurrentAccount
};

var convertVariables = ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables, ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse);

var ClaimAnonymously_bookingDiffError_decode = ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.Utils.bookingDiffError_decode;

var ClaimAnonymously_bookingDiffError_fromString = ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.Utils.bookingDiffError_fromString;

var ClaimAnonymously_docGender_decode = ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.Utils.docGender_decode;

var ClaimAnonymously_docGender_fromString = ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.Utils.docGender_fromString;

var ClaimAnonymously_signUpErr_decode = ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.Utils.signUpErr_decode;

var ClaimAnonymously_signUpErr_fromString = ClaimNewPaxInvitationModel_ClaimAnonMutation_graphql$Thin.Utils.signUpErr_fromString;

var ClaimAnonymously = {
  bookingDiffError_decode: ClaimAnonymously_bookingDiffError_decode,
  bookingDiffError_fromString: ClaimAnonymously_bookingDiffError_fromString,
  docGender_decode: ClaimAnonymously_docGender_decode,
  docGender_fromString: ClaimAnonymously_docGender_fromString,
  signUpErr_decode: ClaimAnonymously_signUpErr_decode,
  signUpErr_fromString: ClaimAnonymously_signUpErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use$2
};

var convertVariables$1 = ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.Internal.convertVariables;

var convertResponse$1 = ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse$1 = ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.Internal.convertWrapRawResponse;

var commitMutation$1 = RescriptRelay_Mutation.commitMutation(convertVariables$1, ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.node, convertResponse$1, convertWrapRawResponse$1);

var use$3 = RescriptRelay_Mutation.useMutation(convertVariables$1, ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.node, convertResponse$1, convertWrapRawResponse$1);

var ClaimLoggedIn_bookingDiffError_decode = ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.Utils.bookingDiffError_decode;

var ClaimLoggedIn_bookingDiffError_fromString = ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.Utils.bookingDiffError_fromString;

var ClaimLoggedIn_docGender_decode = ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.Utils.docGender_decode;

var ClaimLoggedIn_docGender_fromString = ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.Utils.docGender_fromString;

var ClaimLoggedIn_signUpErr_decode = ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.Utils.signUpErr_decode;

var ClaimLoggedIn_signUpErr_fromString = ClaimNewPaxInvitationModel_ClaimLoggedInMutation_graphql$Thin.Utils.signUpErr_fromString;

var ClaimLoggedIn = {
  bookingDiffError_decode: ClaimLoggedIn_bookingDiffError_decode,
  bookingDiffError_fromString: ClaimLoggedIn_bookingDiffError_fromString,
  docGender_decode: ClaimLoggedIn_docGender_decode,
  docGender_fromString: ClaimLoggedIn_docGender_fromString,
  signUpErr_decode: ClaimLoggedIn_signUpErr_decode,
  signUpErr_fromString: ClaimLoggedIn_signUpErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation$1,
  use: use$3
};

var Mutation = {
  ClaimAnonymously: ClaimAnonymously,
  ClaimLoggedIn: ClaimLoggedIn
};

var docGender_decode = ClaimNewPaxInvitationModelCurrentAccount_frag_graphql$Thin.Utils.docGender_decode;

var newPassword = HopperState$Util.Observable.FormField.delegate(NewPasswordModel$Thin.newPassword, {
      read: (function (i) {
          return i.password;
        }),
      write: (function (i, password) {
          return {
                  claimProgress: i.claimProgress,
                  password: password,
                  preferredFirstName: i.preferredFirstName,
                  preferredLastName: i.preferredLastName,
                  docFirstName: i.docFirstName,
                  docLastName: i.docLastName,
                  docFirstSameAsPreferredFirst: i.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: i.docLastSameAsPreferredLast,
                  docBirthdate: i.docBirthdate,
                  docCitizenship: i.docCitizenship,
                  docGender: i.docGender,
                  phone: i.phone,
                  phoneCountryCode: i.phoneCountryCode,
                  postalCode: i.postalCode,
                  residenceCountry: i.residenceCountry,
                  email: i.email,
                  acquisitionSlug: i.acquisitionSlug,
                  halMarinerNumber: i.halMarinerNumber
                };
        })
    });

var confirmNewPassword = HopperState$Util.Observable.FormField.delegate(NewPasswordModel$Thin.confirmNewPassword, {
      read: (function (i) {
          return i.password;
        }),
      write: (function (i, password) {
          return {
                  claimProgress: i.claimProgress,
                  password: password,
                  preferredFirstName: i.preferredFirstName,
                  preferredLastName: i.preferredLastName,
                  docFirstName: i.docFirstName,
                  docLastName: i.docLastName,
                  docFirstSameAsPreferredFirst: i.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: i.docLastSameAsPreferredLast,
                  docBirthdate: i.docBirthdate,
                  docCitizenship: i.docCitizenship,
                  docGender: i.docGender,
                  phone: i.phone,
                  phoneCountryCode: i.phoneCountryCode,
                  postalCode: i.postalCode,
                  residenceCountry: i.residenceCountry,
                  email: i.email,
                  acquisitionSlug: i.acquisitionSlug,
                  halMarinerNumber: i.halMarinerNumber
                };
        })
    });

var docFirstSameAsPreferredFirst = HopperState$Util.Observable.FormField.makeBool({
      read: (function (param) {
          return param.docFirstSameAsPreferredFirst;
        }),
      write: (function (pax, docFirstSameAsPreferredFirst) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), true, undefined);

var docLastSameAsPreferredLast = HopperState$Util.Observable.FormField.makeBool({
      read: (function (param) {
          return param.docLastSameAsPreferredLast;
        }),
      write: (function (pax, docLastSameAsPreferredLast) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), true, undefined);

var preferredFirstName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.preferredFirstName;
        }),
      write: (function (pax, preferredFirstName) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var preferredLastName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.preferredLastName;
        }),
      write: (function (pax, preferredLastName) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var docFirstName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (m) {
          if (docFirstSameAsPreferredFirst.getValue(m)) {
            return m.preferredFirstName;
          } else {
            return m.docFirstName;
          }
        }),
      write: (function (m, docFirstName) {
          if (docFirstSameAsPreferredFirst.getValue(m)) {
            return {
                    claimProgress: m.claimProgress,
                    password: m.password,
                    preferredFirstName: docFirstName,
                    preferredLastName: m.preferredLastName,
                    docFirstName: docFirstName,
                    docLastName: m.docLastName,
                    docFirstSameAsPreferredFirst: m.docFirstSameAsPreferredFirst,
                    docLastSameAsPreferredLast: m.docLastSameAsPreferredLast,
                    docBirthdate: m.docBirthdate,
                    docCitizenship: m.docCitizenship,
                    docGender: m.docGender,
                    phone: m.phone,
                    phoneCountryCode: m.phoneCountryCode,
                    postalCode: m.postalCode,
                    residenceCountry: m.residenceCountry,
                    email: m.email,
                    acquisitionSlug: m.acquisitionSlug,
                    halMarinerNumber: m.halMarinerNumber
                  };
          } else {
            return {
                    claimProgress: m.claimProgress,
                    password: m.password,
                    preferredFirstName: m.preferredFirstName,
                    preferredLastName: m.preferredLastName,
                    docFirstName: docFirstName,
                    docLastName: m.docLastName,
                    docFirstSameAsPreferredFirst: m.docFirstSameAsPreferredFirst,
                    docLastSameAsPreferredLast: m.docLastSameAsPreferredLast,
                    docBirthdate: m.docBirthdate,
                    docCitizenship: m.docCitizenship,
                    docGender: m.docGender,
                    phone: m.phone,
                    phoneCountryCode: m.phoneCountryCode,
                    postalCode: m.postalCode,
                    residenceCountry: m.residenceCountry,
                    email: m.email,
                    acquisitionSlug: m.acquisitionSlug,
                    halMarinerNumber: m.halMarinerNumber
                  };
          }
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var docLastName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (m) {
          if (docLastSameAsPreferredLast.getValue(m)) {
            return m.preferredLastName;
          } else {
            return m.docLastName;
          }
        }),
      write: (function (m, docLastName) {
          if (docLastSameAsPreferredLast.getValue(m)) {
            return {
                    claimProgress: m.claimProgress,
                    password: m.password,
                    preferredFirstName: m.preferredFirstName,
                    preferredLastName: docLastName,
                    docFirstName: m.docFirstName,
                    docLastName: docLastName,
                    docFirstSameAsPreferredFirst: m.docFirstSameAsPreferredFirst,
                    docLastSameAsPreferredLast: m.docLastSameAsPreferredLast,
                    docBirthdate: m.docBirthdate,
                    docCitizenship: m.docCitizenship,
                    docGender: m.docGender,
                    phone: m.phone,
                    phoneCountryCode: m.phoneCountryCode,
                    postalCode: m.postalCode,
                    residenceCountry: m.residenceCountry,
                    email: m.email,
                    acquisitionSlug: m.acquisitionSlug,
                    halMarinerNumber: m.halMarinerNumber
                  };
          } else {
            return {
                    claimProgress: m.claimProgress,
                    password: m.password,
                    preferredFirstName: m.preferredFirstName,
                    preferredLastName: m.preferredLastName,
                    docFirstName: m.docFirstName,
                    docLastName: docLastName,
                    docFirstSameAsPreferredFirst: m.docFirstSameAsPreferredFirst,
                    docLastSameAsPreferredLast: m.docLastSameAsPreferredLast,
                    docBirthdate: m.docBirthdate,
                    docCitizenship: m.docCitizenship,
                    docGender: m.docGender,
                    phone: m.phone,
                    phoneCountryCode: m.phoneCountryCode,
                    postalCode: m.postalCode,
                    residenceCountry: m.residenceCountry,
                    email: m.email,
                    acquisitionSlug: m.acquisitionSlug,
                    halMarinerNumber: m.halMarinerNumber
                  };
          }
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var docBirthdate = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.docBirthdate;
        }),
      write: (function (pax, docBirthdate) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive, CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var docCitizenship = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.docCitizenship;
        }),
      write: (function (pax, docCitizenship) {
          var tmp;
          if (pax.residenceCountry.lastTouched === undefined) {
            var init = pax.residenceCountry;
            tmp = {
              initialValue: init.initialValue,
              value: docCitizenship.value,
              lastTouched: init.lastTouched
            };
          } else {
            tmp = pax.residenceCountry;
          }
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: tmp,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), HopperState$Util.Observable.FormField.makeStringableSchema((function (v) {
            return v;
          }), (function (v) {
            return v;
          })), {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var docGender = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.docGender;
        }),
      write: (function (pax, docGender) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), HopperState$Util.Observable.FormField.makeStringableSchema((function (v) {
            return v;
          }), (function (v) {
            return v;
          })), {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var phone = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.phone;
        }),
      write: (function (pax, phone) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var phoneCountryCode = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.phoneCountryCode;
        }),
      write: (function (pax, phoneCountryCode) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), "+1", undefined);

var postalCode = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.postalCode;
        }),
      write: (function (pax, postalCode) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, CS_NonemptyStrings$Util.PostalCode.fromPrimitive, CS_NonemptyStrings$Util.PostalCode.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var residenceCountry = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.residenceCountry;
        }),
      write: (function (pax, residenceCountry) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), CS_ISO3166_1$Util.Alpha3.schema, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var email = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.email;
        }),
      write: (function (pax, email) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, CS_Emails$Util.Email.fromPrimitive, CS_Emails$Util.Email.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var acquisitionSlug = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.acquisitionSlug;
        }),
      write: (function (pax, acquisitionSlug) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: acquisitionSlug,
                  halMarinerNumber: pax.halMarinerNumber
                };
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), CS_Slugs$Util.Slug.schema, {
      TAG: "Error",
      _0: "This field is required"
    }, Caml_option.some(undefined), undefined);

var halMarinerNumber = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.halMarinerNumber;
        }),
      write: (function (pax, halMarinerNumber) {
          return {
                  claimProgress: pax.claimProgress,
                  password: pax.password,
                  preferredFirstName: pax.preferredFirstName,
                  preferredLastName: pax.preferredLastName,
                  docFirstName: pax.docFirstName,
                  docLastName: pax.docLastName,
                  docFirstSameAsPreferredFirst: pax.docFirstSameAsPreferredFirst,
                  docLastSameAsPreferredLast: pax.docLastSameAsPreferredLast,
                  docBirthdate: pax.docBirthdate,
                  docCitizenship: pax.docCitizenship,
                  docGender: pax.docGender,
                  phone: pax.phone,
                  phoneCountryCode: pax.phoneCountryCode,
                  postalCode: pax.postalCode,
                  residenceCountry: pax.residenceCountry,
                  email: pax.email,
                  acquisitionSlug: pax.acquisitionSlug,
                  halMarinerNumber: halMarinerNumber
                };
        })
    }, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.fromPrimitive, CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var blankModel_preferredFirstName = preferredFirstName.blankStore;

var blankModel_preferredLastName = preferredLastName.blankStore;

var blankModel_docFirstName = docFirstName.blankStore;

var blankModel_docLastName = docLastName.blankStore;

var blankModel_docFirstSameAsPreferredFirst = docFirstSameAsPreferredFirst.blankStore;

var blankModel_docLastSameAsPreferredLast = docLastSameAsPreferredLast.blankStore;

var blankModel_docBirthdate = docBirthdate.blankStore;

var blankModel_docCitizenship = docCitizenship.blankStore;

var blankModel_docGender = docGender.blankStore;

var blankModel_phone = phone.blankStore;

var blankModel_phoneCountryCode = phoneCountryCode.blankStore;

var blankModel_postalCode = postalCode.blankStore;

var blankModel_residenceCountry = residenceCountry.blankStore;

var blankModel_email = email.blankStore;

var blankModel_acquisitionSlug = acquisitionSlug.blankStore;

var blankModel_halMarinerNumber = halMarinerNumber.blankStore;

var blankModel = {
  claimProgress: "NotAsked",
  password: NewPasswordModel$Thin.blankModel,
  preferredFirstName: blankModel_preferredFirstName,
  preferredLastName: blankModel_preferredLastName,
  docFirstName: blankModel_docFirstName,
  docLastName: blankModel_docLastName,
  docFirstSameAsPreferredFirst: blankModel_docFirstSameAsPreferredFirst,
  docLastSameAsPreferredLast: blankModel_docLastSameAsPreferredLast,
  docBirthdate: blankModel_docBirthdate,
  docCitizenship: blankModel_docCitizenship,
  docGender: blankModel_docGender,
  phone: blankModel_phone,
  phoneCountryCode: blankModel_phoneCountryCode,
  postalCode: blankModel_postalCode,
  residenceCountry: blankModel_residenceCountry,
  email: blankModel_email,
  acquisitionSlug: blankModel_acquisitionSlug,
  halMarinerNumber: blankModel_halMarinerNumber
};

function prefill(view, prefill$1) {
  HopperState$Util.Observable.notify(view, (function (model) {
          return email.setFromValid(preferredLastName.setFromValid(preferredFirstName.setFromValid(model, Caml_option.some(prefill$1.preferredFirstName), "InitialValueAsPrefill"), prefill$1.preferredLastName, "InitialValueAsPrefill"), Caml_option.some(prefill$1.invitationEmail), "InitialValueAsPrefill");
        }));
}

function prefillFromAccount(view, prefill) {
  var match = Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(prefill.fullPhone, CS_Phone$Util.Phone.toPrimitive), (function (v) {
              return [
                      CS_Phone$Util.Phone.getNumber(v),
                      Belt_Option.getWithDefault(CS_Phone$Util.Phone.getCallingCode(v), phoneCountryCode.blankValue)
                    ];
            })), [
        undefined,
        phoneCountryCode.blankValue
      ]);
  var phoneCallingCode = match[1];
  var phoneNumber = match[0];
  HopperState$Util.Observable.notify(view, (function (model) {
          return halMarinerNumber.setFromValid(acquisitionSlug.setFromValid(email.setFromValid(postalCode.setFromValid(phoneCountryCode.setFromValid(phone.setFromValid(docGender.setFromValid(docCitizenship.setFromValid(docBirthdate.setFromValid(docLastName.setFromValid(docFirstName.setFromValid(preferredLastName.setFromValid(preferredFirstName.setFromValid(model, prefill.preferredFirstName, "InitialValueAsPrefill"), prefill.preferredLastName, "InitialValueAsPrefill"), prefill.docFirstName, "InitialValueAsPrefill"), prefill.docLastName, "InitialValueAsPrefill"), prefill.docBirthdate, "InitialValueAsPrefill"), prefill.docCitizenship, "InitialValueAsPrefill"), Belt_Option.flatMap(prefill.docGender, docGender_decode), "InitialValueAsPrefill"), phoneNumber, "InitialValueAsPrefill"), phoneCallingCode, "InitialValueAsPrefill"), prefill.postalCode, "InitialValueAsPrefill"), prefill.email, "InitialValueAsPrefill"), prefill.acquisitionSlug, "InitialValueAsPrefill"), prefill.halMarinerNumber, "InitialValueAsPrefill");
        }));
}

function validatePhone(model) {
  var match = phone.validate(model, undefined);
  var match$1 = phoneCountryCode.validate(model, undefined);
  if (match.TAG !== "Ok") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var number = match._0;
  if (number !== undefined && match$1.TAG === "Ok") {
    return Belt_Result.map(CS_Phone$Util.Phone.fromPrimitive(match$1._0 + " " + number), (function (v) {
                  return Caml_option.some(v);
                }));
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function validateAccountCredentials(model, token) {
  var match = email.validate(model, undefined);
  var match$1 = newPassword.validate(model, undefined);
  var match$2 = confirmNewPassword.validate(model, undefined);
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "AccountCredentialsIncomplete"
          };
  }
  var email$1 = match._0;
  if (email$1 === undefined) {
    return {
            TAG: "Error",
            _0: "AccountCredentialsIncomplete"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "AccountCredentialsIncomplete"
          };
  }
  var p1 = match$1._0;
  if (match$2.TAG === "Ok" && p1 === match$2._0) {
    return {
            TAG: "Ok",
            _0: [
              token,
              Caml_option.valFromOption(email$1),
              p1
            ]
          };
  } else {
    return {
            TAG: "Error",
            _0: "AccountCredentialsIncomplete"
          };
  }
}

function validatePersonalFields(model) {
  var match = preferredFirstName.validate(model, undefined);
  var match$1 = preferredLastName.validate(model, undefined);
  var match$2 = validatePhone(model);
  var match$3 = residenceCountry.validate(model, undefined);
  var match$4 = acquisitionSlug.validate(model, undefined);
  var match$5 = postalCode.validate(model, undefined);
  var match$6 = model.halMarinerNumber.value;
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  var accountFirstName = match._0;
  if (accountFirstName === undefined) {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  var accountLastName = match$1._0;
  if (accountLastName === undefined) {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  var phone = match$2._0;
  if (phone === undefined) {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  var residenceCountry$1 = match$3._0;
  if (residenceCountry$1 === undefined) {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  var custAcquisitionSlug = match$4._0;
  if (custAcquisitionSlug === undefined) {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  if (match$5.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "PersonalFieldsIncomplete"
          };
  }
  var halMarinerNumber;
  if (match$6 !== undefined && match$6 !== "") {
    var v = CS_LoyaltyAndCruiselineNumbers$Util.HalMarinerNumber.fromPrimitive(match$6);
    halMarinerNumber = v.TAG === "Ok" ? Caml_option.some(v._0) : undefined;
  } else {
    halMarinerNumber = undefined;
  }
  return {
          TAG: "Ok",
          _0: {
            accountFirstName: Caml_option.valFromOption(accountFirstName),
            accountLastName: Caml_option.valFromOption(accountLastName),
            phone: Caml_option.valFromOption(phone),
            residenceCountry: Caml_option.valFromOption(residenceCountry$1),
            postalCode: match$5._0,
            custAcquisitionSlug: Caml_option.valFromOption(custAcquisitionSlug),
            halMarinerNumber: halMarinerNumber
          }
        };
}

function validateTravelDocFields(model) {
  var match = docFirstName.validate(model, undefined);
  var match$1 = docLastName.validate(model, undefined);
  var match$2 = docGender.validate(model, undefined);
  var match$3 = docCitizenship.validate(model, undefined);
  var match$4 = docBirthdate.validate(model, undefined);
  if (match.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
  var docFirstname = match._0;
  if (docFirstname === undefined) {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
  if (match$1.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
  var docLastname = match$1._0;
  if (docLastname === undefined) {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
  if (match$2.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
  var docGender$1 = match$2._0;
  if (docGender$1 === undefined) {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
  if (match$3.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
  var docCitizenship$1 = match$3._0;
  if (docCitizenship$1 === undefined) {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
  if (match$4.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
  var docBirthdate$1 = match$4._0;
  if (docBirthdate$1 !== undefined) {
    return {
            TAG: "Ok",
            _0: {
              docFirstname: Caml_option.valFromOption(docFirstname),
              docLastname: Caml_option.valFromOption(docLastname),
              docGender: docGender$1,
              docCitizenship: Caml_option.valFromOption(docCitizenship$1),
              docBirthdate: Caml_option.valFromOption(docBirthdate$1)
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: "TravelDocFieldsIncomplete"
          };
  }
}

function validateClaimAnonymouslyInput(model, token) {
  var match = validateAccountCredentials(model, token);
  var match$1 = validatePersonalFields(model);
  var match$2 = validateTravelDocFields(model);
  if (match.TAG !== "Ok") {
    return match;
  }
  var match$3 = match._0;
  if (match$1.TAG === "Ok") {
    if (match$2.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                allegedToken: match$3[0],
                newPasswd: match$3[2],
                accountEmail: match$3[1],
                personalFields: match$1._0,
                travelDocFields: match$2._0
              }
            };
    } else {
      return match$2;
    }
  } else {
    return match$1;
  }
}

function validateClaimLoggedInInput(model, allegedToken) {
  var match = validatePersonalFields(model);
  var match$1 = validateTravelDocFields(model);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                allegedToken: allegedToken,
                personalFields: match._0,
                travelDocFields: match$1._0
              }
            };
    } else {
      return match$1;
    }
  } else {
    return match;
  }
}

var utils = HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.emptyContainer(), docFirstSameAsPreferredFirst), docLastSameAsPreferredLast), preferredFirstName), preferredLastName), docFirstName), docLastName), docBirthdate), docCitizenship), docGender), phone), phoneCountryCode), postalCode), residenceCountry), email), acquisitionSlug), halMarinerNumber);

function forceShowErrors(extra) {
  return Curry._2(utils.polyReducer, "MarkTouchedNow", extra);
}

function describeSignUpErr(enum_SignUpErr) {
  if (!(enum_SignUpErr === "ACCOUNT_UPDATE_ERROR" || enum_SignUpErr === "INVITATION_NOT_FOUND" || enum_SignUpErr === "BOOKING_UPDATE_ERROR" || enum_SignUpErr === "USER_CANT_BE_CREATED_ASK_SUPPORT" || enum_SignUpErr === "EMAIL_ALREADY_IN_USE" || enum_SignUpErr === "CANNOT_CLAIM")) {
    return "An unknown error of Type 1 occurred " + enum_SignUpErr + ". Please contact support.";
  }
  switch (enum_SignUpErr) {
    case "EMAIL_ALREADY_IN_USE" :
        return "The email you have selected is already in use or cannot be used to complete your signup. If you already have an account, please log in and claim this invitation with your existing account.";
    case "USER_CANT_BE_CREATED_ASK_SUPPORT" :
        return "We were unable to create your account. Please contact support.";
    case "BOOKING_UPDATE_ERROR" :
        return "We were unable to add you to the desired booking. Please contact support.";
    case "INVITATION_NOT_FOUND" :
        return "We can't find your invitation with the data you provided or it has already been claimed. Confirm the data you provided is correct and contact support if the problem persists.";
    case "ACCOUNT_UPDATE_ERROR" :
        return "We were unable to update your user account with the new data you provided. Please contact support if this problem persists.";
    case "CANNOT_CLAIM" :
        return "An unknown error of Type 2 occurred " + enum_SignUpErr + ". Please contact support.";
    
  }
}

function describeComboError(signup, bderr) {
  return describeSignUpErr(signup) + (
          bderr !== undefined ? " Error Codes: " + signup + ", " + bderr : " Error Code: " + signup
        );
}

var checkIsDirty = utils.checkIsDirty;

exports.$$URL = $$URL;
exports.Query = Query;
exports.Mutation = Mutation;
exports.docGender_decode = docGender_decode;
exports.newPassword = newPassword;
exports.confirmNewPassword = confirmNewPassword;
exports.docFirstSameAsPreferredFirst = docFirstSameAsPreferredFirst;
exports.docLastSameAsPreferredLast = docLastSameAsPreferredLast;
exports.preferredFirstName = preferredFirstName;
exports.preferredLastName = preferredLastName;
exports.docFirstName = docFirstName;
exports.docLastName = docLastName;
exports.docBirthdate = docBirthdate;
exports.docCitizenship = docCitizenship;
exports.docGender = docGender;
exports.phone = phone;
exports.phoneCountryCode = phoneCountryCode;
exports.postalCode = postalCode;
exports.residenceCountry = residenceCountry;
exports.email = email;
exports.acquisitionSlug = acquisitionSlug;
exports.halMarinerNumber = halMarinerNumber;
exports.blankModel = blankModel;
exports.prefill = prefill;
exports.prefillFromAccount = prefillFromAccount;
exports.validatePhone = validatePhone;
exports.validateAccountCredentials = validateAccountCredentials;
exports.validatePersonalFields = validatePersonalFields;
exports.validateTravelDocFields = validateTravelDocFields;
exports.validateClaimAnonymouslyInput = validateClaimAnonymouslyInput;
exports.validateClaimLoggedInInput = validateClaimLoggedInInput;
exports.checkIsDirty = checkIsDirty;
exports.utils = utils;
exports.forceShowErrors = forceShowErrors;
exports.describeSignUpErr = describeSignUpErr;
exports.describeComboError = describeComboError;
/* commitMutation Not a pure module */
