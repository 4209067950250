// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SFState$Thin = require("../../../utils/sales-flow/SFState.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CabinCard$Thin = require("./CabinCard.bs.js");
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var ModalDrawer$Thin = require("../../common/molecules/ModalDrawer.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function ClassAndSubclassSelector(props) {
  var pricingMode = props.pricingMode;
  var holdWarningOrProceed = props.holdWarningOrProceed;
  var hasCabinHold = props.hasCabinHold;
  var sessionData = props.sessionData;
  var url = props.url;
  var flow = props.flow;
  var occupancy = props.occupancy;
  var urlState = props.urlState;
  var $$class = props.class;
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var match$1;
  switch (flow) {
    case "ByCategory" :
        var match$2 = urlState.cabinTargetingByCategory;
        match$1 = [
          match$2[1],
          match$2[2],
          SFState$Thin.ByCategory.nextNavTarget("ClassAndSubclassSelection"),
          (function (urlData) {
              return SFState$Thin.ByCategory.CabinTarget.setSub(urlData, undefined);
            })
        ];
        break;
    case "AllNoCabin" :
        var match$3 = urlState.cabinTargetingByAllNoCabin;
        match$1 = [
          match$3[1],
          match$3[2],
          SFState$Thin.ByAllNoCabin.nextNavTarget("ClassAndSubclassSelection"),
          (function (urlData) {
              return SFState$Thin.ByAllNoCabin.CabinTarget.setSub(urlData, undefined);
            })
        ];
        break;
    case "All" :
        var match$4 = urlState.cabinTargetingByAll;
        match$1 = [
          match$4[1],
          match$4[2],
          SFState$Thin.ByAll.nextNavTarget("ClassAndSubclassSelection"),
          (function (urlData) {
              return SFState$Thin.ByAll.CabinTarget.setSub(urlData, undefined);
            })
        ];
        break;
    
  }
  var clearSubclass = match$1[3];
  var nextNavTarget = match$1[2];
  var subclassSlug = match$1[1];
  var classSlug = match$1[0];
  var isSelected = function (slug) {
    switch (slug.TAG) {
      case "Class" :
          return Caml_obj.equal(classSlug, Caml_option.some(slug._0));
      case "Subclass" :
          return Caml_obj.equal(subclassSlug, Caml_option.some(slug._0));
      case "Category" :
          return false;
      
    }
  };
  var onSelect = function (slug) {
    switch (slug.TAG) {
      case "Class" :
          var cl = slug._0;
          if (classSlug !== undefined && Caml_obj.equal(Caml_option.valFromOption(classSlug), cl)) {
            var match = $$class.subs;
            if (match !== undefined) {
              return setModalOpen(function (param) {
                          return true;
                        });
            } else {
              HopperState$Util.Observable.notify(sessionData, (function (model) {
                      return {
                              paxes: model.paxes,
                              bc: model.bc,
                              pax1: model.pax1,
                              paymentOption: undefined,
                              sessionSaveIsMutating: model.sessionSaveIsMutating,
                              signup: model.signup,
                              url: model.url,
                              paymentSuggestions: model.paymentSuggestions,
                              quote: model.quote,
                              referral: model.referral,
                              referralSuccess: model.referralSuccess,
                              referralError: model.referralError
                            };
                    }));
              return SFState$Thin.navigate(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(urlState, nextNavTarget)), url);
            }
          }
          var subs = $$class.subs;
          if (subs !== undefined) {
            if (subs.length === 1) {
              var sub = Belt_Array.get(subs, 0);
              if (sub === undefined) {
                return ;
              }
              var newUrlState = SFState$Thin.cabinTypeSelect(SFState$Thin.cabinTypeSelect(urlState, {
                        TAG: "Class",
                        _0: cl
                      }, flow), sub.slug, flow);
              var onConfirmOneSubclass = function (param) {
                HopperState$Util.Observable.notify(sessionData, (function (model) {
                        return {
                                paxes: model.paxes,
                                bc: model.bc,
                                pax1: model.pax1,
                                paymentOption: undefined,
                                sessionSaveIsMutating: model.sessionSaveIsMutating,
                                signup: model.signup,
                                url: model.url,
                                paymentSuggestions: model.paymentSuggestions,
                                quote: model.quote,
                                referral: model.referral,
                                referralSuccess: model.referralSuccess,
                                referralError: model.referralError
                              };
                      }));
                SFState$Thin.navigate(SFState$Thin.URLState.serialize(newUrlState), url);
                SFState$Thin.navigate(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(newUrlState, nextNavTarget)), url);
              };
              return holdWarningOrProceed(hasCabinHold, onConfirmOneSubclass);
            }
            var newUrlState$1 = SFState$Thin.cabinTypeSelect(urlState, {
                  TAG: "Class",
                  _0: cl
                }, flow);
            var onConfirmMultipleSubclasses = function (param) {
              HopperState$Util.Observable.notify(sessionData, (function (model) {
                      return {
                              paxes: model.paxes,
                              bc: model.bc,
                              pax1: model.pax1,
                              paymentOption: undefined,
                              sessionSaveIsMutating: model.sessionSaveIsMutating,
                              signup: model.signup,
                              url: model.url,
                              paymentSuggestions: model.paymentSuggestions,
                              quote: model.quote,
                              referral: model.referral,
                              referralSuccess: model.referralSuccess,
                              referralError: model.referralError
                            };
                    }));
              SFState$Thin.navigate(SFState$Thin.URLState.serialize(newUrlState$1), url);
            };
            return holdWarningOrProceed(hasCabinHold, onConfirmMultipleSubclasses);
          }
          var newUrlState$2 = SFState$Thin.cabinTypeSelect(SFState$Thin.cabinTypeSelect(urlState, {
                    TAG: "Class",
                    _0: cl
                  }, flow), {
                TAG: "Subclass",
                _0: CS_Slugs$Util.CabinSubclassSlug.Exn.fromPrimitive("default")
              }, flow);
          var onConfirmNoSubclasses = function (param) {
            HopperState$Util.Observable.notify(sessionData, (function (model) {
                    return {
                            paxes: model.paxes,
                            bc: model.bc,
                            pax1: model.pax1,
                            paymentOption: undefined,
                            sessionSaveIsMutating: model.sessionSaveIsMutating,
                            signup: model.signup,
                            url: model.url,
                            paymentSuggestions: model.paymentSuggestions,
                            quote: model.quote,
                            referral: model.referral,
                            referralSuccess: model.referralSuccess,
                            referralError: model.referralError
                          };
                  }));
            SFState$Thin.navigate(SFState$Thin.URLState.serialize(newUrlState$2), url);
            SFState$Thin.navigate(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(newUrlState$2, nextNavTarget)), url);
          };
          return holdWarningOrProceed(hasCabinHold, onConfirmNoSubclasses);
      case "Subclass" :
          var sub$1 = slug._0;
          var newUrlState$3 = SFState$Thin.cabinTypeSelect(urlState, {
                TAG: "Subclass",
                _0: sub$1
              }, flow);
          var onConfirmSubclass = function (param) {
            HopperState$Util.Observable.notify(sessionData, (function (model) {
                    return {
                            paxes: model.paxes,
                            bc: model.bc,
                            pax1: model.pax1,
                            paymentOption: undefined,
                            sessionSaveIsMutating: model.sessionSaveIsMutating,
                            signup: model.signup,
                            url: model.url,
                            paymentSuggestions: model.paymentSuggestions,
                            quote: model.quote,
                            referral: model.referral,
                            referralSuccess: model.referralSuccess,
                            referralError: model.referralError
                          };
                  }));
            SFState$Thin.navigate(SFState$Thin.URLState.serialize(newUrlState$3), url);
          };
          return holdWarningOrProceed(hasCabinHold && Caml_option.some(sub$1) !== subclassSlug, onConfirmSubclass);
      case "Category" :
          return ;
      
    }
  };
  React.useEffect((function () {
          var cl = $$class.slug;
          switch (cl.TAG) {
            case "Class" :
                if (Caml_obj.equal(classSlug, Caml_option.some(cl._0)) && Belt_Option.getWithDefault($$class.subs, []).length > 1) {
                  setModalOpen(function (param) {
                        return true;
                      });
                }
                break;
            case "Subclass" :
            case "Category" :
                break;
            
          }
        }), [classSlug]);
  var subs = $$class.subs;
  var tmp;
  if (subs !== undefined) {
    var match$5 = $$class.subs;
    var tmp$1;
    if (subclassSlug !== undefined && match$5 !== undefined) {
      var subclassSlug$1 = Caml_option.valFromOption(subclassSlug);
      var subclass = Belt_Array.get(Belt_Array.keep(match$5, (function (s) {
                  var scSlug = s.slug;
                  switch (scSlug.TAG) {
                    case "Subclass" :
                        return Caml_obj.equal(scSlug._0, subclassSlug$1);
                    case "Class" :
                    case "Category" :
                        return false;
                    
                  }
                })), 0);
      tmp$1 = subclass !== undefined ? "Select " + subclass.name : "Select";
    } else {
      tmp$1 = "Select";
    }
    tmp = JsxRuntime.jsxs(ModalDrawer$Thin.make, {
          open_: match[0],
          title: "Select " + $$class.name + " type",
          onClose: (function () {
              setModalOpen(function (param) {
                    return false;
                  });
            }),
          children: [
            JsxRuntime.jsx(DialogContent, {
                  children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Grid.make, {
                            children: Caml_option.some(Belt_Array.mapWithIndex(subs, (function (i, subcl) {
                                        return JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                    children: Caml_option.some(JsxRuntime.jsx(CabinCard$Thin.make, {
                                                              class: subcl,
                                                              occupancy: occupancy,
                                                              selected: isSelected(subcl.slug),
                                                              flow: flow,
                                                              onSelect: (function () {
                                                                  onSelect(subcl.slug);
                                                                }),
                                                              shadow: false,
                                                              pricingMode: pricingMode
                                                            })),
                                                    xs: 12,
                                                    sm: 6,
                                                    md: 4
                                                  }, String(i));
                                      }))),
                            container: true,
                            rowSpacing: 2,
                            columnSpacing: 2
                          })),
                  sx: {
                    p: 2,
                    overflowX: "hidden"
                  }
                }),
            JsxRuntime.jsx(Divider, {}),
            JsxRuntime.jsx(DialogActions, {
                  children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                            direction: {
                              xs: "column-reverse",
                              sm: "row"
                            },
                            justifyContent: "space-between",
                            spacing: 1,
                            sx: {
                              width: "100%",
                              p: 2
                            },
                            children: [
                              JsxRuntime.jsx(Button, {
                                    onClick: (function (param) {
                                        SFState$Thin.navigate(SFState$Thin.URLState.serialize(clearSubclass(urlState)), url);
                                        setModalOpen(function (param) {
                                              return false;
                                            });
                                      }),
                                    variant: "outlined",
                                    color: "neutral",
                                    children: "Back to Class Selection"
                                  }),
                              JsxRuntime.jsx(Button, {
                                    onClick: (function (param) {
                                        SFState$Thin.navigate(SFState$Thin.URLState.serialize(SFState$Thin.Alter.setNavTarget(urlState, nextNavTarget)), url);
                                      }),
                                    color: "primary",
                                    disabled: Belt_Option.isNone(subclassSlug),
                                    children: Caml_option.some(tmp$1)
                                  })
                            ]
                          }))
                })
          ],
          contained: false
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(CabinCard$Thin.make, {
                      class: $$class,
                      occupancy: occupancy,
                      selected: isSelected($$class.slug),
                      flow: flow,
                      onSelect: (function () {
                          onSelect($$class.slug);
                        }),
                      pricingMode: pricingMode
                    }),
                tmp
              ]
            });
}

var make = ClassAndSubclassSelector;

exports.make = make;
/* react Not a pure module */
