// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Theme$Thin = require("./components/wrappers/Theme.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("./utils/SysCtx.bs.js");
var ImageSet$Thin = require("./context/ImageSet.bs.js");
var AppConfig$Thin = require("./context/AppConfig.bs.js");
var HopperURL$Thin = require("./context/HopperURL.bs.js");
var LoginRoute$Thin = require("./routes/LoginRoute.bs.js");
var CurrentUser$Thin = require("./context/CurrentUser.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var AccountRoute$Thin = require("./routes/AccountRoute.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var BookingsRoute$Thin = require("./routes/BookingsRoute.bs.js");
var DeeplinkRoute$Thin = require("./routes/DeeplinkRoute.bs.js");
var TransferRoute$Thin = require("./routes/TransferRoute.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var SalesFlowRoute$Thin = require("./routes/SalesFlowRoute.bs.js");
var FullPageWaveLoader$Thin = require("./components/common/molecules/FullPageWaveLoader.bs.js");
var ResetPasswordRoute$Thin = require("./routes/ResetPasswordRoute.bs.js");
var BookingDetailsRoute$Thin = require("./routes/BookingDetailsRoute.bs.js");
var ThinAppQuery_graphql$Thin = require("./__generated__/ThinAppQuery_graphql.bs.js");
var ConnectedAccountsRoute$Thin = require("./routes/ConnectedAccountsRoute.bs.js");
var ClaimNewPaxInvitationModel$Thin = require("./utils/accounts/ClaimNewPaxInvitationModel.bs.js");
var ClaimNewPaxInvitationRoute$Thin = require("./routes/ClaimNewPaxInvitationRoute.bs.js");
var ClaimPrefilledInvitationModel$Thin = require("./utils/accounts/ClaimPrefilledInvitationModel.bs.js");
var ClaimPrefilledInvitationRoute$Thin = require("./routes/ClaimPrefilledInvitationRoute.bs.js");

function ThinApp$Redirect(props) {
  var link = props.link;
  React.useEffect((function () {
          HopperURL$Thin.redirectToPath(link, undefined, undefined);
        }), []);
  return JsxRuntime.jsx("div", {});
}

var Redirect = {
  make: ThinApp$Redirect
};

var convertVariables = ThinAppQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = ThinAppQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ThinAppQuery_graphql$Thin.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, ThinAppQuery_graphql$Thin.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, ThinAppQuery_graphql$Thin.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(ThinAppQuery_graphql$Thin.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(ThinAppQuery_graphql$Thin.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(ThinAppQuery_graphql$Thin.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(ThinAppQuery_graphql$Thin.node, convertVariables);

var ThinAppQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function setDocTitle(title) {
  return (document.title=title);
}

function ThinApp(props) {
  var fragmentRefs = use(undefined, undefined, undefined, undefined).query.fragmentRefs;
  var currentUser = CurrentUser$Thin.useQuery(fragmentRefs);
  var logout = currentUser.logout;
  var signOnStatus = currentUser.signOnStatus;
  var appConfig = AppConfig$Thin.useQuery(fragmentRefs);
  var match = appConfig.voyages;
  var getBookableStatuses = match.getBookableStatuses;
  var checkVoyageSlug = match.checkVoyageSlug;
  var brandFamilyName = appConfig.brandFamily.brandFamilyName;
  var urlState = HopperURL$Thin.useUrl();
  var path = HopperState$Util.Observable.useComputed(urlState.url, (function (param) {
          return param.path;
        }), undefined, undefined);
  var hasDeeplinkVars = HopperState$Util.Observable.useComputed(urlState.queryState, (function (qs) {
          var match = DeeplinkRoute$Thin.checkRoute(qs);
          return Belt_Result.isOk(match[1]);
        }), undefined, undefined);
  var match$1;
  var exit = 0;
  if (path) {
    var voyageSlug = path.hd;
    switch (voyageSlug) {
      case "account" :
          if (path.tl) {
            exit = 1;
          } else {
            match$1 = [
              "My Account | " + brandFamilyName,
              (function () {
                  return JsxRuntime.jsx(AccountRoute$Thin.make, {});
                })
            ];
          }
          break;
      case "bookings" :
          var match$2 = path.tl;
          if (match$2) {
            if (match$2.tl) {
              exit = 1;
            } else {
              var bookingId = match$2.hd;
              match$1 = [
                bookingId + " | " + brandFamilyName,
                (function () {
                    return JsxRuntime.jsx(BookingDetailsRoute$Thin.make, {
                                bookingId: bookingId
                              });
                  })
              ];
            }
          } else {
            match$1 = [
              "Bookings | " + brandFamilyName,
              (function () {
                  return JsxRuntime.jsx(BookingsRoute$Thin.make, {});
                })
            ];
          }
          break;
      case "connected-accounts" :
          if (path.tl) {
            exit = 1;
          } else {
            match$1 = [
              "Connected Accounts | " + brandFamilyName,
              (function () {
                  return JsxRuntime.jsx(ConnectedAccountsRoute$Thin.make, {});
                })
            ];
          }
          break;
      case "deeplink" :
          if (path.tl) {
            exit = 1;
          } else {
            match$1 = [
              brandFamilyName,
              (function () {
                  return JsxRuntime.jsx(DeeplinkRoute$Thin.make, {});
                })
            ];
          }
          break;
      case "login" :
          if (path.tl) {
            exit = 1;
          } else {
            match$1 = [
              "Login | " + brandFamilyName,
              (function () {
                  return JsxRuntime.jsx(LoginRoute$Thin.make, {});
                })
            ];
          }
          break;
      case "reset-password" :
          if (path.tl) {
            exit = 1;
          } else {
            match$1 = [
              "Reset your Password | " + brandFamilyName,
              (function () {
                  return JsxRuntime.jsx(ResetPasswordRoute$Thin.make, {});
                })
            ];
          }
          break;
      case "transfer" :
          if (path.tl) {
            exit = 1;
          } else {
            match$1 = [
              "Transfer Account | " + brandFamilyName,
              (function () {
                  return JsxRuntime.jsx(TransferRoute$Thin.make, {});
                })
            ];
          }
          break;
      default:
        if (path.tl || !Belt_Result.isOk(checkVoyageSlug(voyageSlug))) {
          exit = 1;
        } else {
          var name = match.getVoyageName(Belt_Result.getExn(checkVoyageSlug(voyageSlug)));
          match$1 = [
            name !== undefined ? name + " Booking" : brandFamilyName + " Booking",
            (function () {
                return JsxRuntime.jsx(SalesFlowRoute$Thin.make, {
                            voyage: Belt_Result.getExn(checkVoyageSlug(voyageSlug)),
                            getBookableStatuses: getBookableStatuses
                          });
              })
          ];
        }
    }
  } else {
    match$1 = hasDeeplinkVars ? [
        brandFamilyName,
        (function () {
            return JsxRuntime.jsx(DeeplinkRoute$Thin.make, {});
          })
      ] : [
        brandFamilyName,
        (function () {
            return JsxRuntime.jsx(ThinApp$Redirect, {
                        link: SysCtx$Thin.vars.loginLink
                      });
          })
      ];
  }
  if (exit === 1) {
    match$1 = Belt_Option.isSome(ClaimNewPaxInvitationModel$Thin.$$URL.match(path)) ? [
        "Claim Account | " + brandFamilyName,
        (function () {
            return JsxRuntime.jsx(ClaimNewPaxInvitationRoute$Thin.make, {
                        nav: Belt_Option.getExn(ClaimNewPaxInvitationModel$Thin.$$URL.match(path))
                      });
          })
      ] : (
        Belt_Option.isSome(ClaimPrefilledInvitationModel$Thin.$$URL.match(path)) ? [
            "Claim Account | " + brandFamilyName,
            (function () {
                return JsxRuntime.jsx(ClaimPrefilledInvitationRoute$Thin.make, {
                            nav: Belt_Option.getExn(ClaimPrefilledInvitationModel$Thin.$$URL.match(path))
                          });
              })
          ] : [
            "Ruh roh!",
            (function () {
                return "Route not found";
              })
          ]
      );
  }
  var title = match$1[0];
  React.useEffect(function () {
        if ((signOnStatus === "VERIFY_EMAIL" || signOnStatus === "INVITED" || signOnStatus === "PENDING_2FA" || signOnStatus === "CLEAR_JWT" || signOnStatus === "ANONYMOUS" || signOnStatus === "SIGNED_ON") && signOnStatus === "CLEAR_JWT") {
          logout();
        }
        setDocTitle(title);
      });
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsx(AppConfig$Thin.Context.Provider.make, {
                        value: appConfig,
                        children: JsxRuntime.jsx(CurrentUser$Thin.Context.Provider.make, {
                              value: currentUser,
                              children: JsxRuntime.jsx(ImageSet$Thin.Context.Provider.make, {
                                    imageSetPrefetch: Caml_option.some(fragmentRefs),
                                    children: JsxRuntime.jsx(HopperURL$Thin.Context.Provider.make, {
                                          value: urlState,
                                          children: JsxRuntime.jsx(React.Suspense, {
                                                children: Caml_option.some(JsxRuntime.jsx(Theme$Thin.make, {
                                                          children: match$1[1]()
                                                        })),
                                                fallback: Caml_option.some(JsxRuntime.jsx(FullPageWaveLoader$Thin.make, {}))
                                              })
                                        })
                                  })
                            })
                      }))
            });
}

var make = ThinApp;

exports.Redirect = Redirect;
exports.ThinAppQuery = ThinAppQuery;
exports.setDocTitle = setDocTitle;
exports.make = make;
/* use Not a pure module */
